import { passiveOptions } from 'constants/passives'

export const inputs = [
  {
    component: 'select',
    type: 'text',
    name: 'passiveType',
    label: 'Tipo de pasivo',
    placeholder: 'Elige una opción',
    isRequired: true,
    options: passiveOptions.types,
    className: 'col-span-2',
  },
  {
    component: 'default',
    type: 'text',
    name: 'passivePrice',
    label: 'Saldo capital',
    placeholder: 'Ingresa el monto',
    isRequired: true,
    className: 'col-span-1',
    startContent: '$',
  },
  {
    component: 'select',
    type: 'text',
    name: 'currencyPassive',
    label: 'Divisa',
    placeholder: 'Elige una opción',
    isRequired: true,
    options: passiveOptions.currencies,
    className: 'col-span-1',
  },
  {
    component: 'default',
    type: 'text',
    name: 'quotaValue',
    label: 'Valor cuota',
    placeholder: 'Ingresa el monto',
    isRequired: true,
    className: 'col-span-1',
    startContent: '$',
  },
  {
    component: 'default',
    type: 'number',
    name: 'quotaPeriod',
    label: 'Número de cuotas',
    placeholder: 'Ingresa el período',
    className: 'col-span-1',
    isRequired: true,
  },
  {
    component: 'default',
    type: 'number',
    name: 'pendingQuota',
    label: 'Plazo del crédito pendiente',
    placeholder: 'Ingresa el número de meses pendientes',
    isRequired: true,
    className: 'col-span-2',
  },
  {
    component: 'checkboxGroup',
    type: 'number',
    name: 'includesQuota',
    label: 'La cuota incluye',
    placeholder: 'Elige una opción',
    isRequired: true,
    options: passiveOptions.benefits,
    className: 'col-span-2',
  },
  {
    component: 'default',
    type: 'number',
    name: 'annualInterestRate',
    label: 'Tasa de interés anual',
    placeholder: 'Ingresa el porcentaje',
    className: 'col-span-2',
    isRequired: true,
    startContent: '%',
  },
]
