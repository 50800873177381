import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Listeners } from './components'
import { App as AppViews, Public as PublicViews } from './containers'
import authRoutes from 'routes/auth'
import Layout from 'layout/index'
import Loader from 'components/App/Loader'
import useAuth from 'hooks/useAuth'

const App = () => {
  const { user } = useAuth()
  return (
    <HashRouter>
      <Listeners.ChatBot />
      <Listeners.DAU />
      <Listeners.MetaPixel />
      <Listeners.ValidateRoutes />
      {user && <Listeners.UserInactivity />}
      <Suspense fallback={<Loader isAppLoader />}>
        <Routes>
          {authRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              name={route.name}
              element={<route.component />}
              exact
            />
          ))}
          <Route path="*" name="Inicio" element={<Layout />} />
          <Route path="/404" name="404" element={<AppViews.Error code="404" />} />
          <Route path="/500" name="500" element={<AppViews.Error code="500" />} />
          <Route path="/legal" name="Legal" element={<PublicViews.Legal />} />
          <Route path="/income" name="Income" element={<PublicViews.Income />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
