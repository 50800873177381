import React, { Fragment, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from 'routes/content'
import useAuth from 'hooks/useAuth'
import Loader from 'components/App/Loader'

const Content = () => {
  useAuth()
  return (
    <Suspense fallback={<Loader />}>
      <Fragment>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              name={route.name}
              element={
                <div className="animate-fade">
                  <route.component />
                </div>
              }
            />
          ))}
        </Routes>
      </Fragment>
    </Suspense>
  )
}

export default Content
