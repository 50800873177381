import React, { Fragment, useEffect, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from '@nextui-org/react'
import axios from 'axios'
import { api } from 'api/index'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

const ModalAvatar = ({ userId, user, visible, setVisible, fetchUser }) => {
  const [avatars, setAvatars] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selected, setSelected] = useState('')

  const fetchAvatars = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        'https://szjweqshf2.execute-api.us-west-2.amazonaws.com/avatar/perfil',
      )
      setAvatars(data)
      return setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAvatars()
  }, [])

  const handleSubmit = async () => {
    setSubmitLoading(true)
    try {
      await api.updateUser(userId, { avatar: selected })
      toast.success('Avatar actualizado con éxito!')
      setSubmitLoading(false)

      fetchUser()
      return setVisible(false)
    } catch (error) {
      setSubmitLoading(false)
      toast.error('Hubo un problema al actualizar los datos, intente de nuevo más tarde')
      return console.error(error)
    }
  }

  const handleUserAvatar = (avatar) => {
    if (selected) {
      return selected === avatar ? 'opacity-100' : 'opacity-50 hover:opacity-100'
    }
    if (avatar === user.avatar) return 'opacity-100'
    return 'opacity-50 hover:opacity-100'
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="mx-auto text-2xl text-primary">
              Selecciona un avatar
            </ModalHeader>
            <ModalBody className="flex flex-col py-0 space-y-1">
              <p className="text-center dark:text-foreground">
                Puedes seleccionar un avatar de la lista para establecerlo como el avatar de tu
                cuenta Propital
              </p>
              {loading ? (
                <Spinner color="primary" size="lg" />
              ) : (
                <div className="flex flex-wrap justify-center gap-3">
                  {avatars.map((avatar) => (
                    <img
                      key={avatar}
                      src={avatar}
                      alt={avatar}
                      className={`w-[56px] h-[56px] rounded-full cursor-pointer transition-all ${handleUserAvatar(
                        avatar,
                      )}`}
                      onClick={() => setSelected(avatar)}
                    />
                  ))}
                </div>
              )}
            </ModalBody>
            <ModalFooter className="flex justify-center mt-2">
              <Button
                color="primary"
                variant="bordered"
                className="w-fit rounded-3xl"
                onClick={() => setVisible(false)}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="shadow"
                className="w-fit rounded-3xl"
                onClick={() => handleSubmit()}
                isDisabled={!selected}
                isLoading={submitLoading}
              >
                Guardar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
}

export default ModalAvatar
