import React, { Fragment, useEffect, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
  Checkbox,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react'
import PropTypes from 'prop-types'
import { editStyles } from './styles'
import { avatars, passiveOptions } from 'constants/passives'
import { validationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { api } from 'api/index'
import { Utils } from 'helpers/index'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

const ModalEdit = ({ visible, setVisible, currentItem, userId, setItems }) => {
  const { uuid, basicInfo, paymentInfo } = currentItem

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const defaultValues = {
    includesQuota: {
      amortizacion: basicInfo.includesQuota.amortizacion,
      seguro: basicInfo.includesQuota.seguro,
      intereses: basicInfo.includesQuota.intereses,
    },
  }

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const {
    passiveType,
    passivePrice,
    currencyPassive,
    quotaValue,
    quotaCurrency,
    quotaPeriod,
    includesQuota,
    annualInterestRate,
    avatar,
  } = watch()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const passive = {
        passiveType,
        passivePrice: Utils.sanitizeNumericValue(passivePrice),
        currencyPassive,
        quotaValue: Utils.sanitizeNumericValue(quotaValue),
        quotaCurrency,
        quotaPeriod: Number(quotaPeriod),
        includesQuota,
        annualInterestRate: Number(annualInterestRate),
        avatar,
      }
      const { data } = await api.updatePassive(uuid, Utils.cleanObject(passive))
      const newPassives = data.data
      dispatch({ type: 'set', passives: newPassives })
      setItems(newPassives)
      toast.success('¡Pasivo modificado con éxito!')
      return setVisible(false)
    } catch (error) {
      setLoading(false)
      toast.error('Hubo un problema al modificar el pasivo, intenta nuevamente')
      return console.error(error)
    }
  }

  const haveErrors = Object.keys(errors).length !== 0
  const validate = !haveErrors && !!passiveType && !!passivePrice

  const benefits = [
    {
      label: 'Amortización',
      key: 'amortizacion',
      value: basicInfo.includesQuota.amortizacion,
    },
    {
      label: 'Seguros',
      key: 'seguro',
      value: basicInfo.includesQuota.seguro,
    },
    {
      label: 'Intereses',
      key: 'intereses',
      value: basicInfo.includesQuota.intereses,
    },
  ]

  const handleCheckbox = (e, target) => {
    return setValue('includesQuota', { ...includesQuota, [target]: e })
  }

  useEffect(() => {
    return setValue('avatar', avatars[passiveType])
  }, [passiveType])

  const handlePriceChange = (fieldName, e) => {
    let inputValue = e.target.value

    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''))

    if (!isNaN(numericValue)) {
      let formattedValue = numericValue.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      if (Number.isInteger(numericValue)) {
        formattedValue = numericValue.toLocaleString('es-AR')
      }

      setValue(fieldName, numericValue)
      e.target.value = formattedValue
    }
  }

  let tabs = [
    {
      id: 'info',
      label: 'Información',
      content: (
        <div className={editStyles.CONTENT}>
          <Select
            name="passiveType"
            label="Tipo de pasivo"
            color="primary"
            variant="underlined"
            items={passiveOptions.types}
            defaultSelectedKeys={[basicInfo.passiveType]}
            {...register('passiveType')}
          >
            {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
          </Select>
          <div className="flex space-x-2">
            <Input
              name="passivePrice"
              type="text"
              color={errors.passivePrice ? 'danger' : 'primary'}
              variant="underlined"
              label="Saldo capital"
              defaultValue={basicInfo.passivePrice.toLocaleString('es-AR')}
              {...register('passivePrice', { shouldDirty: true })}
              onChange={(e) => handlePriceChange('passivePrice', e)}
              isInvalid={errors.passivePrice}
              errorMessage={errors.passivePrice?.message}
            />
            <Select
              name="currencyPassive"
              label="Divisa"
              color="primary"
              variant="underlined"
              items={passiveOptions.currencies}
              defaultSelectedKeys={[basicInfo.currencyPassive]}
              {...register('currencyPassive')}
            >
              {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
            </Select>
          </div>
          <div className="flex space-x-2">
            <Input
              name="quotaValue"
              type="text"
              color={errors.quotaValue ? 'danger' : 'primary'}
              variant="underlined"
              label="Valor cuota"
              defaultValue={paymentInfo.quotaValue.toLocaleString('es-AR')}
              isInvalid={errors.quotaValue}
              errorMessage={errors.quotaValue?.message}
              {...register('quotaValue', { shouldDirty: true })}
              onChange={(e) => handlePriceChange('quotaValue', e)}
            />
            <Input
              name="quotaPeriod"
              type="number"
              color="primary"
              variant="underlined"
              label="Número de cuotas"
              defaultValue={paymentInfo.quotaPeriod}
              {...register('quotaPeriod')}
            />
          </div>
        </div>
      ),
    },
    {
      id: 'details',
      label: 'Detalles',
      content: (
        <div className={editStyles.CONTENT}>
          <Input
            name="annualInterestRate"
            type="number"
            color="primary"
            variant="underlined"
            label="Interés anual (%)"
            defaultValue={paymentInfo.annualInterestRate}
            {...register('annualInterestRate')}
          />
          <div className={`${editStyles.SECTION} space-y-0`}>
            <p className="text-small text-primary">Beneficios</p>
            {benefits.map((item, index) => (
              <Checkbox
                key={index}
                onValueChange={(value) => handleCheckbox(value, item.key)}
                defaultSelected={item.value}
              >
                <p className={`${editStyles.TEXT} text-foreground dark:text-white`}>{item.label}</p>
              </Checkbox>
            ))}
          </div>
        </div>
      ),
    },
  ]

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {() => (
          <Fragment>
            <ModalHeader className="pb-0 text-primary">Editar Pasivo</ModalHeader>
            <ModalBody>
              <div className="flex flex-col w-full">
                <Tabs color="primary" variant="solid" items={tabs} aria-label="movements">
                  {(item) => (
                    <Tab key={item.id} title={item.label} className="animate-fade">
                      {item.content}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button
                color="primary"
                variant="light"
                onClick={() => setVisible(false)}
                isDisabled={loading}
              >
                Cerrar
              </Button>
              <Button
                color="primary"
                isDisabled={!validate}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Guardar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  setItems: PropTypes.func.isRequired,
}

export default ModalEdit
