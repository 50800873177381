import React from 'react'
import PropTypes from 'prop-types'

const Content = ({ children, className = '', ...rest }) => {
  return (
    <div
      className={`p-3 rounded-2xl shadow-propital dark:shadow-none bg-white dark:bg-background dark:shadow-background ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Content
