export const formatPortfolioMovements = (array) => {
  return array.map((item) => {
    return {
      id: item.pk_inversion_id,
      name: item.publicacion_inversion.nombre_proyecto,
      image: item.publicacion_inversion.imagen_proyecto,
      date: item.fecha_inversion,
      amount: item.monto_inversion,
      totalBricks: item.cantidad_bricks_actual,
      status: item.inversion_estado.estado_inversion_descripcion,
      movements: item.pagos_inversion.map((move) => ({
        id: move.pk_pago_transferencia_inversion_id,
        inversionID: move.fk_inversion_id,
        amount: move.monto_pagado,
        date: move.fecha_pago_transferencia,
        hash: move.hash_id_transferencia,
        paymentStatus: move.fk_estado_pago_id,
      })),
    }
  })
}
