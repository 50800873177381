import useMetaPixel from 'hooks/useMetaPixel'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Se necesita llamar a pageView() cada vez que cambie la ubicación de la página
const MetaPixel = ({ children }) => {
  const { pageView } = useMetaPixel()
  const location = useLocation()

  const handlePageView = useCallback(() => {
    pageView()
  }, [location.pathname, pageView])

  useEffect(() => {
    handlePageView()
  }, [handlePageView])

  return children
}

export default MetaPixel
