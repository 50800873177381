import { lazy } from 'react'

const Legal = lazy(() => import('pages/Legal'))
const Income = lazy(() => import('pages/Income'))

const publicRoutes = [
  { path: '/legal', name: 'Legal y tributario', component: Legal },
  {
    path: '/income',
    name: 'Income',
    component: Income,
  },
]

export default publicRoutes
