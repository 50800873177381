export const icons = {
  themes: 'line-md:light-dark',
  paint: 'mdi:paint-outline',
  light: 'line-md:sun-rising-filled-loop',
  dark: 'line-md:sunny-filled-loop-to-moon-alt-filled-loop-transition',
  user: 'line-md:account',
  notification: 'line-md:bell',
  notificationActive: 'line-md:bell-twotone',
  news: 'line-md:confirm-circle',
  newsActive: 'line-md:confirm-circle-twotone',
  settings: 'line-md:cog-filled',
  logout: 'line-md:logout',
  login: 'line-md:log-in',
  portfolio: 'line-md:clipboard-check',
  profile: 'line-md:cog-filled',
  circle: 'line-md:round-360',
  fraccionario: 'f7:square-stack-3d-up',
  report: 'lucide:bug',
  loading: 'svg-spinners:3-dots-scale',
}

export const defaultAvatar =
  'https://prod-propital-avatares.s3.us-west-2.amazonaws.com/6326055-removebg-preview.png'
