import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  Avatar,
  Button,
  Divider,
  Progress,
  Skeleton,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { calculateProgress } from './controller'
import { api, apiReferrals } from 'api/index'
import PropTypes from 'prop-types'

const defaultAvatar =
  'https://prod-propital-avatares.s3.us-west-2.amazonaws.com/6326055-removebg-preview.png'

const Account = ({ user, setUser, userCustomerId, setModalAvatar, loading }) => {
  const [enable, setEnable] = useState(false)
  const { settings } = user
  const isVerified = settings?.isVerified
  const isReferred = settings?.isReferred
  const percentage = calculateProgress(user).progress

  const verifyUser = async () => {
    try {
      const payload = { ...settings, isVerified: true }
      await api.updateUser(userCustomerId, payload)
      setUser((prev) => ({ ...prev, settings: payload }))
    } catch (error) {
      console.error(error)
    }
  }

  const confirmReferral = async () => {
    try {
      await apiReferrals.confirmReferral({
        referral_type_id: 1,
        client_referred_id: userCustomerId,
      })
      const payload = { ...settings, isReferred: true }
      await api.updateUser(userCustomerId, payload)
      setUser((prev) => ({ ...prev, settings: payload }))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (percentage === 100 && !isVerified) {
      verifyUser()
    } else if (isVerified && !isReferred) {
      confirmReferral()
    }
  }, [settings])

  return (
    <Skeleton isLoaded={!loading} className={`${loading && 'rounded-2xl'}`}>
      <div className="flex items-center w-full py-3 space-x-3">
        <div className="relative">
          <Avatar
            src={user?.avatar || defaultAvatar}
            className={`min-w-[60px] min-h-[60px] cursor-pointer transition-all ${
              enable && 'opacity-20'
            }`}
            onMouseEnter={() => setEnable(true)}
            onMouseLeave={() => setEnable(false)}
          />
          {enable && (
            <Icon
              icon="clarity:edit-line"
              className="absolute w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer text-primary top-1/2 left-1/2"
              onMouseEnter={() => setEnable(true)}
              onClick={() => {
                setEnable(false)
                setModalAvatar(true)
              }}
            />
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center space-x-2">
            <p className="font-semibold text-primary dark:text-foreground">
              {user.first_name} {user.last_name}
            </p>
            <div className="min-w-[8px] min-h-[8px] mt-[2px] rounded-full bg-lime-500"></div>
          </div>
          <Button
            color="primary"
            variant="shadow"
            size="sm"
            className="h-6 mt-1 rounded-3xl w-fit"
            onClick={() => setModalAvatar(true)}
          >
            Editar
          </Button>
        </div>
      </div>
      <Divider />
      {percentage < 100 ? (
        <Progress
          size="lg"
          showValueLabel={true}
          label={
            <span className="text-base font-semibold text-primary">Progreso de tu perfil</span>
          }
          valueLabel={
            <span className="text-base font-semibold text-secondary dark:text-foreground">
              {percentage}%
            </span>
          }
          value={percentage}
          maxValue={100}
          className="px-2 my-2"
          classNames={{
            track: '-mt-2',
          }}
        />
      ) : (
        <Accordion selectionMode="single" className="px-0 mt-2">
          <AccordionItem
            title={
              <div className="flex items-center mx-2 space-x-2 text-base font-semibold text-primary">
                <Icon
                  icon="solar:medal-ribbon-star-bold-duotone"
                  className="w-6 h-6 text-primary"
                />
                <p>Progreso de mi perfil</p>
              </div>
            }
          >
            <div className="flex flex-col space-y-1 text-sm">
              <div className="flex items-center mx-2 space-x-2 text-primary">
                <Icon icon="ph:user-circle-check-duotone" className="w-6 h-6" />
                <p>Completa tu perfil</p>
              </div>
              <div className="flex items-center mx-2 space-x-2 text-neutral-400">
                <Icon icon="ph:user-switch-duotone" className="w-6 h-6" />
                <p>Refiere un contacto</p>
              </div>
              <div className="flex items-center mx-2 space-x-2 text-neutral-400">
                <Icon icon="ph:exclude-duotone" className="w-6 h-6" />
                <p>Registra activos y pasivos</p>
              </div>
              <div className="flex items-center mx-2 space-x-2 text-neutral-400">
                <Icon icon="solar:dna-bold-duotone" className="w-6 h-6" />
                <p>Sincroniza Propital Income</p>
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      )}
    </Skeleton>
  )
}

Account.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  userCustomerId: PropTypes.string.isRequired,
  setModalAvatar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default Account
