import React, { useState, useEffect } from 'react'
import { styles } from './styles'
import { api } from 'api'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PortfolioDetail } from 'containers/Project'
import { defaultValues } from 'constants/patrimony'
import { UI } from 'components/index'
import Patrimony from './Patrimony'
import List from './List'
import Header from './Header'
import Banner from './Banner'
import Datamart from './Datamart'

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [item, setItem] = useState(defaultValues)
  const [selectedList, setSelectedList] = useState('all')
  const [loading, setLoading] = useState(true)

  const currency = useSelector((state) => state.currency)
  const cachedPatrimony = useSelector((state) => state.patrimony)
  const user = useSelector((state) => state.user?.customer?.authenticated?.attributes)

  const fetchData = async () => {
    setLoading(true)
    try {
      api.syncCrowdfunding(user.sub)
    } catch (error) {
      console.error(error)
    }
    try {
      const { data } = await api.getPatrimony(user.sub) // ID
      if (typeof data === 'string') {
        setItem(defaultValues)
        return setLoading(false)
      }
      setItem(data)
      dispatch({ type: 'set', patrimony: data })
      return setLoading(false)
    } catch (error) {
      setItem(defaultValues)
      setLoading(false)
      return console.error(error)
    }
  }

  useEffect(() => {
    if (cachedPatrimony) {
      if (typeof cachedPatrimony === 'string') {
        setItem(defaultValues)
        return setLoading(false)
      }
      setItem(cachedPatrimony)
      return setLoading(false)
    }
    fetchData()
  }, [cachedPatrimony])

  return (
    <div className={styles.MAIN_CONTAINER}>
      <UI.Tour view="dashboard" />
      <Banner />
      <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3">
        <Header user={user} navigate={navigate} />
        <Datamart user={user} navigate={navigate} />
      </div>
      <div className="flex flex-col w-full space-y-3 lg:space-y-0 lg:space-x-3 lg:flex-row">
        <Patrimony
          loading={loading}
          item={item}
          currency={currency}
          navigate={navigate}
          selectedList={selectedList}
          dispatch={dispatch}
        />
        <List
          loading={loading}
          item={item}
          currency={currency}
          navigate={navigate}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      </div>
      <PortfolioDetail />
    </div>
  )
}

export default Dashboard
