import React, { Fragment, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { questions } from './constants'
import { api } from 'api/index'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'

const ModalRisk = ({
  visible,
  setVisible,
  riskProfile,
  setRiskProfile,
  riskLevel,
  fetchProfile,
}) => {
  const userCustomerId = useSelector(
    (state) => state.user?.customer.authenticated.attributes.sub || null,
  )
  const [loading, setLoading] = useState(false)

  const handleSelectionChange = (e, key) => {
    const value = parseInt(e.target.value)
    setRiskProfile((prev) => ({ ...prev, [key]: value }))
  }

  const handleUpdateRisk = async () => {
    setLoading(true)
    try {
      await api.updateUser(userCustomerId, { risk_profile: riskLevel.perfil })
      await fetchProfile()
      setLoading(false)
      toast.success('Evaluación completada! 🚀')
      return setVisible(false)
    } catch (error) {
      setLoading(false)
      toast.error('Hubo un problema al realizar la evaluación, intenta de nuevo')
      return console.error(error)
    }
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} size="xl" className="bg-background">
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="mx-auto text-xl text-primary">Perfil de riesgo</ModalHeader>
            <ModalBody className="flex flex-col py-0 space-y-1 max-h-[400px] overflow-y-auto">
              {questions.map((question, index) => (
                <div className="flex flex-col space-y-1" key={index}>
                  <p className="text-sm font-semibold text-secondary dark:text-primary">
                    {question.title}
                  </p>
                  <Select
                    color="primary"
                    variant="bordered"
                    placeholder="Selecciona una opción"
                    items={question.options}
                    listboxProps={{ className: 'bg-background' }}
                    popoverProps={{ className: 'bg-transparent p-1 w-full' }}
                    classNames={{ innerWrapper: 'py-0' }}
                    onChange={(e) => handleSelectionChange(e, question.key)}
                    aria-label="select"
                    aria-labelledby="select"
                  >
                    {(option) => (
                      <SelectItem classNames={{ title: 'text-clip' }} key={option.value}>
                        {option.label}
                      </SelectItem>
                    )}
                  </Select>
                </div>
              ))}
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <Button
                color="primary"
                variant="bordered"
                className="rounded-3xl min-w-[100px]"
                isDisabled={loading}
                onClick={() => setVisible(false)}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="shadow"
                className="rounded-3xl min-w-[100px]"
                isLoading={loading}
                onClick={() => handleUpdateRisk()}
              >
                Completar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalRisk.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  riskProfile: PropTypes.object.isRequired,
  setRiskProfile: PropTypes.func.isRequired,
  riskLevel: PropTypes.any,
  fetchProfile: PropTypes.func.isRequired,
}

export default ModalRisk
