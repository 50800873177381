import React, { Fragment } from 'react'
import { useTheme } from 'next-themes'
import { Button, Select, SelectItem } from '@nextui-org/react'
import { App, UI } from 'components/index'
import { hints } from 'constants/hints'
import { styles } from './styles'
import { chartOptions } from './constants'
import { Icon } from '@iconify/react'
import CountUp from 'react-countup'
import PropTypes from 'prop-types'

const Patrimony = ({ loading, item, currency, navigate, selectedList, dispatch }) => {
  const { theme } = useTheme()

  const calculateTotal = (obj = { anyValue: 0 }) => {
    if (Object.keys(obj).length === 0) return 0
    return Object.values(obj).reduce((a, b) => a + b)
  }

  const calculateItems = (obj) => {
    return Object.entries(obj).map(([name, value]) => ({ name, value }))
  }

  const chartData = {
    all: [
      {
        name: 'Activos',
        value: calculateTotal(item.assetsTotalCurrencies[currency]),
      },
      {
        name: 'Pasivos',
        value: calculateTotal(item.passivesTotalCurrencies[currency]),
      },
    ],
    assets: calculateItems(item.assetsTotalCurrencies[currency]),
    passives: calculateItems(item.passivesTotalCurrencies[currency]),
  }

  const currencies = [
    { name: 'CLP', value: 'CLP' },
    { name: 'USD', value: 'USD' },
    { name: 'UF', value: 'UF' },
  ]

  const handleCurrency = (value) => {
    localStorage.setItem('currency', value)
    dispatch({ type: 'set', currency: value })
  }

  return (
    <UI.Content className={`${styles.CONTENT} w-full step-3`}>
      {loading ? (
        <div className="flex items-center justify-center h-full min-h-[30vh]">
          <App.Loader />
        </div>
      ) : (
        <Fragment>
          <div className="relative max-h-[400px] h-[45vh]">
            <Select
              variant="flat"
              color="primary"
              size="sm"
              className="absolute top-0 right-0 w-[100px] step-4"
              selectedKeys={[currency]}
              defaultSelectedKeys={[currency]}
              onChange={(e) => handleCurrency(e.target.value)}
              classNames={{
                value: 'font-semibold pl-2',
              }}
            >
              {currencies.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.name}
                </SelectItem>
              ))}
            </Select>
            <div className="flex items-center space-x-1">
              <p className="text-xl font-semibold text-primary">Mi patrimonio</p>
              <UI.ModalHelper
                content={hints.patrimony}
                actions={
                  <div className="flex flex-wrap space-x-2">
                    <Button color="primary" variant="bordered" className="rounded-3xl">
                      Agregar pasivos
                    </Button>
                    <Button color="primary" variant="shadow" className="rounded-3xl">
                      Agregar activos
                    </Button>
                  </div>
                }
              />
            </div>
            <UI.Chart options={chartOptions(theme === 'dark', chartData[selectedList])} />
            <div className={`${styles.TOTAL_CONTAINER} mt-[32px]`}>
              <CountUp
                className={styles.TOTAL_TEXT}
                end={
                  selectedList === 'passives'
                    ? chartData.all[1].value
                    : item.totalPatrimonyCurrencies[currency]
                }
                prefix={`$`}
                duration={1}
                separator="."
              />
              <div className="flex justify-around w-full space-x-2 text-primary">
                <div className="relative">
                  <div
                    className="cursor-pointer w-max"
                    onClick={() => {
                      window.scrollTo(0, 0)
                      navigate('/assets')
                    }}
                  >
                    <CountUp
                      className="p-1 rounded-3xl bg-primary/10"
                      style={{ fontSize: '11px' }}
                      end={chartData.all[0].value}
                      prefix={`▲ $`}
                      duration={1}
                      separator="."
                    />
                  </div>
                  <div className="absolute top-0 right-0 -mt-1 -mr-2 scale-75">
                    <UI.ModalHelper
                      content={hints.assets}
                      actions={
                        <Button
                          color="primary"
                          variant="shadow"
                          className="rounded-3xl"
                          onClick={() => {
                            window.scrollTo(0, 0)
                            navigate('/assets')
                          }}
                        >
                          Ver mis activos
                        </Button>
                      }
                    />
                  </div>
                </div>
                <div className="relative">
                  <div
                    className="cursor-pointer w-max"
                    onClick={() => {
                      window.scrollTo(0, 0)
                      navigate('/passives')
                    }}
                  >
                    <CountUp
                      className="p-1 rounded-3xl bg-primary/10"
                      style={{ fontSize: '11px' }}
                      end={chartData.all[1].value}
                      prefix={`▼ $`}
                      duration={1}
                      separator="."
                    />
                  </div>
                  <div className="absolute top-0 right-0 -mt-1 -mr-2 scale-75">
                    <UI.ModalHelper
                      content={hints.passives}
                      actions={
                        <Button
                          color="primary"
                          variant="shadow"
                          className="rounded-3xl"
                          onClick={() => {
                            window.scrollTo(0, 0)
                            navigate('/passives')
                          }}
                        >
                          Ver mis pasivos
                        </Button>
                      }
                    />
                  </div>
                </div>
              </div>
              <Button
                color="primary"
                variant="shadow"
                size="sm"
                className="rounded-3xl h-[26px] w-min mx-auto"
                endContent={
                  <Icon
                    className="min-h-[16px] min-w-[16px] animate-jump-in animate-duration-1000 -ml-1"
                    icon="heroicons:rocket-launch"
                  />
                }
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate('/opportunities')
                }}
              >
                Invertir
              </Button>
            </div>
          </div>
        </Fragment>
      )}
      <p className={styles.LAST_UPDATE}>Actualizado: {item?.metadata?.lastUpdate}</p>
    </UI.Content>
  )
}

Patrimony.propTypes = {
  loading: PropTypes.bool,
  item: PropTypes.object,
  currency: PropTypes.string,
  navigate: PropTypes.func,
  selectedList: PropTypes.string,
  dispatch: PropTypes.func,
}

export default Patrimony
