import { lazy } from 'react'

const Home = lazy(() => import('pages/Home'))
const Assets = lazy(() => import('pages/Assets'))
const CreateAsset = lazy(() => import('pages/CreateAsset'))
const Passives = lazy(() => import('pages/Passives'))
const CreatePassive = lazy(() => import('pages/CreatePassive'))
const Opportunities = lazy(() => import('pages/Opportunities'))
const Profile = lazy(() => import('pages/Profile'))
const Academy = lazy(() => import('pages/Academy'))
const Congrats = lazy(() => import('pages/Congrats'))

const contentRoutes = [
  {
    linkTo: '/#/',
    path: '/',
    name: 'Inicio',
    component: Home,
    icon: 'heroicons:home-20-solid',
    showNavigate: true,
  },
  {
    linkTo: '/#/assets',
    path: '/assets',
    name: 'Activos',
    component: Assets,
    icon: 'line-md:arrow-up-circle',
    showNavigate: true,
  },
  {
    linkTo: '/#/create-assets',
    path: '/create-assets',
    name: 'Agregar activos',
    component: CreateAsset,
    icon: '',
    showNavigate: false,
  },
  {
    linkTo: '/#/passives',
    path: '/passives',
    name: 'Pasivos',
    component: Passives,
    icon: 'line-md:arrow-down-circle',
    showNavigate: true,
  },
  {
    linkTo: '/#/create-passives',
    path: '/create-passives',
    name: 'Agregar pasivos',
    component: CreatePassive,
    icon: '',
    showNavigate: false,
  },
  {
    linkTo: '/#/opportunities',
    path: '/opportunities',
    name: 'Oportunidades',
    component: Opportunities,
    icon: 'heroicons:rocket-launch',
    showNavigate: true,
  },
  {
    linkTo: '/#/academy',
    path: '/academy',
    name: 'Academy',
    component: Academy,
    icon: 'fluent:hat-graduation-16-regular',
    showNavigate: true,
  },
  {
    linkTo: '/#/profile',
    path: '/profile',
    name: 'Mi perfil',
    component: Profile,
    icon: 'lucide:user-cog',
    showNavigate: true,
  },
  {
    linkTo: '/#/muchas-gracias',
    path: '/muchas-gracias',
    name: '¡Muchas gracias!',
    component: Congrats,
    icon: '',
    showNavigate: false,
    protected: true,
  },
]

export default contentRoutes
