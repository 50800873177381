import React, { createRef, useEffect } from 'react'
import useLayout from 'hooks/useLayout'
import PropTypes from 'prop-types'
import { useTheme } from 'next-themes'
import * as echarts from 'echarts'

const Chart = ({ options, className }) => {
  const chartRef = createRef()
  const { layout } = useLayout()
  const { theme } = useTheme()

  useEffect(() => {
    const chart = echarts.init(chartRef.current)
    chart.setOption(options)
    return () => {
      chart.dispose()
    }
    // Add options dependency when needed
  }, [layout.breakpoint, theme, options])

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} className={className} />
}

// Docs:
// https://echarts.apache.org/examples/en/#chart-type-pie
// https://echarts.apache.org/en/option.html#title

Chart.propTypes = {
  options: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default Chart
