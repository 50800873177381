export const inputs = [
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    placeholder: 'Ingresa tu correo electrónico',
  },
  {
    type: 'text',
    name: 'code',
    label: 'Código',
    placeholder: 'Ingresa el código',
  },
]
