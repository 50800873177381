import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const disabledRoutes = [
  '/login',
  '/register',
  '/confirm',
  '/recovery',
  '/recoverySubmit',
  '/newPassword',
]

const ChatBot = () => {
  const location = useLocation()
  const [wait, setWait] = useState(false)

  const handleScript = () => {
    if (!disabledRoutes.includes(location.pathname) && wait) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = 'hs-script-loader'
      script.async = true
      script.defer = true
      script.src = '//js-na1.hs-scripts.com/3478246.js'

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    } else {
      const script = document.getElementById('hs-script-loader')
      if (script) {
        document.body.removeChild(script)
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWait(true)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    return handleScript()
  }, [location, wait])

  return null
}

export default ChatBot
