export const defaultValues = {
  totalPatrimonyCurrencies: {
    CLP: 0,
    USD: 0,
    UF: 0,
  },
  assetsTotalCurrencies: {
    CLP: {},
    USD: {},
    UF: {},
  },
  passivesTotalCurrencies: {
    CLP: {},
    USD: {},
    UF: {},
  },
  exchangeRates: {
    USDtoCLP: 0,
    CLFtoCLP: 0,
  },
  metadata: {
    creationDate: '',
    lastUpdate: '',
  },
}
