import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Input } from '@nextui-org/react'
import { inputs } from './constants'
import { styles } from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import { Icon } from '@iconify/react'
import { App } from 'helpers/index'
import { useLocation } from 'react-router-dom'
import authImage from 'assets/svg/auth-footer.svg'
import useConversion from 'hooks/useConversion'
import useAuth from 'hooks/useAuth'
import CryptoJS from 'crypto-js'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './customflag.css'
import ModalToS from 'components/App/ModalToS'

const defaultValues = {
  name: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const Register = () => {
  const [userIp, setUserIp] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [openToS, setOpenToS] = useState(false)
  const [checkedToS, setCheckedToS] = useState(false)

  const { signUp, loading } = useAuth()
  const { sendEvent } = useConversion()
  const { search } = useLocation()

  const referralCode = search?.split('?code=')?.[1] ?? null

  const [isClicked, setIsClicked] = useState(false)
  const containerRef = useRef(null)

  const {
    watch,
    register,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (App.isProd()) {
      App.getIp(userIp, setUserIp)
    }
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode)
    }
  }, [])

  const { name, lastName, phone, email, password, confirmPassword } = watch()

  const eventData = {
    event_name: 'CompleteRegistration',
    user_data: {
      fn: CryptoJS.SHA256(name.trim().toLowerCase()).toString(),
      ln: CryptoJS.SHA256(lastName.trim().toLowerCase()).toString(),
      em: CryptoJS.SHA256(email.trim().toLowerCase()).toString(),
      ph: CryptoJS.SHA256(phone.trim()).toString(),
      client_ip_address: userIp,
    },
  }

  const handleSignUp = (e) => {
    if (!isValid) return toast.error('Completa los campos correctamente')
    if (checkedToS) {
      sendEvent(eventData)
      const userData = {
        name,
        lastName,
        phone,
        email,
        password,
        confirmPassword,
      }
      signUp(userData)
    }

    e.preventDefault()
  }

  const handleType = (inputName, inputType) => {
    if (inputName === 'password' || inputName === 'confirmPassword') {
      return showPassword ? 'text' : 'password'
    }
    return inputType
  }

  const handleEndContent = (inputName) => {
    if (inputName === 'password' || inputName === 'confirmPassword') {
      return (
        <Icon
          className={styles.ICON}
          onClick={() => setShowPassword(!showPassword)}
          icon={showPassword ? 'line-md:watch-off' : 'line-md:watch'}
        />
      )
    }
    return null
  }

  const handleContainerClick = () => {
    setIsClicked(!isClicked)
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsClicked(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const containerClassName = `container-tel ${isClicked && !errors.phone ? 'clicked' : ''} ${
    errors.phone ? 'error' : ''
  }`

  const inputStyle = {
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    border: '2px solid #f5f8ff',
    backgroundColor: '#f5f8ff',
  }

  return (
    <div className={styles.MAIN_CONTAINER}>
      {openToS && <ModalToS visible={openToS} setVisible={setOpenToS} />}
      <form className={styles.FORM}>
        <p className={styles.TITLE}>Registrar cuenta</p>
        {inputs.map((input) =>
          input.name === 'phone' ? (
            <div key={input.name}>
              <div
                key={input.name}
                ref={containerRef}
                className={`${containerClassName} ${errors.phone ? 'error' : ''}`}
                onClick={handleContainerClick}
              >
                <p className={`input-tel-title ${errors.phone ? 'error' : ''}`}>Teléfono</p>
                <PhoneInput
                  placeholder={input.placeholder}
                  disableDropdown={true}
                  autoFormat={false}
                  inputStyle={inputStyle}
                  country={'cl'}
                  onChange={(value) => {
                    setValue(input.name, value)
                    trigger(input.name)
                  }}
                />
              </div>
              {errors.phone && (
                <div className="error-container text-tiny text-danger">{errors.phone.message}</div>
              )}
            </div>
          ) : (
            <Input
              key={input.name}
              type={handleType(input.name, input.type)}
              name={input.name}
              label={input.label}
              placeholder={input.placeholder}
              {...register(input.name)}
              isInvalid={errors[input.name]}
              errorMessage={errors[input.name]?.message}
              color={errors[input.name] ? 'danger' : 'primary'}
              variant="bordered"
              endContent={handleEndContent(input.name)}
            />
          ),
        )}
        <Checkbox
          classNames={{
            label: 'text-sm',
          }}
          isDisabled={!isValid}
          defaultChecked={false}
          onChange={setCheckedToS}
        >
          He leído y acepto los
          <strong className="text-primary" onClick={() => setOpenToS(true)}>
            {' '}
            Términos y Condiciones de Propital
          </strong>
        </Checkbox>

        <Button
          color="primary"
          onClick={(e) => handleSignUp(e)}
          isDisabled={!checkedToS || !isValid}
          isLoading={loading}
        >
          Crear cuenta
        </Button>
        <div className={styles.BOTTOM_CONTENT}>
          <p>¿Ya tienes una cuenta creada?</p>
          <a href="/#/login" className={styles.LOGIN}>
            Ingresa aquí
          </a>
        </div>
      </form>
      <img src={authImage} className={styles.FOOTER} alt="auth-footer" />
    </div>
  )
}

export default Register
