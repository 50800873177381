import React, { Fragment, useEffect, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react'
import PropTypes from 'prop-types'
import { detailStyles, editStyles } from './styles'
import { avatars, assetOptions } from 'constants/assets'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultSchema, validationSchemas } from './validations'
import { api } from 'api/index'
import { Utils } from 'helpers/index'
import { UI } from 'components/index'
import toast from 'react-hot-toast'
import useGooglePlaces from 'hooks/useGooglePlaces'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useDispatch } from 'react-redux'

const ModalEdit = ({ visible, setVisible, currentItem, setItems }) => {
  dayjs.extend(utc)
  const [validationType, setValidationType] = useState('')

  const {
    uuid,
    financialData: defaultFinancialData,
    location: defaultLocation,
    nameProject: defaultNameProject,
    description: defaultDescription,
  } = currentItem

  const dispatch = useDispatch()
  const { AutoComplete, coordinates, placeDetails } = useGooglePlaces()
  const [position, setPosition] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleValidationResolver = () => {
    if (validationType) return validationSchemas[validationType]
    return defaultSchema
  }

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(handleValidationResolver()),
  })

  const {
    assetType,
    assetSubType,
    assetPrice,
    currencyAsset,
    rentalPrice,
    annualVacancy,
    annualPropTax,
    description,
    nameProject,
    bricksNumber,
    brickPrice,
    sharesNumber,
    sharePrice,
    bank,
    depositType,
    startDate,
    finishDate,
    typeMutualFund,
    address,
    longitude,
    latitude,
    avatar,
  } = watch()

  // El payload hacia la API debe ser distinto según el tipo de activo
  const handlePayload = (assetType, assetSubType = null) => {
    const defaultAsset = {
      assetType,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
      currencyAsset,
      avatar,
    }
    const assetWithProject = {
      ...defaultAsset,
      description,
      nameProject,
    }
    if (assetType === 'Real Estate') {
      const asset = {
        ...assetWithProject,
        assetSubType,
        address,
        longitude,
        latitude,
      }
      const assetWithRental = {
        ...asset,
        rentalPrice: Utils.sanitizeNumericValue(rentalPrice),
        annualVacancy: annualVacancy ? parseInt(annualVacancy) : null,
        annualPropTax: annualPropTax ? Utils.sanitizeNumericValue(annualPropTax) : null,
      }
      if (assetSubType !== 'Terreno' && assetSubType !== 'Parcela') {
        return assetWithRental
      }
      return asset
    }
    const payloads = {
      Fraccionario: {
        ...assetWithProject,
        bricksNumber: parseInt(bricksNumber),
        brickPrice: Utils.sanitizeNumericValue(brickPrice),
      },
      Acciones: {
        ...assetWithProject,
        sharesNumber: parseInt(sharesNumber),
        sharePrice: Utils.sanitizeNumericValue(sharePrice),
      },
      'Fondos mutuos': {
        ...assetWithProject,
        typeMutualFund,
      },
      'Depósito a plazo': {
        ...defaultAsset,
        bank,
        depositType,
        finishDate, // (YYYY-MM-DD)
        startDate, // (YYYY-MM-DD)
      },
      Otros: {
        ...assetWithProject,
      },
      Vehiculo: {
        ...assetWithProject,
      },
    }
    return payloads[assetType]
  }

  const payload = handlePayload(assetType, assetSubType)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const { data } = await api.updateAsset(uuid, Utils.cleanObject(payload))
      // Nuevo array modificado
      const newAssets = data.data
      dispatch({ type: 'set', assets: newAssets })
      setItems(newAssets)
      toast.success('¡Activo modificado con éxito!')
      return setVisible(false)
    } catch (error) {
      setLoading(false)
      toast.error('Hubo un problema al modificar el activo, intenta nuevamente')
      return console.error(error)
    }
  }

  const handlePriceChange = (fieldName, e) => {
    let inputValue = e.target.value

    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''))

    if (!isNaN(numericValue)) {
      let formattedValue = numericValue.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      if (Number.isInteger(numericValue)) {
        formattedValue = numericValue.toLocaleString('es-AR')
      }

      setValue(fieldName, numericValue)
      e.target.value = formattedValue
    }
  }

  let tabs = [
    {
      id: 'info',
      label: 'Información',
      content: (
        <div className={editStyles.CONTENT}>
          <div className={detailStyles.HEADER}>
            <img src={currentItem.avatar} className={detailStyles.IMAGE} alt="asset" />
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>
                {defaultFinancialData.assetSubType
                  ? defaultFinancialData.assetSubType
                  : defaultFinancialData.assetType}
              </p>
            </div>
          </div>
          <div className="flex space-x-2">
            <Input
              name="assetPrice"
              type="text"
              color={errors.assetPrice ? 'danger' : 'primary'}
              variant="underlined"
              label="Precio del activo"
              defaultValue={defaultFinancialData?.assetPrice?.toLocaleString('es-AR')}
              {...register('assetPrice')}
              onChange={(e) => handlePriceChange('assetPrice', e)}
              startContent={<p className="text-primary/30 dark:text-foreground/30">$</p>}
              isInvalid={errors.assetPrice}
              errorMessage={errors.assetPrice?.message}
            />
            <Select
              name="currencyAsset"
              label="Divisa"
              color="primary"
              variant="underlined"
              items={assetOptions.currencies}
              defaultSelectedKeys={[defaultFinancialData.currencyAsset]}
              {...register('currencyAsset')}
              disallowEmptySelection
            >
              {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
            </Select>
          </div>
          {defaultNameProject && (
            <div className="flex space-x-2">
              <Input
                name="nameProject"
                type="text"
                color="primary"
                variant="underlined"
                label="Nombre del activo"
                defaultValue={defaultNameProject}
                {...register('nameProject')}
                isInvalid={errors.nameProject}
                errorMessage={errors.nameProject?.message}
              />
              <Input
                name="description"
                type="text"
                color="primary"
                variant="underlined"
                label="Descripción"
                defaultValue={defaultDescription}
                {...register('description')}
              />
            </div>
          )}
          {defaultFinancialData.assetType === 'Fraccionario' && (
            <div className="flex space-x-2">
              <Input
                name="bricksNumber"
                type="text"
                color="primary"
                variant="underlined"
                label="Cantidad de bricks"
                defaultValue={defaultFinancialData?.bricksNumber}
                {...register('bricksNumber')}
                isInvalid={errors.bricksNumber}
                errorMessage={errors.bricksNumber?.message}
              />
              <Input
                name="brickPrice"
                type="text"
                color="primary"
                variant="underlined"
                label="Precio del brick"
                defaultValue={defaultFinancialData?.brickPrice?.toLocaleString('es-AR')}
                {...register('brickPrice')}
                onChange={(e) => handlePriceChange('brickPrice', e)}
                startContent={<p className="text-primary/30 dark:text-foreground/30">$</p>}
                isInvalid={errors.brickPrice}
                errorMessage={errors.brickPrice?.message}
              />
            </div>
          )}
          {defaultFinancialData.assetType === 'Acciones' && (
            <div className="flex space-x-2">
              <Input
                name="sharesNumber"
                type="number"
                color="primary"
                variant="underlined"
                label="Cantidad de acciones"
                defaultValue={defaultFinancialData?.sharesNumber}
                {...register('sharesNumber')}
                isInvalid={errors.sharesNumber}
                errorMessage={errors.sharesNumber?.message}
              />
              <Input
                name="sharePrice"
                type="text"
                color="primary"
                variant="underlined"
                label="Precio de la acción"
                defaultValue={defaultFinancialData?.sharePrice?.toLocaleString('es-AR')}
                {...register('sharePrice')}
                onChange={(e) => handlePriceChange('sharePrice', e)}
                startContent={<p className="text-primary/30 dark:text-foreground/30">$</p>}
                isInvalid={errors.sharePrice}
                errorMessage={errors.sharePrice?.message}
              />
            </div>
          )}
          {defaultFinancialData.assetType === 'Fondos mutuos' && (
            <Input
              name="typeMutualFund"
              type="text"
              color={errors.bank ? 'danger' : 'primary'}
              variant="underlined"
              label="Tipo de fondo al que pertenece"
              defaultValue={defaultFinancialData?.typeMutualFund}
              {...register('typeMutualFund')}
              isInvalid={errors.typeMutualFund}
              errorMessage={errors.typeMutualFund?.message}
            />
          )}
          {defaultFinancialData.assetType === 'Depósito a plazo' && (
            <Fragment>
              <div className="flex space-x-2">
                <Input
                  name="bank"
                  type="text"
                  color={errors.bank ? 'danger' : 'primary'}
                  variant="underlined"
                  label="Nombre del banco"
                  defaultValue={defaultFinancialData?.bank}
                  {...register('bank')}
                  isInvalid={errors.bank}
                  errorMessage={errors.bank?.message}
                />
                <Select
                  name="depositType"
                  label="Tipo de depósito"
                  color="primary"
                  variant="underlined"
                  items={assetOptions.depositType}
                  defaultSelectedKeys={[defaultFinancialData.depositType]}
                  {...register('depositType')}
                  disallowEmptySelection
                >
                  {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
                </Select>
              </div>
              <div className="flex space-x-2">
                <Input
                  name="startDate"
                  type="date"
                  color={errors.startDate ? 'danger' : 'primary'}
                  variant="underlined"
                  label="Fecha de inicio"
                  defaultValue={dayjs(defaultFinancialData.startDate).utc().format('YYYY-MM-DD')}
                  {...register('startDate')}
                  isInvalid={errors.startDate}
                  errorMessage={errors.startDate?.message}
                />
                <Input
                  name="finishDate"
                  type="date"
                  color={errors.finishDate ? 'danger' : 'primary'}
                  variant="underlined"
                  label="Fecha de fin"
                  defaultValue={dayjs(defaultFinancialData.finishDate).utc().format('YYYY-MM-DD')}
                  {...register('finishDate')}
                  isInvalid={errors.finishDate}
                  errorMessage={errors.finishDate?.message}
                />
              </div>
            </Fragment>
          )}
          {defaultFinancialData.assetSubType &&
            defaultFinancialData.assetSubType !== 'Parcela' &&
            defaultFinancialData.assetSubType !== 'Terreno' && (
              <Fragment>
                <Input
                  name="rentalPrice"
                  type="text"
                  color={errors.rentalPrice ? 'danger' : 'primary'}
                  variant="underlined"
                  label="Canon de arriendo"
                  defaultValue={defaultFinancialData?.rentalPrice?.toLocaleString('es-AR')}
                  {...register('rentalPrice')}
                  onChange={(e) => handlePriceChange('rentalPrice', e)}
                  startContent={<p className="text-primary/30 dark:text-foreground/30">$</p>}
                  isInvalid={errors.rentalPrice}
                  errorMessage={errors.rentalPrice?.message}
                />
                <div className="flex space-x-2">
                  <Input
                    name="annualPropTax"
                    type="text"
                    color="primary"
                    variant="underlined"
                    label="Impuesto territorial anual"
                    placeholder="(Opcional)"
                    defaultValue={defaultFinancialData?.annualPropTax?.toLocaleString('es-AR')}
                    {...register('annualPropTax')}
                    onChange={(e) => handlePriceChange('annualPropTax', e)}
                    startContent={<p className="text-primary/30 dark:text-foreground/30">$</p>}
                    isInvalid={errors.annualPropTax}
                    errorMessage={errors.annualPropTax?.message}
                  />
                  <Input
                    name="annualVacancy"
                    type="number"
                    color="primary"
                    variant="underlined"
                    label="Vacancia anual estimada"
                    placeholder="(Opcional)"
                    defaultValue={defaultFinancialData.annualVacancy}
                    {...register('annualVacancy')}
                    startContent={<p className="text-primary/30 dark:text-foreground/30">%</p>}
                    isInvalid={errors.annualVacancy}
                    errorMessage={errors.annualVacancy?.message}
                  />
                </div>
              </Fragment>
            )}
        </div>
      ),
    },
  ]

  let locationTab = [
    {
      id: 'location',
      label: 'Ubicación',
      content: defaultLocation && (
        <div className={editStyles.CONTENT}>
          {AutoComplete()}
          <UI.Map
            height="200px"
            center={
              position
                ? position
                : { lat: defaultLocation.latitude, lng: defaultLocation.longitude }
            }
            currentPosition={
              position
                ? position
                : { lat: defaultLocation.latitude, lng: defaultLocation.longitude }
            }
            markers={
              position
                ? [position]
                : [{ lat: defaultLocation.latitude, lng: defaultLocation.longitude }]
            }
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (coordinates) {
      setValue('latitude', coordinates.lat)
      setValue('longitude', coordinates.lng)
      setValue('address', placeDetails.formatted_address)
      return setPosition(coordinates)
    }
  }, [coordinates])

  useEffect(() => {
    setValidationType(assetType)
    if (assetSubType) {
      return setValue('avatar', avatars[assetSubType])
    }
    return setValue('avatar', avatars[assetType])
  }, [assetType])

  // En el primer render se debe actualizar el watch()
  useEffect(() => {
    if (currentItem) {
      const defaultFields = ['avatar', 'description', 'nameProject']
      defaultFields.forEach((field) => {
        return setValue(field, currentItem[field])
      })
      const financialFields = ['assetType', 'assetSubType', 'assetPrice', 'currencyAsset']
      financialFields.forEach((field) => {
        return setValue(field, currentItem.financialData[field])
      })
      if (defaultFinancialData.assetType === 'Real Estate') {
        const locationFields = ['address', 'latitude', 'longitude']
        locationFields.forEach((field) => {
          return setValue(field, currentItem.location[field])
        })
      }
    }
  }, [])

  const haveErrors = Boolean(Object.keys(errors).length)

  const validations = {
    'Real Estate': Boolean(
      assetType &&
        assetPrice &&
        currencyAsset &&
        nameProject &&
        latitude &&
        longitude &&
        !haveErrors,
    ),
    Fraccionario: Boolean(
      assetType &&
        assetPrice &&
        currencyAsset &&
        nameProject &&
        bricksNumber &&
        brickPrice &&
        !haveErrors,
    ),
    Acciones: Boolean(
      assetType &&
        assetPrice &&
        currencyAsset &&
        nameProject &&
        sharesNumber &&
        sharePrice &&
        !haveErrors,
    ),
    'Fondos mutuos': Boolean(
      assetType && assetPrice && currencyAsset && nameProject && typeMutualFund && !haveErrors,
    ),
    'Depósito a plazo': Boolean(
      assetType && currencyAsset && bank && depositType && startDate && finishDate && !haveErrors,
    ),
    Vehiculo: Boolean(assetType && assetPrice && currencyAsset && nameProject && !haveErrors),
    Otros: Boolean(assetType && currencyAsset && nameProject && !haveErrors),
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {() => (
          <Fragment>
            <ModalHeader className="pb-0 text-primary">Editar Activo</ModalHeader>
            <ModalBody>
              <div className="flex flex-col w-full">
                <Tabs
                  color="primary"
                  variant="solid"
                  items={defaultLocation ? [...tabs, ...locationTab] : tabs}
                  aria-label="movements"
                >
                  {(item) => (
                    <Tab key={item.id} title={item.label} className="animate-fade">
                      {item.content}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button
                color="primary"
                variant="light"
                onClick={() => setVisible(false)}
                isDisabled={loading}
              >
                Cerrar
              </Button>
              <Button
                color="primary"
                isDisabled={loading || !validations[assetType]}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Guardar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
  setItems: PropTypes.func.isRequired,
}

export default ModalEdit
