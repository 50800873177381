export const styles = {
  MAIN_CONTAINER: 'flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto',
  CONTENT: 'flex flex-col space-y-3 md:max-w-xl md:mx-auto',
  STEPPER: 'flex justify-center space-x-6',
  STEP_CONTAINER: 'flex flex-col space-y-3',
  STEP: 'flex items-center justify-center w-8 h-8 rounded-full',
  TITLE: 'py-1 text-lg font-semibold text-center',
  FORM_CONTAINER: 'grid grid-cols-2 gap-2 md:gap-4',
  ACTIONS: 'flex mx-auto space-x-3 pt-3 transition-all md:w-fit',
  BUTTON: 'w-full rounded-3xl',
  CHECK_GROUP_TITLE: 'px-3 mb-1 text-xs text-primary',
  CHECKBOX_CONTAINER: 'flex flex-col items-center space-y-2 w-full',
  CHECKBOX_CONTENT:
    'p-3 transition-colors border-2 rounded-xl border-default-200 hover:border-default-400 w-full',
  CHECKBOX_LABEL: 'text-sm font-normal opacity-60',
}

export const cardStyles = {
  CARDS_CONTAINER: 'flex flex-wrap gap-3 max-w-4xl justify-center mx-auto',
  CARD: 'border-none shadow-propital dark:shadow-none !rounded-2xl bg-white dark:bg-background',
  IMAGE: 'w-full object-contain h-[160px] border-none shadow-none',
  NAME: 'text-sm font-semibold text-secondary dark:text-foreground',
}
