import axios from 'axios'

export const API_FRAC = process.env.REACT_APP_API_FRAC
export const API_FINTOC = process.env.REACT_APP_API_FINTOC
export const FINTOC_PUBLIC_KEY = process.env.REACT_APP_FINTOC_PUBLIC_KEY

// TODO: Probar en producción, verificar que funcione y luego crear variables de entorno
const PIXEL_ID = '407647136233806'
const META_URL = 'https://graph.facebook.com/v18.0'
export const META_TOKEN =
  'EAAr8KnzhZA30BO1nnFisB0DCXdCNJjMDxzET3qGBvCkzOnXFqwvyyR3RnqwKOAB7yUPhP5KcJYDuVtm0TGKGPzUbC9SY92MpipcQmEfGIv0XwS2tIDu96XhGRARiq80fLmKEufdDuZBv3tbNpkf3oYDwkZA0j9ZC0NvddBzZALKq3yUVSoIINF1BbdfwfWgZDZD'

export const env = (KEY) => process.env[`REACT_APP_${KEY}`]

// Token Authorizations
const session = JSON.parse(localStorage.getItem('session'))
const token = session?.customer?.session?.idToken?.jwtToken
const tokenFrac = session?.frac?.token

const api = axios.create({
  baseURL: env('BASE_URL'),
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

const communicator = axios.create({
  baseURL: env('API_COMMUNICATOR'),
  headers: {
    api_key_header: env('API_KEY_COMMUNICATOR'),
  },
})

const dau = axios.create({
  baseURL: env('API_URL_DAU'),
  headers: {
    api_key_header: env('API_KEY_DAU'),
  },
})

const fintoc = axios.create({
  baseURL: API_FINTOC,
})

const frac = axios.create({
  baseURL: API_FRAC,
  headers: {
    Authorization: `Bearer ${tokenFrac}`,
  },
})

const referrals = axios.create({
  baseURL: env('API_REFERRALS'),
  headers: {
    api_key_header: env('API_KEY_REFERRALS'),
  },
})

const slackbot = axios.create({
  baseURL: env('SLACK_BOT'),
})

const metaConversion = axios.create({
  baseURL: `${META_URL}/${PIXEL_ID}`,
})

export { api, communicator, dau, fintoc, frac, referrals, slackbot, metaConversion }
