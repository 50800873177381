import React, { useState } from 'react'
import { renderSection } from './controller'
import { Button } from '@nextui-org/react'
import { api } from 'api/index'
import { defaultTours } from 'constants/Onboarding'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

const Preferences = ({ user, userCustomerId, fetchProfile }) => {
  const [loading, setLoading] = useState(false)

  const array = [
    {
      title: 'Área de interés',
      value: user.interest_area ?? 'Sin especificar',
      icon: 'ph:graph-duotone',
    },
  ]

  const resetTutorials = async () => {
    setLoading(true)
    try {
      await api.updateUser(userCustomerId, { onboarding: { tours: defaultTours } })
      toast.success('¡Se han reiniciado todos los tutoriales!')
      setLoading(false)
      window.scrollTo(0, 0)
      return fetchProfile()
    } catch (error) {
      setLoading(false)
      toast.error('Hubo un problema al actualizar los datos, intente de nuevo más tarde')
      return console.error(error)
    }
  }

  return (
    <div className="flex flex-col space-y-2">
      {array.map((item) => renderSection(item.title, item.value, item.icon))}
      <Button
        color="primary"
        className="!mt-6 w-fit rounded-3xl"
        isLoading={loading}
        onClick={() => resetTutorials()}
      >
        Reiniciar Tutoriales
      </Button>
    </div>
  )
}

Preferences.propTypes = {
  user: PropTypes.object.isRequired,
  userCustomerId: PropTypes.string.isRequired,
  fetchProfile: PropTypes.func.isRequired,
}

export default Preferences
