import React from 'react'
import { Button } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'

const ReferralHeader = ({ setEditSection, setEditMode }) => {
  return (
    <div className="flex flex-col p-3 space-y-3">
      <p className="text-sm text-secondary dark:text-foreground">
        ¡Invita a tus amigos y colegas a unirse a <b>Propital</b> y obtén{' '}
        <b>beneficios exclusivos</b>!
      </p>
      <Button
        color="primary"
        variant="shadow"
        className="ml-auto md:mx-auto md:px-9 rounded-3xl"
        size="sm"
        endContent={<Icon icon="line-md:person-add" className="w-[20px] h-[20px]" />}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setEditSection('referrals')
          setEditMode(true)
        }}
      >
        Mis referidos
      </Button>
    </div>
  )
}

ReferralHeader.propTypes = {
  setEditSection: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
}

export default ReferralHeader
