import React from 'react'
import { Spinner } from '@nextui-org/react'
import PropTypes from 'prop-types'

const Loader = ({ isAppLoader, color = 'primary' }) => {
  return (
    <div
      className={`flex items-center justify-center animate-fade ${
        isAppLoader ? 'h-[100vh]' : 'h-full'
      }`}
    >
      <Spinner color={color} size="lg" className="scale-[200%]" />
    </div>
  )
}

Loader.propTypes = {
  isAppLoader: PropTypes.bool,
  color: PropTypes.string,
}

export default Loader
