import React, { useState } from 'react'
import { Button } from '@nextui-org/react'
import ModalRisk from './ModalRisk'
import PropTypes from 'prop-types'
import { calculateRisk } from './controller'
import { defaultRiskProfile, riskResults } from './constants'

const Risk = ({ user, fetchProfile }) => {
  const [modalRisk, setModalRisk] = useState(false)
  const [riskProfile, setRiskProfile] = useState(defaultRiskProfile)

  const validations = ['Conservador', 'Moderado', 'Agresivo']
  const enableTest = !validations.includes(user?.risk_profile)

  const riskLevel = calculateRisk(riskProfile)
  const currentRisk = riskResults.find((risk) => risk.perfil === user?.risk_profile)

  return (
    <div>
      {modalRisk && (
        <ModalRisk
          visible={modalRisk}
          setVisible={setModalRisk}
          riskProfile={riskProfile}
          setRiskProfile={setRiskProfile}
          riskLevel={riskLevel}
          fetchProfile={fetchProfile}
        />
      )}
      <div className="p-2 px-4 mb-2 border-2 border-primary rounded-3xl bg-primary/10">
        <p className="font-semibold text-primary">
          {enableTest ? 'Sin evaluar' : currentRisk?.perfil}
        </p>
        <span className="text-secondary dark:text-foreground">
          {enableTest
            ? 'Te recomendamos que realices nuestra evaluación para conocer tu tipo de perfil! 🚀'
            : currentRisk?.descripcion}
        </span>
      </div>
      <Button
        color="primary"
        className="w-fit rounded-3xl"
        isDisabled={false}
        onClick={() => setModalRisk(true)}
      >
        {enableTest ? 'Realizar test' : 'Realizar de nuevo'}
      </Button>
    </div>
  )
}

Risk.propTypes = {
  user: PropTypes.object,
  fetchProfile: PropTypes.func,
}

export default Risk
