import React, { Fragment, useState } from 'react'
import { Modal, ModalContent, ModalBody, ModalFooter, Button, Progress } from '@nextui-org/react'
import { Utils } from 'helpers'
import { detailStyles } from './styles'
import { Icon } from '@iconify/react'
import { API_FINTOC, FINTOC_PUBLIC_KEY } from 'api/config'
import { apiFintoc } from 'api/index'
import { getFintoc } from '@fintoc/fintoc-js'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

const ModalDetails = ({ visible, setVisible, currentItem, userCustomer }) => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const {
    uuid: unit_uuid,
    avatar,
    assetPrice,
    assetType,
    currencyAsset,
    nameProject,
    address,
    description,
    deliveryType,
    bonoPie,
    capRate,
    surface,
    typology,
    bricksNumber,
    brickPrice,
    rentalPrice,
    annualCapitalGain,
  } = currentItem

  const handlePayment = async () => {
    setLoading(true)
    try {
      const { data } = await apiFintoc.generatePayment({
        unit_uuid,
        transaction_origin: 'Customer Web',
        customer_email: userCustomer.email,
      })
      setVisible(false)
      const widgetToken = data.data.widgetToken
      // Ejecutar el widget después de obtener el widget_token
      const Fintoc = await getFintoc()
      const options = {
        holderType: 'individual',
        product: 'payments',
        publicKey: FINTOC_PUBLIC_KEY,
        country: 'cl',
        webhookUrl: `${API_FINTOC}/webhook`,
        widgetToken,
        onSuccess: async () => {
          return navigate('/muchas-gracias')
        },
        onExit: () => {
          setLoading(false)
        },
        onEvent: () => {},
      }
      const widget = Fintoc.create(options)
      return widget.open()
    } catch (error) {
      console.error(error)
      setLoading(false)
      return toast.error('Ha ocurrido un error al intentar pagar')
    }
  }

  const renderSection = (title, value) => {
    return (
      <div className={detailStyles.SECTION}>
        <p className={detailStyles.TITLE}>{title}</p>
        <span className={detailStyles.TEXT}>{value ?? 'S/I'}</span>
      </div>
    )
  }

  const formatted = {
    capRate: Number(capRate.replaceAll('%', '').replaceAll(',', '.')),
    annualCapitalGain: Number(annualCapitalGain.replaceAll('%', '').replaceAll(',', '.')),
  }

  const bars = [
    {
      label: 'Cap Rate',
      value: formatted.capRate,
      valueLabel: capRate,
      maxValue: 10,
    },
    {
      label: 'Cash Flow',
      value: rentalPrice,
      valueLabel: `CLP $${Utils.formatRoundPrice(rentalPrice)}`,
      maxValue: 500000,
    },
    {
      label: 'Ganancias de capital',
      value: formatted.annualCapitalGain,
      valueLabel: annualCapitalGain,
      maxValue: 10,
    },
  ]

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {() => (
          <Fragment>
            <ModalBody className="pt-6">
              <div className={detailStyles.CONTENT}>
                <div className={detailStyles.HEADER}>
                  <img src={avatar} className={detailStyles.IMAGE} alt="asset" />
                  <div className={detailStyles.SECTION}>
                    <p className="text-lg font-semibold text-primary dark:text-foreground">
                      {nameProject}
                    </p>
                    <span className="text-lg !font-extrabold text-secondary">
                      {currencyAsset} {currencyAsset !== 'UF' && '$'}
                      {Utils.formatRoundUF(assetPrice)}
                    </span>
                  </div>
                </div>
                {assetType !== 'Fraccionario' && (
                  <Fragment>
                    {bars.map((bar, index) => (
                      <Progress
                        key={index}
                        size="lg"
                        showValueLabel={true}
                        label={
                          <span className="text-base font-semibold text-primary">{bar.label}</span>
                        }
                        valueLabel={
                          <span className="text-base font-semibold">{bar.valueLabel}</span>
                        }
                        value={bar.value}
                        maxValue={bar.maxValue}
                        classNames={{
                          track: '-mt-2',
                        }}
                      />
                    ))}
                  </Fragment>
                )}
                {description && renderSection('Descripción', description)}
                {renderSection('Dirección', address)}
                {assetType !== 'Fraccionario' && (
                  <Fragment>
                    <div className="flex space-x-3">
                      {renderSection('Tipo de entrega', deliveryType)}
                      {renderSection('Bono pie', bonoPie)}
                    </div>
                    <div className="flex space-x-3">
                      {renderSection('Tipologías', typology)}
                      {renderSection('Superficie', surface)}
                    </div>
                  </Fragment>
                )}
                {assetType === 'Fraccionario' && (
                  <div className="flex space-x-3">
                    {renderSection('Bricks totales', bricksNumber)}
                    <div className={detailStyles.SECTION}>
                      <p className={detailStyles.TITLE}>Precio por unidad</p>
                      <span className={detailStyles.TEXT}>
                        CLP ${Utils.formatRoundPrice(brickPrice) ?? 'S/I'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="pt-0 mt-3">
              <Button
                onClick={() => setVisible(false)}
                color="primary"
                variant="light"
                className="rounded-3xl"
              >
                Cerrar
              </Button>
              <Button
                onClick={() => handlePayment()}
                color="primary"
                variant="shadow"
                className="font-semibold rounded-3xl"
                isLoading={loading}
                startContent={
                  <Icon
                    className="min-h-[24px] min-w-[24px] animate-jump-in animate-duration-1000"
                    icon="iconamoon:shield-yes-light"
                  />
                }
              >
                PRE-RESERVAR
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
  userCustomer: PropTypes.object,
}

export default ModalDetails
