export const styles = {
  MAIN_CONTAINER: 'flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto',
  CONTENT: 'flex flex-col py-3 space-y-3 md:max-w-xl md:mx-auto',
  STEPPER: 'flex justify-center space-x-6',
  STEP_CONTAINER: 'flex flex-col space-y-3',
  STEP: 'flex items-center justify-center w-8 h-8 rounded-full',
  TITLE: 'py-1 text-xl font-semibold text-center text-primary',
  FORM_CONTAINER: 'grid grid-cols-2 gap-2 md:gap-4',
  ACTIONS: 'flex mx-auto space-x-3 transition-all md:w-fit pt-3',
  BUTTON: 'w-full rounded-3xl',
}
