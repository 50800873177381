import React, { Fragment, useEffect } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
} from '@nextui-org/react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { detailStyles } from './styles'
import { UI } from 'components/index'
import dayjs from 'dayjs'

const ModalDetails = ({ visible, setVisible, currentItem }) => {
  const { avatar, financialData, location, nameProject, description, typology } = currentItem

  let tabs = [
    {
      id: 'financialData',
      label: 'Información',
      content: (
        <div className={detailStyles.CONTENT}>
          <div className={detailStyles.HEADER}>
            <img src={avatar} className={detailStyles.IMAGE} alt="asset" />
            <div className={detailStyles.SECTION}>
              <p className={`${detailStyles.TITLE} !text-lg`}>{financialData.assetType}</p>
              <span className={`${detailStyles.TEXT} !text-lg font-semibold`}>
                {financialData.currencyAsset} ${Utils.formatRoundUF(financialData.assetPrice)}
              </span>
            </div>
          </div>
          {typology && (
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>Tipologías</p>
              <div className="flex space-x-1">
                {typology?.split('|')?.map((item, index) => (
                  <div key={index} className="px-2 mt-1 text-sm text-white rounded-3xl bg-primary">
                    {item}
                  </div>
                )) ?? 'S/I'}
              </div>
            </div>
          )}
          {nameProject && (
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>Nombre del activo</p>
              <span className={detailStyles.TEXT}>{nameProject ?? 'S/I'}</span>
            </div>
          )}
          {description && (
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>Descripción</p>
              <span className={detailStyles.TEXT}>{description ?? 'S/I'}</span>
            </div>
          )}
          {financialData.assetType === 'Fondos mutuos' && (
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>Tipo de fondo al que pertenece</p>
              <span className={detailStyles.TEXT}>{financialData.typeMutualFund ?? 'S/I'}</span>
            </div>
          )}
          {financialData.assetType === 'Acciones' && (
            <Fragment>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Cantidad de acciones</p>
                <span className={detailStyles.TEXT}>{financialData.sharesNumber ?? 'S/I'}</span>
              </div>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Precio de la acción</p>
                <span className={detailStyles.TEXT}>
                  {financialData.sharePrice
                    ? ` $${Utils.formatRoundUF(financialData.sharePrice)}`
                    : 'S/I'}
                </span>
              </div>
            </Fragment>
          )}
          {financialData.assetType === 'Fraccionario' && (
            <Fragment>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Cantidad de bricks</p>
                <span className={detailStyles.TEXT}>{financialData.bricksNumber ?? 'S/I'}</span>
              </div>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Precio del brick</p>
                <span className={detailStyles.TEXT}>
                  {financialData.brickPrice
                    ? ` $${Utils.formatRoundUF(financialData.brickPrice)}`
                    : 'S/I'}
                </span>
              </div>
            </Fragment>
          )}
          {financialData.assetType === 'Depósito a plazo' && (
            <Fragment>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Nombre del banco</p>
                <span className={detailStyles.TEXT}>{financialData.bank ?? 'S/I'}</span>
              </div>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Tipo de depósito</p>
                <span className={detailStyles.TEXT}>{financialData.depositType ?? 'S/I'}</span>
              </div>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Fecha de inicio</p>
                <span className={detailStyles.TEXT}>
                  {dayjs(financialData.startDate).format('DD/MM/YYYY') ?? 'S/I'}
                </span>
              </div>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Fecha de finalización</p>
                <span className={detailStyles.TEXT}>
                  {dayjs(financialData.finishDate).format('DD/MM/YYYY') ?? 'S/I'}
                </span>
              </div>
            </Fragment>
          )}
          {financialData.assetType === 'Vehiculo' && (
            <Fragment>
              <div className={detailStyles.SECTION}>
                <p className={detailStyles.TITLE}>Modelo de la unidad</p>
                <span className={detailStyles.TEXT}>{financialData.age ?? 'S/I'}</span>
              </div>
            </Fragment>
          )}
          {financialData.assetSubType &&
            financialData.assetSubType !== 'Parcela' &&
            financialData.assetSubType !== 'Terreno' && (
              <Fragment>
                <div className={detailStyles.SECTION}>
                  <p className={detailStyles.TITLE}>Canon de arriendo</p>
                  <span className={detailStyles.TEXT}>
                    {financialData.currencyAsset}
                    {financialData.rentalPrice
                      ? ` $${Utils.formatRoundUF(financialData.rentalPrice)}`
                      : 'S/I'}
                  </span>
                </div>
                <div className={detailStyles.SECTION}>
                  <p className={detailStyles.TITLE}>Vacancia anual estimada</p>
                  <span className={detailStyles.TEXT}>{financialData.annualVacancy ?? 'S/I'}</span>
                </div>
                <div className={detailStyles.SECTION}>
                  <p className={detailStyles.TITLE}>Impuesto territorial anual</p>
                  <span className={detailStyles.TEXT}>
                    {financialData.annualPropTax ? `${financialData.annualPropTax}%` : 'S/I'}
                  </span>
                </div>
              </Fragment>
            )}
        </div>
      ),
    },
    {
      id: 'location',
      label: 'Ubicación',
      content: location && (
        <div className={detailStyles.CONTENT}>
          <div className={detailStyles.SECTION}>
            <p className={detailStyles.TITLE}>Ubicación</p>
            <span className={detailStyles.TEXT}>{location.address ?? 'S/I'}</span>
          </div>
          <UI.Map
            height="200px"
            center={{ lat: location.latitude, lng: location.longitude }}
            markers={[{ lat: location.latitude, lng: location.longitude }]}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (!financialData.assetSubType) {
      tabs = tabs.filter((tab) => tab.id !== 'location')
    }
  }, [financialData])

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {() => (
          <Fragment>
            <ModalHeader className="pb-0 text-primary">Detalles del Activo</ModalHeader>
            <ModalBody>
              <div className="flex flex-col w-full">
                <Tabs color="primary" variant="solid" items={tabs} aria-label="movements">
                  {(item) => (
                    <Tab key={item.id} title={item.label} className="animate-fade">
                      {item.content}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button onClick={() => setVisible(false)} color="primary">
                Cerrar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
}

export default ModalDetails
