import React from 'react'
import PropTypes from 'prop-types'

const Header = ({
  title,
  renderTitle,
  subtitle,
  containerClassName,
  titleClassName,
  subtitleClassName,
  rightContent,
}) => {
  return (
    <div
      className={
        containerClassName ??
        'flex items-center w-full p-4 rounded-2xl shadow-propital dark:shadow-none bg-background'
      }
    >
      <div className="flex flex-col w-full">
        {renderTitle ? (
          renderTitle
        ) : (
          <h2 className={titleClassName ?? 'text-xl font-semibold text-primary'}>{title}</h2>
        )}
        <p className={subtitleClassName ?? 'text-secondary font-semibold dark:text-foreground'}>
          {subtitle}
        </p>
      </div>
      {rightContent && rightContent}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.any,
  renderTitle: PropTypes.any,
  subtitle: PropTypes.any,
  containerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  rightContent: PropTypes.node,
}

export default Header
