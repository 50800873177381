/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import { Accordion, AccordionItem, Button, Image } from '@nextui-org/react'
import { motion, useInView } from 'framer-motion'
import { Icon } from '@iconify/react'
import { App } from 'components/index'
import Logo from 'assets/images/app/logo-desktop.png'
import Income1 from 'assets/images/app/income/income1.jpg'
import Income2 from 'assets/images/app/income/income2.jpg'
import Income3 from 'assets/images/app/income/income3.png'

const Income = () => {
  function Section({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return <section ref={ref}>{isInView && <div>{children}</div>}</section>
  }
  const [currentSection, setCurrentSection] = useState(0)
  const sections = [
    'income-section-1',
    'income-section-2',
    'income-section-3',
    'income-section-4',
    'income-section-5',
  ]
  const topSection = {
    hidden: { y: '10vh', opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.66 } },
  }
  const leftSection = {
    hidden: { x: '-10vw', opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 1.66 } },
  }
  const rightSection = {
    hidden: { x: '10vw', opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 1.66 } },
  }
  const floatTransition = {
    y: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 6,
      ease: 'easeInOut',
    },
    x: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 6,
      ease: 'easeInOut',
    },
  }

  // const goNextSection = () => {
  //   const nextSection = (currentSection + 1) % sections.length
  //   setCurrentSection(nextSection)
  //   const section = document.getElementById(sections[nextSection])
  //   section.scrollIntoView({ behavior: 'smooth' })
  // }

  // const NavigateArrow = () => {
  //   return (
  //     <motion.div
  //       className="absolute bottom-0 mb-3 transform -translate-x-1/2 -translate-y-1/2 left-1/2"
  //       animate={{
  //         y: ['0%', '-20%', '0%'],
  //       }}
  //       transition={{
  //         duration: 3,
  //         repeat: Infinity,
  //         repeatType: 'loop',
  //         ease: 'easeInOut',
  //       }}
  //     >
  //       <Icon
  //         icon="ep:arrow-down-bold"
  //         className="text-3xl cursor-pointer text-primary/50"
  //         onClick={goNextSection}
  //       />
  //     </motion.div>
  //   )
  // }

  const benefits = [
    {
      icon: 'material-symbols:watch-check-rounded',
      title: 'Ahorras tiempo en trámites',
      description: 'al facilitar la recopilación de tus datos.',
    },
    {
      icon: 'hugeicons:money-bag-02',
      title: 'Conoces tu poder de compra',
      description: 'obteniendo el perfil de como te ven los bancos.',
    },
    {
      icon: 'majesticons:creditcard-hand-line',
      title: 'Mejoras tus probabilidades',
      description: 'de acceso a créditos y a otros benficios.',
    },
  ]

  return (
    <div>
      {/* Sección 1 */}
      <div id="income-section-1" className="relative flex flex-col">
        <div className="flex justify-center">
          <a href="/">
            <img src={Logo} alt="Propital" className="w-auto h-6 mt-6 animate-fade-up" />
          </a>
        </div>
        <div className="flex flex-col justify-center w-full h-screen px-6 md:items-center md:flex-row md:justify-evenly">
          <Section>
            <motion.div initial="hidden" animate="visible" variants={leftSection}>
              <div className="flex flex-col justify-center h-full space-y-3 text-secondary">
                <b className="text-xl text-primary">Propital Income</b>
                <b className="text-5xl">Conoce tu poder</b>
                <b className="text-5xl">de compra</b>
                <br />
                <b className="text-2xl text-primary">De forma rápida,</b>
                <b className="text-2xl text-primary">segura y 100% online</b>
                <br />
                <div className="mr-auto w-fit">
                  <datamart-button
                    connectid="e2fa73dd-bcd2-4126-a321-dc608b378d31"
                    // validatesubscription="true"
                    // subscriberid={RUT}
                    // authorizername={name}
                    // authorizeremail={profile.email}
                    // authorizermobile={profile.phone}
                    uibuttontext="Sincronizar Income"
                    uibuttonshowtagline="false"
                    uibuttonshowexplanation="false"
                    uibuttonbgbox="transparent"
                    uibuttonshape="round"
                    uibuttoncolor="#0084FE"
                  ></datamart-button>
                </div>
              </div>
            </motion.div>
          </Section>
          <Section>
            <motion.div initial="hidden" animate="visible" variants={rightSection}>
              <div className="relative p-[8vh] hidden md:block">
                <motion.div
                  className="absolute top-0 right-0 h-[40vh] w-[40vh] bg-secondary rounded-full"
                  transition={floatTransition}
                  animate={{
                    x: ['-5px', '5px'],
                    y: ['-5px', '5px'],
                  }}
                ></motion.div>
                <Image
                  src={Income1}
                  isBlurred
                  alt="Income"
                  className="h-[50vh] w-[60vh] shadow-propital rounded-[35px] object-cover"
                />
                <motion.div
                  className="absolute bottom-0 left-0 h-[24vh] w-[24vh] bg-neutral-300 rounded-full"
                  transition={floatTransition}
                  animate={{
                    x: ['-5px', '5px'],
                    y: ['-5px', '5px'],
                  }}
                ></motion.div>
              </div>
            </motion.div>
          </Section>
        </div>
        {/* <NavigateArrow /> */}
      </div>
      {/* Sección 2 */}
      <div
        id="income-section-2"
        className="relative flex flex-col items-center h-screen justify-evenly"
      >
        <Section>
          <motion.div initial="hidden" animate="visible" variants={topSection}>
            <div className="md:w-[70vw] py-12 mx-4 px-4 md:mx-0 text-center text-white bg-secondary rounded-3xl shadow-propital">
              <b className="text-lg md:text-2xl">Ahora podrás agilizar tu evaluación crediticia</b>
              <p>sincronizando tu perfil financiero con tu clave única</p>
            </div>
          </motion.div>
        </Section>
        <Section>
          <div className="flex flex-wrap justify-center md:space-x-9 mb-9">
            {benefits.map((item, index) => (
              <motion.div
                key={index}
                initial={{ visibility: 0, y: -200 }}
                animate={{ visibility: 1, y: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 160,
                  damping: 8,
                  repeatType: 'none',
                }}
                className="flex flex-col items-center m-3 space-y-3 text-center w-52 text-secondary md:m-0"
              >
                <Icon
                  icon={item.icon}
                  className="p-2 text-6xl text-white rounded-full bg-primary shadow-propital"
                />
                <b className="text-xl">{item.title}</b>
                <p>{item.description}</p>
              </motion.div>
            ))}
          </div>
        </Section>
        {/* <NavigateArrow /> */}
      </div>
      {/* Sección 3 */}
      <div
        id="income-section-3"
        className="relative flex flex-col justify-center w-full h-screen px-6 md:items-center md:flex-row md:justify-evenly"
      >
        <Section>
          <motion.div initial="hidden" animate="visible" variants={rightSection}>
            <div className="relative p-[8vh] hidden md:block">
              <motion.div
                className="absolute top-0 left-0 h-[40vh] w-[40vh] bg-secondary rounded-full"
                transition={floatTransition}
                animate={{
                  x: ['-5px', '5px'],
                  y: ['-5px', '5px'],
                }}
              ></motion.div>
              <Image
                src={Income2}
                isBlurred
                alt="Income"
                className="h-[50vh] w-[60vh] shadow-propital rounded-[35px] object-cover"
              />
              <motion.div
                className="absolute bottom-0 right-0 h-[24vh] w-[24vh] bg-neutral-300 rounded-full"
                transition={floatTransition}
                animate={{
                  x: ['-5px', '5px'],
                  y: ['-5px', '5px'],
                }}
              ></motion.div>
            </div>
          </motion.div>
        </Section>
        <Section>
          <motion.div initial="hidden" animate="visible" variants={leftSection}>
            <div className="flex flex-col justify-center h-full space-y-3 text-secondary">
              <b className="text-5xl">Todo será más</b>
              <b className="text-5xl">cómodo y rápido</b>
              <br />
              <p className="w-96">
                Al compartir tus antecedentes financieros adelantas el proceso de evaluación
                financiera y crediticia simplificando el proceso y ahorrando tiempo.
              </p>
            </div>
          </motion.div>
        </Section>
        {/* <NavigateArrow /> */}
      </div>
      {/* Sección 4 */}
      <div id="income-section-4" className="relative flex flex-col">
        <Section>
          <motion.div initial="hidden" animate="visible" variants={topSection}>
            <div className="text-center mt-14 text-secondary">
              <b className="text-3xl">Sincroniza tus datos financieros</b>
              <p className="text-xl">con tu clave única y comienza a disfrutar de los beneficios</p>
              <div className="mt-14">
                <div className="mx-auto w-fit">
                  <datamart-button
                    connectid="e2fa73dd-bcd2-4126-a321-dc608b378d31"
                    // validatesubscription="true"
                    // subscriberid={RUT}
                    // authorizername={name}
                    // authorizeremail={profile.email}
                    // authorizermobile={profile.phone}
                    uibuttontext="Sincronizar Income"
                    uibuttonshowtagline="false"
                    uibuttonshowexplanation="false"
                    uibuttonbgbox="transparent"
                    uibuttonshape="round"
                    uibuttoncolor="#0084FE"
                  ></datamart-button>
                </div>
              </div>
            </div>
            <img src={Income3} alt="Income" className="object-cover w-full h-full -mt-[10vh]" />
          </motion.div>
        </Section>
        {/* <NavigateArrow /> */}
      </div>
      {/* Sección 5 */}
      <div id="income-section-5" className="relative flex flex-col items-center min-h-screen py-12">
        <div className="text-center text-secondary">
          <b className="text-3xl">Preguntas frecuentes</b>
        </div>
        <div className="w-[90vw] md:w-[80vw] my-9">
          <Accordion
            variant="splitted"
            itemClasses={{
              base: '!bg-inactive/20 !shadow-none my-3 !px-9 !py-3 !rounded-3xl text-secondary',
              title: 'text-secondary font-bold',
            }}
          >
            <AccordionItem key="1" title="¿Qué es Propital Income?">
              Propital Income es un servicio que te permite sincronizar tus datos financieros con
              Propital, gracias a nuestra alianza con Datamart, para ofrecerte una visión clara y
              detallada de tus inversiones y finanzas.
            </AccordionItem>
            <AccordionItem key="2" title="¿Cómo sincronizo mis datos con Propital Income?">
              Simplemente presiona el botón de “Sincronizar” y sigue las instrucciones, vas a
              necesitar proporcionar tu clave única.
            </AccordionItem>
            <AccordionItem key="3" title="¿Es seguro sincronizar mis datos financieros?">
              Sí, garantizamos la seguridad de tus datos mediante avanzados protocolos de
              encriptación y protección. Tu información estará segura y solo será utilizada para
              ofrecerte el mejor análisis financiero.
            </AccordionItem>
            <AccordionItem key="4" title="¿Qué es la Clave Única?">
              La Clave Única es una herramienta esencial que permite a las personas ahorrar tiempo y
              dinero al evitar visitas a oficinas públicas para obtener certificados importantes.
              Con la Clave Única, puedes gestionar trámites financieros en línea y la obtención de
              documentos relevantes para solicitudes de préstamos y financiamiento.
            </AccordionItem>
          </Accordion>
          {/* <NavigateArrow /> */}
        </div>
      </div>
      {/* Footer */}
      <App.Footer />
    </div>
  )
}

export default Income
