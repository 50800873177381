import React, { Fragment } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
  Checkbox,
} from '@nextui-org/react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { detailStyles } from './styles'

const ModalDetails = ({ visible, setVisible, currentItem }) => {
  const { avatar, basicInfo, paymentInfo } = currentItem

  let tabs = [
    {
      id: 'info',
      label: 'Información',
      content: (
        <div className={detailStyles.CONTENT}>
          <div className={detailStyles.HEADER}>
            <img src={avatar} className={detailStyles.IMAGE} alt="asset" />
            <div className={detailStyles.SECTION}>
              <p className={detailStyles.TITLE}>{basicInfo.passiveType}</p>
              <span className={detailStyles.TEXT}>
                {basicInfo.currencyPassive} ${Utils.formatRoundUF(basicInfo.passivePrice)}
              </span>
            </div>
          </div>
          <div className={detailStyles.SECTION}>
            <p className={detailStyles.TITLE}>Valor cuota</p>
            <span className={detailStyles.TEXT}>
              {paymentInfo.quotaCurrency}
              {paymentInfo.quotaValue ? ` $${Utils.formatRoundUF(paymentInfo.quotaValue)}` : 'S/I'}
            </span>
          </div>
          <div className={detailStyles.SECTION}>
            <p className={detailStyles.TITLE}>Número de cuotas</p>
            <span className={detailStyles.TEXT}>{paymentInfo.quotaPeriod ?? 'S/I'}</span>
          </div>
        </div>
      ),
    },
    {
      id: 'details',
      label: 'Detalles',
      content: (
        <div className={detailStyles.CONTENT}>
          <div className={detailStyles.SECTION}>
            <p className={detailStyles.TITLE}>Interés anual</p>
            <span className={detailStyles.TEXT}>
              {`${paymentInfo.annualInterestRate} %` ?? 'S/I'}
            </span>
          </div>
          <div className={`${detailStyles.SECTION} space-y-0 pointer-events-none`}>
            <p className={detailStyles.TITLE}>Beneficios</p>
            <Checkbox defaultSelected={basicInfo.includesQuota.amortizacion}>
              <p className={`${detailStyles.TEXT} text-foreground dark:text-white`}>Amortización</p>
            </Checkbox>
            <Checkbox defaultSelected={basicInfo.includesQuota.seguro}>
              <p className={`${detailStyles.TEXT} text-foreground dark:text-white`}>Seguros</p>
            </Checkbox>
            <Checkbox defaultSelected={basicInfo.includesQuota.intereses}>
              <p className={`${detailStyles.TEXT} text-foreground dark:text-white`}>Intereses</p>
            </Checkbox>
          </div>
        </div>
      ),
    },
  ]

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {() => (
          <Fragment>
            <ModalHeader className="pb-0 text-primary">Detalles del Pasivo</ModalHeader>
            <ModalBody>
              <div className="flex flex-col w-full">
                <Tabs color="primary" variant="solid" items={tabs} aria-label="movements">
                  {(item) => (
                    <Tab key={item.id} title={item.label} className="animate-fade">
                      {item.content}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button onClick={() => setVisible(false)} color="primary">
                Cerrar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
}

export default ModalDetails
