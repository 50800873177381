import React from 'react'
import { renderSection } from './controller'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'

const Information = ({ user }) => {
  dayjs.extend(utc)
  const array = [
    {
      title: 'Nombre y apellido',
      value: `${user.first_name} ${user.last_name}`,
      icon: 'carbon:user-profile',
    },
    {
      title: 'Email',
      value: user.email,
      icon: 'carbon:email',
    },
    {
      title: 'Teléfono',
      value: user.phone,
      icon: 'carbon:phone',
    },
    {
      title: 'RUT',
      value: user.identity_document,
      icon: 'carbon:identification',
    },
    {
      title: 'Fecha de nacimiento',
      value: user?.birth_date
        ? `${dayjs(user.birth_date).utc().format('DD/MM/YYYY')}`
        : 'Sin especificar',
      icon: 'carbon:calendar',
    },
    {
      title: 'Estado civil',
      value: user.marital_status,
      icon: 'carbon:user-multiple',
    },
    {
      title: 'Profesión',
      value: user.profession,
      icon: 'carbon:portfolio',
    },
    {
      title: 'Dirección',
      value: user.address,
      icon: 'carbon:location',
    },
    {
      title: 'Ciudad',
      value: user.city,
      icon: 'solar:city-linear',
    },
    {
      title: 'País',
      value: user.country,
      icon: 'solar:earth-outline',
    },
  ]
  return (
    <div className="flex flex-col space-y-2">
      {array.map((item) => renderSection(item.title, item.value, item.icon))}
    </div>
  )
}

Information.propTypes = {
  user: PropTypes.object.isRequired,
}

export default Information
