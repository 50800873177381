import { useEffect, useState } from 'react'
import { Auth, Utils } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'

const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const [session] = useState(JSON.parse(localStorage.getItem('session')))

  const user = useSelector((state) => state.user)
  const cognitoUser = useSelector((state) => state.cognitoUser)
  const dispatch = useDispatch()

  const path = window.location.hash

  const authRoutes = ['login', 'register', 'confirm', 'recovery', 'recoverySubmit', 'newPassword']
  const publicRoutes = ['legal', 'income']

  const excludeRoutes = [...authRoutes, ...publicRoutes]

  const validateSession = () => {
    if (excludeRoutes.some((item) => path.includes(item))) return
    const isExpired = session && new Date(session.expirationDate) <= new Date()
    if (!user) {
      dispatch({ type: 'set', user: session })
    }
    if (!session || isExpired) {
      localStorage.clear()
      dispatch({ type: 'set', user: null })
      signOut()
      return Utils.redirect('/#/login')
    }
  }

  useEffect(() => {
    validateSession()
  }, [session])

  const signIn = (email, password) => Auth.signIn(email, password, setLoading, dispatch)

  const signUp = (user) => Auth.signUp(user, setLoading)

  const confirmSignUp = (email, code) => Auth.confirmSignUp(email, code, setLoading)

  const signOut = () => Auth.signOut(dispatch, setLoading)

  const forgotPassword = (user) => Auth.forgotPassword(user, setLoading)

  const changeBothPasswords = (user) => Auth.changeBothPasswords(user, setLoading)

  const completeNewPassword = (user) => Auth.completeNewPassword(user, setLoading, cognitoUser)

  return {
    user,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    forgotPassword,
    changeBothPasswords,
    completeNewPassword,
    loading,
  }
}

export default useAuth
