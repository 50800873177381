import React from 'react'
import { NavbarBrand, NavbarContent, NavbarMenuToggle } from '@nextui-org/react'
import LogoDesktop from 'assets/images/app/logo-desktop.png'
import LogoDesktopDark from 'assets/images/app/logo-desktop-dark.png'
import { useTheme } from 'next-themes'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const Brand = ({ isOpen }) => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  return (
    <NavbarContent className="md:!mx-auto md:mt-6 md:mb-2">
      <NavbarMenuToggle
        icon={
          <Icon
            icon={isOpen ? 'line-md:menu-to-close-alt-transition' : 'line-md:menu'}
            className="min-w-[24px] min-h-[24px]"
          />
        }
        className="sm:hidden"
      />
      <NavbarBrand>
        <img
          src={theme === 'dark' ? LogoDesktopDark : LogoDesktop}
          alt="Logo"
          className="md:hidden h-[24px] w-max cursor-pointer"
          onClick={() => navigate('/')}
        />
        <img
          src={theme === 'dark' ? LogoDesktopDark : LogoDesktop}
          alt="Logo"
          className="hidden md:block h-[24px] cursor-pointer"
          onClick={() => navigate('/')}
        />
      </NavbarBrand>
    </NavbarContent>
  )
}

Brand.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default Brand
