export const styles = {
  MAIN_CONTAINER: 'flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto',
  CONTENT_CONTAINER: 'grid grid-cols-12 gap-3 md:min-h-[400px]',
  CONTENT: 'relative flex flex-col p-4',
  TOTAL_CONTAINER:
    'absolute text-center -translate-x-1/2 -translate-y-1/2 text-secondary dark:text-foreground top-1/2 left-1/2 flex flex-col space-y-3',
  TOTAL_TEXT: 'text-xl font-semibold',
  LIST: 'col-span-12 md:col-span-6 w-full',
  LIST_TITLE: 'mb-2 ml-2 text-xl',
  TABS_CONTAINER: 'relative flex flex-col w-full',
  TABS_ACTIONS: 'absolute top-0 right-0 flex mt-1 space-x-1',
  ICON: 'min-w-[24px] min-h-[24px]',
  BUTTON_TAB: 'absolute top-0 right-0 h-[32px] mt-1 mr-1 animate-fade-right animate-duration-500',
  LAST_UPDATE: 'absolute bottom-0 right-0 mb-1 mr-2 text-xs text-neutral-300 dark:text-neutral-700',
}
