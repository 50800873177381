import { referrals } from 'api/config'

const getCode = (params) => {
  return referrals.get(`/referrals/code`, { params })
}

const getReferrals = (code) => {
  return referrals.get(`/referrals/${code}`)
}

const createReferral = (payload, code) => {
  return referrals.post(`/referrals/${code}`, payload)
}

const confirmReferral = (payload) => {
  return referrals.put(`/referrals/confirm`, payload)
}

export { getCode, getReferrals, createReferral, confirmReferral }
