import * as yup from 'yup'

// Activos por defecto para los mensajes de error en los inputs
const generateSchema = (additionalFields) => {
  return yup.object().shape({
    assetPrice: yup.string().required('Este campo es obligatorio'),
    nameProject: yup
      .string()
      .min(3, 'El nombre es demasiado corto')
      .max(60, 'El nombre es demasiado largo')
      .required('Este campo es obligatorio'),
    ...additionalFields,
  })
}

// Esquema de validaciones según el tipo de activo, cada uno tiene sus propios campos

export const defaultSchema = yup.object().shape({
  assetType: yup.string().required('El tipo de activo es obligatorio'),
  currencyAsset: yup.string().required('La divisa es obligatoria'),
})

export const realEstateSchema = generateSchema({
  rentalPrice: yup.string().required('El precio del arriendo es obligatorio'),
})

export const fraccionarioSchema = generateSchema({
  bricksNumber: yup.string().required('La cantidad de bricks es obligatoria'),
  brickPrice: yup.string().required('El precio del brick es obligatorio'),
})

export const sharesSchema = generateSchema({
  sharesNumber: yup.string().required('La cantidad de acciones es obligatoria'),
  sharePrice: yup.string().required('El precio de la acción es obligatorio'),
})

export const mutualFundsSchema = generateSchema({
  typeMutualFund: yup
    .string()
    .min(3, 'El nombre es demasiado corto')
    .max(60, 'El nombre es demasiado largo')
    .required('El tipo de fondo es obligatorio'),
})

export const depositSchema = generateSchema({
  bank: yup
    .string()
    .min(3, 'El nombre es demasiado corto')
    .max(60, 'El nombre es demasiado largo')
    .required('El nombre del banco es obligatorio'),
  depositType: yup.string().required('Este campo es obligatorio'),
  startDate: yup.string().required('La fecha de inicio es obligatoria'),
  finishDate: yup.string().required('La fecha de finalización es obligatoria'),
})

export const vehicleSchema = generateSchema({
  age: yup.string().required('El modelo del vehículo es obligatorio'),
})

export const otherSchema = generateSchema()

// Se exporta un objeto ya resuelto para poder ser usado en el useForm según el tipo de activo

export const validationSchemas = {
  'Real Estate': realEstateSchema,
  Fraccionario: fraccionarioSchema,
  Acciones: sharesSchema,
  'Fondos mutuos': mutualFundsSchema,
  'Depósito a plazo': depositSchema,
  Vehiculo: vehicleSchema,
  Otros: otherSchema,
}
