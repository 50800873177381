import React, { useState } from 'react'
import { Accordion, AccordionItem, Skeleton } from '@nextui-org/react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'

const Sections = ({ sections, setEditSection, setEditMode, loading }) => {
  const [selectedKeys, setSelectedKeys] = useState(new Set(['0']))

  return (
    <Accordion
      className="px-0"
      selectionMode="multiple"
      selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}
    >
      {sections.map((section, index) => (
        <AccordionItem
          key={index}
          aria-label={section.title}
          classNames={{
            trigger: `${index === 0 && '!pt-0'}`,
          }}
          title={
            <div>
              <div className="flex items-center space-x-2">
                <div className="p-[6px] rounded-full bg-primary/20">
                  <Icon
                    icon={section.icon}
                    className={`w-4 h-4 text-primary dark:text-foreground`}
                  />
                </div>
                <p className="text-lg font-semibold text-primary dark:text-foreground">
                  {section.title}
                </p>
                {section.formKey && section.editAction && (
                  <Icon
                    icon="basil:edit-outline"
                    className={`w-5 h-5 -mb-[2px] cursor-pointer text-primary hover:shadow-propital ${
                      index === 1 && 'step-3'
                    }`}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                      setEditSection(section.formKey)
                      setEditMode(true)
                    }}
                  />
                )}
              </div>
              <span className="text-base font-semibold text-secondary dark:text-inactive">
                {section.description}
              </span>
            </div>
          }
        >
          <div className={`flex flex-col space-y-3 ${index === 1 && 'step-2'}`}>
            <Skeleton isLoaded={!loading} className={`${loading && 'rounded-2xl'}`}>
              {section.component}
            </Skeleton>
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
  setEditSection: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default Sections
