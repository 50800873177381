import React from 'react'
import { styles } from './styles'
import { Button, Card, CardBody, Chip } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { Utils } from 'helpers/index'
import { assetActions } from 'constants/assets'
import PropTypes from 'prop-types'

const AssetCard = ({ item, setCurrentItem, setModalDetails, setModalEdit, setModalDelete }) => {
  const handleName = () => {
    if (item.financialData.assetType === 'Depósito a plazo') return item.financialData.bank
    if (item.nameProject) return item.nameProject
    return ''
  }
  const actions = assetActions[item?.editableStatus]
  return (
    <Card
      className={styles.CARD}
      isPressable
      onPress={() => {
        setCurrentItem(item)
        setModalDetails(true)
      }}
    >
      <CardBody className="relative p-3">
        <div className="flex items-center min-h-[32px]">
          <Chip color="primary" size="sm" className={styles.CHIP}>
            {item.financialData.assetSubType ?? item.financialData.assetType}
          </Chip>
          {actions?.edit && (
            <Button
              onClick={() => {
                setCurrentItem(item)
                setModalEdit(true)
              }}
              isIconOnly
              color="primary"
              variant="light"
              size="sm"
              className="ml-auto rounded-full"
            >
              <Icon icon="akar-icons:edit" className="min-w-[24px] min-h-[24px]" />
            </Button>
          )}
          {actions?.delete && (
            <Button
              onClick={() => {
                setCurrentItem(item)
                setModalDelete(true)
              }}
              isIconOnly
              color="primary"
              variant="light"
              size="sm"
              className="rounded-full"
            >
              <Icon icon="ic:outline-delete" className="min-w-[24px] min-h-[24px]" />
            </Button>
          )}
        </div>
        <img className={styles.IMAGE} alt={item.title} src={item.avatar} />
        <div className="text-center">
          <p className={styles.NAME}>{handleName()}</p>
          <p className={styles.TEXT_CONTAINER}>
            <span className="mr-1">
              {item.financialData.currencyAsset === 'CLF' ? 'UF' : item.financialData.currencyAsset}
            </span>
            ${Utils.formatRoundUF(item.financialData.assetPrice)}
          </p>
        </div>
      </CardBody>
    </Card>
  )
}

AssetCard.propTypes = {
  item: PropTypes.object,
  setCurrentItem: PropTypes.func,
  setModalDetails: PropTypes.func,
  setModalEdit: PropTypes.func,
  setModalDelete: PropTypes.func,
}

export default AssetCard
