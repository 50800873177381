import React, { Fragment, useState } from 'react'
import Logo from 'assets/images/app/logo-desktop.png'
import { Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { App } from 'components/index'
import Gif from 'assets/icons/error/sad_parrot.gif'
import useAuth from 'hooks/useAuth'
import PropTypes from 'prop-types'

const Error = ({ code = '404' }) => {
  const { signOut } = useAuth()
  const navigate = useNavigate()
  const [modalReport, setModalReport] = useState(false)
  return (
    <Fragment>
      {modalReport && <App.ModalReport visible={modalReport} setVisible={setModalReport} />}
      <div
        className={`flex items-center justify-center h-screen ${
          modalReport ? 'hidden' : 'animate-fade'
        }`}
      >
        <div className="flex flex-col">
          <div className="flex flex-col items-center content-center">
            <img src={Logo} alt="Logo" className="w-28 h-fit" />
            <p className="text-[160px] font-bold text-secondary dark:text-primary">{code}</p>
          </div>
          <div className="flex justify-center p-3 py-6 space-x-1 text-center rounded-3xl bg-primary/10 text-secondary dark:text-white">
            <p>Oops, parece que algo ha salido mal.</p>
            <img src={Gif} alt="gif" className="object-contain w-6 h-6" />
          </div>
          <div className="flex space-x-3 mt-9">
            <Button
              color="primary"
              variant="shadow"
              className="w-full rounded-3xl"
              startContent={<Icon icon="line-md:backup-restore" className="w-[20px] h-[20px]" />}
              onClick={() => navigate('/')}
            >
              Volver a inicio
            </Button>
            <Button
              color="secondary"
              variant="shadow"
              className="w-full rounded-3xl"
              endContent={<Icon icon="line-md:uploading-loop" className="w-[20px] h-[20px]" />}
              onClick={() => setModalReport(true)}
            >
              Reportar error
            </Button>
          </div>
          <Button
            color="primary"
            variant="bordered"
            className="w-full mt-4 rounded-3xl"
            endContent={<Icon icon="line-md:logout" className="w-[20px] h-[20px]" />}
            onClick={signOut}
          >
            Reiniciar sesión
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

Error.propTypes = {
  code: PropTypes.string,
}

export default Error
