import React, { Fragment, useState } from 'react'
import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import { tours } from 'constants/Onboarding'
import { api } from 'api/index'
import PropTypes from 'prop-types'
import 'shepherd.js/dist/css/shepherd.css'
import './styles.css'

const tourOptions = {
  defaultStepOptions: {
    classes: 'animate-fade !shadow-xl',
    scrollTo: { behavior: 'smooth', block: 'center' },
    cancelIcon: {
      enabled: false,
    },
  },
  scrollTo: true,
  highlightClass: 'highlight',
  useModalOverlay: true,
}

const Tour = ({ view }) => {
  const dispatch = useDispatch()
  const [render, setRender] = useState(false)
  const [fetchTours, setFetchTours] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const userCustomerId = useSelector(
    (state) => state.user?.customer?.authenticated?.attributes?.sub || null,
  )

  const handleOnboarding = async () => {
    try {
      const { data } = await api.getUserInfo(userCustomerId)
      const user = data[0]
      const onboarding = user?.onboarding?.tours ? user.onboarding.tours : null
      setFetchTours(onboarding)
      setLoaded(true)
      return dispatch({ type: 'set', profile: user })
    } catch (error) {
      console.error(error)
    }
  }

  const TourInstance = () => {
    const tour = useContext(ShepherdTourContext)
    const handleLoad = () => {
      if (fetchTours) {
        if (fetchTours[view].completed) {
          return
        }
      }
      if (loaded && !render) {
        setRender(true)
        return tour.start()
      }
    }
    useEffect(() => {
      handleLoad()
    }, [loaded, render])
  }

  useEffect(() => {
    handleOnboarding()
  }, [])

  // Los tours estarán divididos por vistas (views)
  // Cada una de las vistas tendrá su propio tour
  return (
    <Fragment>
      {loaded && (
        <ShepherdTour steps={tours[view]} tourOptions={tourOptions}>
          <TourInstance />
        </ShepherdTour>
      )}
    </Fragment>
  )
}

Tour.propTypes = {
  view: PropTypes.string.isRequired,
}

export default Tour
