import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .min(5, 'El nombre debe tener al menos 5 caracteres')
      .max(32, 'El nombre no debe tener más de 32 caracteres')
      .required('El nombre es obligatorio'),
    lastName: yup
      .string()
      .min(5, 'El apellido debe tener al menos 5 caracteres')
      .max(32, 'El apellido no debe tener más de 32 caracteres')
      .required('El apellido es obligatorio'),
    phone: yup
      .string()
      .min(9, 'El teléfono debe tener al menos 9 caracteres')
      .max(14, 'El teléfono no debe tener más de 14 caracteres')
      .required('El teléfono es obligatorio'),
    email: yup
      .string()
      .email('El formato del correo electrónico no es válido')
      .required('El correo electrónico es obligatorio'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Debe contener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial',
      )
      .max(32, 'La contraseña no debe tener más de 32 caracteres')
      .required('La contraseña es obligatoria'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('La confirmación de contraseña es obligatoria'),
  })
  .required('Todos los campos son obligatorios')
