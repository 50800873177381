import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UI } from 'components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultSchema, validationSchemas } from './validations'
import { handlePayload, handleValidations } from './controller'
import { steps, defaultValues } from './constants'
import { avatars } from 'constants/assets'
import { styles } from './styles'
import { api } from 'api'
import useGooglePlaces from 'hooks/useGooglePlaces'
import toast from 'react-hot-toast'
// import StepHeader from './StepHeader'
import Actions from './Actions'
import Form from './Form'
import Preview from './Preview'

const CreateAsset = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [position, setPosition] = useState(null)
  const [validationType, setValidationType] = useState('')
  const [preview, setPreview] = useState(true)

  const userId = useSelector((state) => state.user.customer.authenticated.attributes.sub)
  const { AutoComplete, coordinates, placeDetails } = useGooglePlaces()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentStep = steps.find((item) => item.step === step) || steps[0]

  // Se cambia el schema de validación según el tipo de activo
  const handleValidationResolver = () => {
    if (validationType) return validationSchemas[validationType]
    return defaultSchema
  }

  const {
    watch,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(handleValidationResolver()),
  })

  const { assetType, assetSubType } = watch()

  const payload = handlePayload(watch, assetType, assetSubType)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await api.createAsset(userId, payload)
      toast.success('¡Activo creado con éxito!')
      dispatch({ type: 'set', assets: [] })
      return navigate('/assets')
    } catch (error) {
      setLoading(false)
      return console.error(error)
    }
  }

  const handleGoBack = () => {
    reset(defaultValues)
    return setPreview(true)
  }

  // Se selecciona automáticamente el avatar para enviarlo al payload
  const handleAvatarSelection = () => {
    if (assetType === 'Real Estate' && assetSubType)
      return setValue(
        'avatar',
        avatars[assetSubType] ? avatars[assetSubType] : avatars['Real Estate'],
      )
    setValidationType(assetType)
    return setValue('avatar', avatars[assetType] ? avatars[assetType] : avatars['Otros'])
  }

  useEffect(() => {
    handleAvatarSelection()
  }, [assetType, assetSubType])

  // Se asigna la posición del mapa y la dirección al payload
  const handleLocation = () => {
    if (placeDetails?.formatted_address) {
      setValue('address', placeDetails.formatted_address)
    }
    if (coordinates) {
      setValue('latitude', coordinates.lat)
      setValue('longitude', coordinates.lng)
      return setPosition(coordinates)
    }
  }

  useEffect(() => {
    handleLocation()
  }, [coordinates])

  // Al ingresar a la página, se debe hacer un scroll hacia arriba
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step, preview])

  // Cuando el tipo de activo sea 'Real Estate', se debe limpiar campos de los subtipos
  useEffect(() => {
    if (assetType !== 'Real Estate') {
      setValue('assetSubType', '')
      setValue('address', '')
    }
  }, [assetType])

  // Errores de ./validations.js
  const haveErrors = Boolean(Object.keys(errors).length)

  return (
    <div className={styles.MAIN_CONTAINER}>
      <UI.Header
        containerClassName="text-center mt-3"
        title={preview ? 'Seleccionar activo' : 'Agregar activos'}
        subtitle={preview ? '¿Qué tipo de activo deseas crear?' : 'Rellena todos los campos'}
      />
      <UI.Content className="!shadow-none !bg-transparent animate-fade-up">
        {/* Antes de ingresar al formulario habrá un selector de tipo de activos */}
        {preview ? (
          <Preview setValue={setValue} setPreview={setPreview} />
        ) : (
          <div className={styles.CONTENT}>
            <Form
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              currentStep={currentStep}
            />
            {/* En los casos de 'Real Estate', se activa el autocomplete con google maps junto con el mapa. */}
            {currentStep.key === 'data' && assetType === 'Real Estate' && (
              <Fragment>
                {AutoComplete()}
                <UI.Map
                  currentPosition={position}
                  markers={position && [position]}
                  height="300px"
                  scrollWheelZoom={false}
                />
              </Fragment>
            )}
            <Actions
              step={step}
              setStep={setStep}
              handleGoBack={handleGoBack}
              handleSubmit={handleSubmit}
              isDisabled={!(handleValidations(watch, assetType) && !haveErrors)}
              loading={loading}
            />
          </div>
        )}
      </UI.Content>
    </div>
  )
}

export default CreateAsset
