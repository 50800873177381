import React, { useState } from 'react'
import { Button, Input } from '@nextui-org/react'
import authImage from 'assets/svg/auth-footer.svg'
import useAuth from 'hooks/useAuth'
import { inputs } from './constants'
import { styles } from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import toast from 'react-hot-toast'
import { Icon } from '@iconify/react'

const defaultValues = {
  email: '',
  password: '',
}

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false)

  const { completeNewPassword, loading } = useAuth()

  const {
    watch,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const { email, code, password } = watch()

  const handleSubmit = (e) => {
    if (!isValid) return toast.error('Completa los campos correctamente')
    const userData = {
      email,
      code,
      password,
    }
    completeNewPassword(userData)
    e.preventDefault()
  }

  const handleType = (inputName, inputType) => {
    if (inputName === 'password') {
      return showPassword ? 'text' : 'password'
    }
    return inputType
  }

  const handleEndContent = (inputName) => {
    if (inputName === 'password' || inputName === 'confirmPassword') {
      return (
        <Icon
          className={styles.ICON}
          onClick={() => setShowPassword(!showPassword)}
          icon={showPassword ? 'line-md:watch-off' : 'line-md:watch'}
        />
      )
    }
    return null
  }

  return (
    <div className={styles.MAIN_CONTAINER}>
      <form className={styles.FORM}>
        <p className={styles.TITLE}>Se requiere cambiar la contraseña</p>
        {inputs.map((input) => (
          <Input
            key={input.name}
            type={handleType(input.name, input.type)}
            name={input.name}
            label={input.label}
            placeholder={input.placeholder}
            {...register(input.name)}
            isInvalid={errors[input.name]}
            errorMessage={errors[input.name]?.message}
            color={errors[input.name] ? 'danger' : 'primary'}
            variant="bordered"
            endContent={handleEndContent(input.name)}
          />
        ))}
        <Button color="primary" onClick={(e) => handleSubmit(e)} isLoading={loading}>
          Actualizar contraseña
        </Button>
        <div className={styles.BOTTOM_CONTENT}>
          <p>¿Recordaste tu contraseña?</p>
          <a href="/#/login" className={styles.LOGIN}>
            Volver a iniciar sesion
          </a>
        </div>
      </form>
      <img src={authImage} className={styles.FOOTER} alt="auth-footer" />
    </div>
  )
}

export default NewPassword
