import React, { Fragment } from 'react'
import { Input, Select, SelectItem } from '@nextui-org/react'
import { styles } from './styles'
import { inputs } from './constants'
import { formatRut, RutFormat } from '@fdograph/rut-utilities'
import { profileOptions } from 'constants/profile'
import PropTypes from 'prop-types'

const Form = ({ watch, register, setValue, trigger, errors, editSection }) => {
  const handleBlur = (fieldName) => {
    const inputValue = watch(fieldName)
    if (fieldName === 'identity_document' && inputValue) {
      const formattedValue = formatRut(inputValue, RutFormat.DOTS_DASH)
      setValue(fieldName, formattedValue)
      trigger(fieldName)
    } else {
      trigger(fieldName)
    }
  }

  const handleDefaultSelectedKey = (input) => {
    return [profileOptions[input.name].find((item) => item.value === watch()[input.name])?.value]
  }

  return (
    <form className={styles.FORM_CONTAINER}>
      {inputs[editSection].map((input, index) => {
        return (
          <Fragment key={index}>
            {input.isSelect ? (
              <Select
                items={input.options}
                label={input.label}
                placeholder={input.placeholder}
                className={input.className}
                {...register(input.name)}
                defaultSelectedKeys={handleDefaultSelectedKey(input)}
                errorMessage={errors[input.name]?.message}
                color={errors[input.name] ? 'danger' : 'primary'}
                isRequired={input.isRequired}
                listboxProps={{ className: 'bg-background' }}
                popoverProps={{ className: 'bg-background p-1 w-full' }}
                disallowEmptySelection
                variant="bordered"
                aria-label="select"
                aria-labelledby="select"
              >
                {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
              </Select>
            ) : (
              <Input
                type={input.type}
                name={input.name}
                disabled={input.name === 'email'}
                className={`${input.className} ${
                  input.name === 'email'
                    ? 'dark:bg-gray-700 dark:text-gray-400 bg-gray-200 text-gray-500 rounded-xl'
                    : ''
                }`}
                color={errors[input.name] ? 'danger' : input.name === 'email' ? 'grey' : 'primary'}
                label={input.label}
                placeholder={input.placeholder}
                {...register(input.name)}
                isInvalid={errors[input.name]}
                errorMessage={errors[input.name]?.message}
                isRequired={input.isRequired}
                variant="bordered"
                aria-label="input"
                aria-labelledby="input"
                onBlur={() => handleBlur(input.name)}
                maxLength={input.name === 'identity_document' ? 9 : undefined}
                defaultValue={watch()[input.name]}
                startContent={
                  input.name === 'phone' && (
                    <p className="text-primary/30 dark:text-foreground/30">+</p>
                  )
                }
              />
            )}
          </Fragment>
        )
      })}
    </form>
  )
}

Form.propTypes = {
  watch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  editSection: PropTypes.string.isRequired,
}

export default Form
