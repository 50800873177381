import { api } from 'api/config'

const getPatrimony = (userId) => {
  return api.get(`/patrimony/${userId}`)
}

const getAllAssets = (userId) => {
  return api.get(`/assets/user/${userId}`)
}

const createAsset = (userId, asset) => {
  return api.post(`/assets/${userId}`, asset)
}

const updateAsset = (assetId, asset) => {
  return api.put(`/assets/${assetId}`, asset)
}

const deleteAsset = (assetId) => {
  return api.delete(`/assets/${assetId}`)
}

const getAllPassives = (userId) => {
  return api.get(`/passives/user/${userId}`)
}

const createPassive = (userId, passive) => {
  return api.post(`/passives/${userId}`, passive)
}

const updatePassive = (passiveId, passive) => {
  return api.put(`/passives/${passiveId}`, passive)
}

const deletePassive = (passiveId) => {
  return api.delete(`/passives/${passiveId}`)
}

const getUserInfo = (userId) => {
  return api.get(`/clients/${userId}`)
}

const updateUser = (userId, data) => {
  return api.put(`/clients/${userId}`, data)
}

const syncCrowdfunding = (userId) => {
  return api.get(`/syncCrowdfunding/${userId}`)
}

const getOpportunities = () => {
  return api.get(`/oportunities`)
}

const getAvatars = () => {
  return api.get(`/avatar/perfil`)
}

export {
  getPatrimony,
  getAllAssets,
  createAsset,
  updateAsset,
  deleteAsset,
  getAllPassives,
  createPassive,
  updatePassive,
  deletePassive,
  getUserInfo,
  updateUser,
  syncCrowdfunding,
  getOpportunities,
  getAvatars,
}
