import React from 'react'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@nextui-org/react'
import { Utils } from 'helpers/index'

const columns = [
  { label: 'Movimiento', key: 'movement' },
  { label: 'Fecha', key: 'date' },
  { label: 'Estado', key: 'paymentStatus' },
  { label: 'Monto', key: 'amount' },
  { label: 'Emisor', key: 'issuedBy' },
]

const statusColorMap = {
  1: 'success',
  2: 'danger',
}
const statusDefinition = {
  1: 'Aprobado',
  2: 'Denegado',
}
const TablePortfolio = (data) => {
  const rows = data.data

  //Función que genera estilos únicos para cada fila
  const renderCell = React.useCallback((content, columnKey) => {
    const cellValue = content[columnKey]
    switch (columnKey) {
      case 'movement':
        return <div>Cuota brick</div>
      case 'date':
        return (
          <div className="flex flex-col">
            <p className="text-sm capitalize text-bold">{Utils.formatDates(cellValue)}</p>
          </div>
        )
      case 'paymentStatus':
        return (
          <Chip className="capitalize" color={statusColorMap[cellValue]} size="sm" variant="flat">
            {statusDefinition[cellValue]}
          </Chip>
        )
      case 'issuedBy':
        return (
          <div className="flex flex-col">
            <p className="text-sm capitalize text-bold text-default-400">Cliente</p>
          </div>
        )
      case 'amount':
        return (
          <div className="flex flex-col">
            <p className="text-sm capitalize text-bold">
              $ {Utils.formatRoundPrice(cellValue)} CLP
            </p>
          </div>
        )
      default:
        return cellValue
    }
  }, [])
  return (
    <Table
      aria-label="Tabla con movimientos financieros respecto a un proyecto"
      classNames={{
        wrapper: 'bg-transparent border-none shadow-none',
      }}
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={rows}>
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default TablePortfolio
