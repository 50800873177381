import React, { Fragment, useEffect, useState } from 'react'
import { UI } from 'components'
import { Spinner } from '@nextui-org/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import { steps, defaultSettings } from './constants'
import { styles } from './styles'
import { api, apiFrac } from 'api/index'
import { useDispatch, useSelector } from 'react-redux'
import { setFormValues } from './controller'
import toast from 'react-hot-toast'
import Account from './Account'
import Finance from './Finance'
import Form from './Form'
import Information from './Information'
import Preferences from './Preferences'
import ModalAvatar from './ModalAvatar'
import FormActions from './FormActions'
import Sections from './Sections'
import Objectives from './Objectives'
import Risk from './Risk'
import ReferralHeader from './ReferralHeader'
import Referrals from './Referrals'

// TODO: Cuando se cambie la lógica, aplicar también en Fraccionario
const Profile = () => {
  const [userCustomer, setUserCustomer] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [editSection, setEditSection] = useState('account')

  const [modalAvatar, setModalAvatar] = useState(false)

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const dispatch = useDispatch()
  const userCustomerId = useSelector(
    (state) => state.user?.customer.authenticated.attributes.sub || null,
  )

  const {
    watch,
    register,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const {
    // Account
    first_name,
    last_name,
    age,
    birth_date,
    identity_document,
    marital_status,
    profession,
    address,
    city,
    country,
    phone,
    email,
    // Finance
    employment_place,
    contract_type,
    seniority,
    account_type,
    bank,
    bank_account_number,
    net_income,
    additional_income,
    savings_capacity,
    savings,
    // Preferences
    interest_area,
    where_you_invest_today,
    risk_profile,
  } = watch()

  const fetchProfile = async () => {
    setLoading(true)
    try {
      const { data } = await api.getUserInfo(userCustomerId)
      const settings = data[0]?.settings === null ? defaultSettings : data[0]?.settings
      const user = { ...data[0], settings }
      setUserCustomer(user)
      setFormValues(user, setValue)
      dispatch({ type: 'set', profile: user })
      setLoading(false)
    } catch (error) {
      console.error('Error al obtener la información del usuario', error)
      return toast.error('Hubo un problema al obtener la información del usuario')
    }
  }

  useEffect(() => {
    if (userCustomerId !== null) {
      fetchProfile()
    }
  }, [])

  const payloads = {
    account: {
      first_name,
      last_name,
      age,
      birth_date,
      identity_document,
      marital_status,
      profession,
      address,
      city,
      country,
      phone,
    },
    finance: {
      employment_place,
      contract_type,
      seniority,
      account_type,
      bank,
      bank_account_number,
      net_income,
      additional_income,
      savings_capacity,
      savings,
    },
    preferences: {
      interest_area,
      where_you_invest_today,
      risk_profile,
    },
  }

  const updateFracUser = async () => {
    if (editSection === 'account') {
      try {
        const userFrac = {
          user: email,
          nombre: first_name,
          apellido: last_name,
          rut: identity_document,
          telefono: phone,
          direccion: address,
        }
        await apiFrac.updateUser(userFrac)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    updateFracUser()
    try {
      await api.updateUser(userCustomerId, payloads[editSection])
      toast.success('¡Usuario actualizado con éxito!')
      setSubmitLoading(false)
      setEditMode(false)
      return fetchProfile()
    } catch (error) {
      setSubmitLoading(false)
      toast.error('Hubo un problema al actualizar los datos, intente de nuevo más tarde')
      return console.error(error)
    }
  }

  const sections = [
    {
      title: 'Datos personales',
      icon: 'gridicons:user',
      description: 'Tu información personal nos ayuda a ofrecerte una mejor experiencia',
      component: <Information user={userCustomer} />,
      formKey: 'account',
      editAction: true,
    },
    {
      title: 'Datos financieros',
      icon: 'tabler:pig-money',
      description: 'Usaremos esta información para poder ofrecerte mejores productos',
      component: <Finance user={userCustomer} />,
      formKey: 'finance',
      editAction: true,
    },
    {
      title: 'Perfil de riesgo',
      icon: 'pajamas:warning',
      description: 'Realiza nuestra evaluación para conocer tu perfil de riesgo',
      component: <Risk user={userCustomer} fetchProfile={fetchProfile} />,
      formKey: 'risk',
      editAction: false,
    },
    {
      title: 'Mis objetivos',
      icon: 'gravity-ui:target-dart',
      description: 'Aquí podrás seleccionar tus intereses y objetivos de inversión',
      component: <Objectives user={userCustomer} userCustomerId={userCustomerId} />,
      formKey: 'objectives',
      editAction: false,
    },
    {
      title: 'Preferencias',
      icon: 'mingcute:settings-6-line',
      description: 'Puedes elegir qué tipo de información quieres recibir de nosotros',
      component: (
        <Preferences
          user={userCustomer}
          userCustomerId={userCustomerId}
          fetchProfile={fetchProfile}
        />
      ),
      formKey: 'preferences',
      editAction: true,
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <UI.Tour view="profile" />
      {modalAvatar && (
        <ModalAvatar
          userId={userCustomerId}
          user={userCustomer}
          visible={modalAvatar}
          setVisible={setModalAvatar}
          fetchUser={fetchProfile}
        />
      )}
      <div className="grid grid-cols-12 gap-3 max-w-7xl lg:mx-auto">
        <div className="col-span-12 lg:col-span-3">
          <UI.Content className="mb-3 animate-fade-right step-1">
            <Account
              user={userCustomer}
              setUser={setUserCustomer}
              userCustomerId={userCustomerId}
              setModalAvatar={setModalAvatar}
              loading={loading}
            />
          </UI.Content>
          {!loading && (
            <UI.Content className="md:animate-fade-down">
              <ReferralHeader setEditSection={setEditSection} setEditMode={setEditMode} />
            </UI.Content>
          )}
        </div>
        <div className="col-span-12 mb-auto lg:col-span-9">
          {editMode ? (
            <UI.Content className="animate-fade">
              <div
                className={`${styles.CONTENT} ${
                  editSection === 'referrals' && '!mx-0 !max-w-full'
                }`}
              >
                <p className={styles.TITLE}>
                  {editSection === 'referrals' ? 'Mis referidos' : steps[editSection].title}
                </p>
                {loading ? (
                  <div className="flex justify-center py-24">
                    <Spinner />
                  </div>
                ) : (
                  <Fragment>
                    {editSection !== 'referrals' ? (
                      <Fragment>
                        <Form
                          watch={watch}
                          register={register}
                          setValue={setValue}
                          trigger={trigger}
                          errors={errors}
                          editSection={editSection}
                        />
                        <FormActions
                          setEditMode={setEditMode}
                          handleSubmit={handleSubmit}
                          submitLoading={submitLoading}
                          isDirty={isDirty}
                        />
                      </Fragment>
                    ) : (
                      <Referrals
                        user={userCustomer}
                        setEditSection={setEditSection}
                        setEditMode={setEditMode}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            </UI.Content>
          ) : (
            <UI.Content className="p-6 animate-fade-up">
              <Sections
                sections={sections}
                setEditSection={setEditSection}
                setEditMode={setEditMode}
                loading={loading}
              />
            </UI.Content>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default Profile
