import { metaConversion, META_TOKEN } from 'api/config'

// Envía los eventos al Event Manager de Meta
const sendEvent = (data) => {
  return metaConversion.post(`/events?access_token=${META_TOKEN}`, '', {
    params: {
      data: JSON.stringify(data),
      access_token: META_TOKEN,
      // Enviar eventos de prueba
      // test_event_code: 'TEST73147',
    },
  })
}

export { sendEvent }

// Todos los eventos standard y personalizados se pueden consultar en:

// API Params
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters

// Server Events
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event#event-name

// Custom Data
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/custom-data
