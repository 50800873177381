import React, { useState } from 'react'
import LogoPropital from 'assets/images/app/logo-propital.png'
import AwsStartups from 'assets/images/app/aws-startups.png'
import { Icon } from '@iconify/react'
import { imageUrls } from 'constants/app'
import ModalToS from '../ModalToS'

const Footer = () => {
  const [openToS, setOpenToS] = useState(false)

  const links = {
    ios: 'https://apps.apple.com/ar/app/propital/id6474455959',
    android: 'https://play.google.com/store/apps/details?id=com.propitalapp&pli=1',
  }

  const sections = [
    {
      title: 'Propital',
      content: () => (
        <div className="flex flex-col space-y-1">
          <p>Evaristo Lillo 48, oficina 1002</p>
          <p>Las Condes, Chile</p>
          <p>Propital SpA, 2023.</p>
          <p>Todos los derechos reservados</p>
          <p>Contáctanos</p>
          <br />
          <p className="cursor-pointer" onClick={() => setOpenToS(true)}>
            Términos y Condiciones
          </p>
        </div>
      ),
    },
    {
      title: 'Social',
      content: () => (
        <div className="flex space-x-2">
          <a href="https://www.facebook.com/propital" target="_blank" rel="noreferrer">
            <Icon icon="formkit:facebook" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://www.instagram.com/propital" target="_blank" rel="noreferrer">
            <Icon
              icon="uil:instagram"
              className="p-[3px] min-h-[24px] min-w-[24px] rounded-full bg-white text-secondary"
            />
          </a>
          <a href="https://twitter.com/propital" target="_blank" rel="noreferrer">
            <Icon icon="formkit:twitter" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://www.linkedin.com/company/propital" target="_blank" rel="noreferrer">
            <Icon icon="entypo-social:linkedin-with-circle" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://wa.link/wqvotz" target="_blank" rel="noreferrer">
            <Icon icon="formkit:whatsapp" className="min-h-[24px] min-w-[24px]" />
          </a>
        </div>
      ),
    },
    {
      title: 'Contacto',
      content: () => (
        <div className="flex flex-col space-y-1">
          <a href="https://wa.link/wqvotz" target="_blank" rel="noreferrer">
            <p>+569 96158452</p>
          </a>
          <p>contacto@propital.com</p>
          <p>Agenda una reunión</p>
        </div>
      ),
    },
    {
      title: 'Aprende más',
      content: () => (
        <div className="flex flex-col space-y-1">
          <a href="https://www.propital.com/real-estate-mastery" target="_blank" rel="noreferrer">
            <p>Academy</p>
          </a>
          <a href="https://ww2.propital.com/blog" target="_blank" rel="noreferrer">
            <p>Blog de Inversiones</p>
          </a>
        </div>
      ),
    },
    {
      title: 'Respaldados por',
      content: () => (
        <div>
          <img src={AwsStartups} className="w-auto h-11" alt="AWS Startups" />
          <h3 className="mb-4 text-xl font-bold h-[28px] mt-9">Descarga la App!</h3>
          <div className="flex flex-col items-center space-y-3 md:items-start">
            <a href={links.android} target="_blank" rel="noreferrer">
              <img
                src={imageUrls.app.android}
                className="object-contain w-auto h-9"
                alt="Android App"
              />
            </a>
            <a href={links.ios} target="_blank" rel="noreferrer">
              <img src={imageUrls.app.ios} className="object-contain w-auto h-9" alt="iOS App" />
            </a>
          </div>
        </div>
      ),
    },
  ]
  return (
    <footer className="flex items-center justify-center h-full px-3 py-12 text-center text-white md:text-left md:py-24 bg-secondary">
      {openToS && <ModalToS visible={openToS} setVisible={setOpenToS} />}
      <div className="flex flex-col items-start space-y-9 md:space-y-0 md:flex-row md:space-x-9">
        {sections.map((section, index) => (
          <div key={index} className="mx-auto md:mx-0">
            {index === 0 ? (
              <img
                src={LogoPropital}
                className="h-[28px] mx-auto md:mx-0 mb-4"
                alt="Logo Propital"
              />
            ) : (
              <h3 className="mb-4 text-xl font-bold h-[28px]">{section.title}</h3>
            )}
            {section.content()}
          </div>
        ))}
      </div>
    </footer>
  )
}

export default Footer
