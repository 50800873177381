import React from 'react'
import { Button, Input } from '@nextui-org/react'
import authImage from 'assets/svg/auth-footer.svg'
import useAuth from 'hooks/useAuth'
import { inputs } from './constants'
import { styles } from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'

const defaultValues = {
  email: '',
  code: '',
}

const Confirm = () => {
  const { confirmSignUp, loading } = useAuth()

  const {
    watch,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const { email, code } = watch()

  const handleConfirmSignUp = (e) => {
    confirmSignUp(email, code)
    e.preventDefault()
  }

  return (
    <div className={styles.MAIN_CONTAINER}>
      <form className={styles.FORM}>
        <p className={styles.TITLE}>Confirmar cuenta</p>
        {inputs.map((input) => (
          <Input
            key={input.name}
            type={input.type}
            name={input.name}
            label={input.label}
            placeholder={input.placeholder}
            {...register(input.name)}
            isInvalid={errors[input.name]}
            errorMessage={errors[input.name]?.message}
            color={errors[input.name] ? 'danger' : 'primary'}
            variant="bordered"
          />
        ))}
        <Button
          color="primary"
          isDisabled={!isValid}
          isLoading={loading}
          onClick={(e) => handleConfirmSignUp(e)}
        >
          Confirmar
        </Button>
        <div className={styles.BOTTOM_CONTENT}>
          <p>¿Todavía no tienes una cuenta?</p>
          <a href="/#/register" className={styles.REGISTER}>
            Regístrate aquí
          </a>
        </div>
      </form>
      <img src={authImage} className={styles.FOOTER} alt="auth-footer" />
    </div>
  )
}

export default Confirm
