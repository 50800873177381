import React from 'react'
import PropTypes from 'prop-types'
import { Link, NavbarMenu, NavbarMenuItem } from '@nextui-org/react'
import { Icon } from '@iconify/react'

const MobileMenu = ({ routes = [], pathname = '', setOpen }) => {
  return (
    <NavbarMenu className="pt-6">
      {routes.map((item, index) => {
        if (!item.showNavigate) return null
        return (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              size="lg"
              href={item.linkTo}
              onClick={() => setOpen(false)}
              className={`${
                pathname === item.path
                  ? 'font-semibold text-primary'
                  : 'font-normal text-neutral-500'
              }`}
            >
              <Icon className="mx-auto h-[30px] w-[30px]" icon={item.icon} />
              <p className="ml-2">{item.name}</p>
            </Link>
          </NavbarMenuItem>
        )
      })}
    </NavbarMenu>
  )
}

MobileMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default MobileMenu
