import React, { Fragment, useState } from 'react'
import CourseImage from 'assets/images/app/academy/curso.png'
import PresentialImage from 'assets/images/app/academy/presencial.png'
import BookImage from 'assets/images/app/academy/libro.png'
import { Card, CardBody, Image, Spinner } from '@nextui-org/react'
import toast from 'react-hot-toast'

const Academy = () => {
  const [selected] = useState(null)
  const [loading, setLoading] = useState(false)
  const views = [
    {
      title: 'Curso de inversión inmobiliaria',
      name: 'Real Estate Mastery 2.0',
      image: CourseImage,
      url: 'https://propital.com/real-estate-mastery',
      tag: 'Curso',
    },
    {
      title: 'Curso intensivo presencial',
      name: 'Inversión inmobiliaria',
      image: PresentialImage,
      url: 'https://ww2.propital.com/curso-presencial-inversión-inmobiliaria?utm_campaign=Curso%20presencial%20Marzo%202024&utm_medium=email&_hsmi=291503209&_hsenc=p2ANqtz-82qbHD8s-xyHstGb33YXVfe5MHfSmzmfvo8S7pJ8CVDe6-yM611E5-heLM78KzrGYMuW3bhSIPuiHJA1U5l4sFRhWEa7bKFo9ni7XSI78XxOAOB7k&utm_content=291503207&utm_source=hs_email',
      tag: 'Curso',
    },
    {
      title: 'Libro de aprendizaje',
      name: 'El código secreto de la inversión inmobiliaria',
      image: BookImage,
      url: 'https://www.propital.net/codigo-secreto1609163285404',
      tag: 'Libro',
    },
  ]
  // Crear función que active un loader durante un segundo para que no se ponga en blanco la página hasta que cargue el iframe
  return (
    <Fragment>
      {selected ? (
        <Fragment>
          {loading && (
            <div className="flex h-[80vh] items-center justify-center">
              <Spinner size="lg" />
            </div>
          )}
          <iframe
            src={selected.url}
            title={selected.name}
            onLoad={() => setLoading(false)}
            className={`w-[100vw] h-[90vh] !-mx-3 !-my-3 animate-fade ${loading && 'hidden'}`}
          />
        </Fragment>
      ) : (
        <div className="flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto">
          <h1 className="my-3 text-3xl font-semibold text-center text-primary">Academy</h1>
          <h2 className="!mb-6 text-xl text-center text-secondary dark:text-foreground">
            Aprende a invertir con Propital
          </h2>
          <div className="flex flex-col space-y-3 md:flex-row md:justify-center md:space-y-0 md:space-x-6 animate-fade-up">
            {views.map((view, index) => (
              <Card
                key={index}
                className={`w-[300px] mx-auto md:mx-0 hover:scale-105 border-none shadow-propital dark:shadow-none !rounded-2xl bg-white dark:bg-background`}
                isPressable
                onPress={() => {
                  if (view.tag === 'Libro')
                    return toast.error('Este enlace no está disponible en este momento.')
                  window.open(view.url, '_blank')
                }}
              >
                <CardBody>
                  <Image src={view.image} />
                  <p className="text-sm text-center text-inactive dark:text-foreground">
                    {view.title}
                  </p>
                  <p className="text-sm font-semibold text-center text-secondary dark:text-primary">
                    {view.name}
                  </p>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default Academy
