import React, { Fragment, useEffect, useState } from 'react'
import { Button, Divider, Input, Progress, Spinner, Tab, Tabs } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useSelector } from 'react-redux'
import { apiCommunicator, apiReferrals } from 'api/index'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sendEmailSchema } from './validations'
import LandingCity from 'assets/images/app/landing-city.png'
import useDate from 'hooks/useDate'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'

const Referrals = ({ user, setEditSection, setEditMode }) => {
  const { getTimeAgo } = useDate()

  const [selectedTab, setSelectedTab] = useState('summary')

  const [codeData, setCodeData] = useState({})
  const [referrals, setReferrals] = useState([])

  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userCustomerId = useSelector(
    (state) => state.user?.customer.authenticated.attributes.sub || null,
  )

  const {
    watch,
    register,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(sendEmailSchema),
    defaultValues: {
      email: '',
    },
  })

  const fetchReferrals = async () => {
    setLoading(true)
    try {
      const { data } = await apiReferrals.getCode({ referrer_id: userCustomerId })
      const { referral_code } = data.payload
      const { data: referralsData } = await apiReferrals.getReferrals(referral_code)
      setCodeData(data.payload)
      setReferrals(referralsData)
      setLoading(false)
    } catch (error) {
      console.error(error)
      toast.error('Ha ocurrido un error al cargar tus referidos')
    }
  }

  const sendReferralEmail = async () => {
    setIsSubmitting(true)
    try {
      const { email } = watch()
      await apiCommunicator.sendEmail({
        email_from: '"Propital" <no-reply@propital.com>',
        email_to: email,
        email_subject: `${user.first_name} te ha invitado a Propital! 🚀`,
        data: {
          REFERRAL_CODE: codeData.referral_code,
          USUARIO: user.first_name,
        },
      })
      toast.success('Correo enviado con éxito!')
    } catch (error) {
      console.error(error)
      toast.error('Ha ocurrido un error al enviar el correo')
    } finally {
      setIsSubmitting(false)
      reset()
    }
  }

  useEffect(() => {
    fetchReferrals()
  }, [])

  const steps = [
    {
      title: 'Comparte tu enlace de referencia',
      description: 'Envía tu enlace a tus amigos o compártelo en tus redes',
    },
    {
      title: 'Invita a tus amigos',
      description: 'Por cada uno que se registre, recibirás una recompensa',
    },
    {
      title: 'Disfruta de tus beneficios',
      description: '¡Reclama tus recompensas en la sección de Mis beneficios! 🚀',
    },
  ]

  const objectives = [
    {
      title: 'Recompensas',
      icon: 'line-md:star',
      value: 0,
    },
    {
      title: 'Referidos',
      icon: 'line-md:account',
      value: referrals.length,
    },
    {
      title: 'Completados',
      icon: 'line-md:circle-to-confirm-circle-transition',
      value: referrals.filter((referral) => referral.status === 'Confirmed').length,
    },
  ]

  const link = `http://app.propital.com/#/register?code=${codeData.referral_code}`

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  const confirmedReferrals = referrals.filter((referral) => referral.status === 'Confirmed').length

  const rewards = [
    {
      value: confirmedReferrals,
      maxValue: 1,
    },
    {
      value: confirmedReferrals,
      maxValue: 5,
    },
    {
      value: confirmedReferrals,
      maxValue: 10,
    },
  ]

  const tabs = [
    {
      id: 'summary',
      label: 'Resumen',
      content: (
        <div className="flex flex-col space-y-3">
          <div className="flex flex-col w-full mx-auto space-y-3 md:space-y-0 md:flex-row md:space-x-3">
            {objectives.map((objective, index) => (
              <div
                className="flex flex-col w-full p-3 space-y-1 font-semibold rounded-3xl bg-primary/10"
                key={index}
              >
                <div className="flex items-center mx-auto space-x-2 text-primary">
                  <Icon icon={objective.icon} className="w-[24px] h-[24px]" />
                  <p className="text-center">{objective.title}</p>
                </div>
                <span className="text-lg font-extrabold text-center">{objective.value}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full p-3 mx-auto space-y-1 font-semibold rounded-3xl bg-primary/10">
            <div className="flex items-center space-x-2 text-primary">
              <Icon icon="line-md:external-link" className="w-[24px] h-[24px]" />
              <p>Tu enlace de referencia</p>
            </div>
            <span className="flex items-center ml-1 font-normal">
              {link}
              <Button
                size="sm"
                variant="bordered"
                isIconOnly
                className="border-none rounded-full text-primary"
                onClick={() => copyToClipboard(link)}
              >
                <Icon icon="akar-icons:copy" className="w-[16px] h-[16px]" />
              </Button>
            </span>
          </div>
          <p>
            También puedes compartir tu enlace enviando un correo a tus contactos. Solo tienes que{' '}
            <b>escribir su correo</b> y hacer clic en <b>enviar</b>!
          </p>
          <div className="relative">
            <Input
              color="primary"
              variant="flat"
              placeholder="Escribe el correo de tu contacto"
              {...register('email')}
              className="border-none"
              classNames={{
                inputWrapper: 'rounded-3xl px-4 h-[48px]',
              }}
            />
            <Button
              color="primary"
              className="absolute right-0 !mr-3 rounded-3xl font-bold top-1/2 transform -translate-y-1/2"
              variant="shadow"
              size="sm"
              endContent={<Icon icon="akar-icons:send" className="w-[16px] h-[16px]" />}
              isDisabled={!isValid || isSubmitting}
              onClick={() => sendReferralEmail()}
            >
              Enviar
            </Button>
          </div>
          <div className="flex flex-col pt-6 md:!mt-6 mx-auto space-y-3 border-2 shadow-propital border-primary/75 rounded-3xl w-fit bg-gradient-to-t dark:from-primary/25 from-primary/50 dark:via-neutral-900 via-white dark:to-neutral-900 to-white">
            <h2 className="text-lg font-bold text-center">¿Cómo funciona?</h2>
            {steps.map((step, index) => (
              <div className="flex items-center px-6 space-x-3 md:px-9" key={index}>
                <div className="shadow-propital font-extrabold text-white flex items-center justify-center min-w-[30px] min-h-[30px] h-[30px] w-[30px] md:min-h-[40px] md:min-w-[40px] md:h-[40px] md:w-[40px] rounded-full bg-primary">
                  {index + 1}
                </div>
                <div className="flex flex-col">
                  <p className="font-bold text-primary">{step.title}</p>
                  <span>{step.description}</span>
                </div>
              </div>
            ))}
            <img src={LandingCity} className="w-fit rounded-b-3xl" alt="Banner ciudad" />
          </div>
        </div>
      ),
    },
    {
      id: 'referrals',
      label: 'Referidos',
      content: (
        <div className="grid grid-cols-2 gap-3">
          {referrals.length > 0 ? (
            referrals.map((ref, index) => {
              const isConfirmed = ref.status === 'Confirmed'
              return (
                <div
                  key={index}
                  className={`col-span-1 py-3 px-4 rounded-3xl relative ${
                    isConfirmed
                      ? 'bg-primary/10 text-primary'
                      : 'bg-fuchsia-600/10 text-fuchsia-600'
                  }`}
                >
                  <Icon
                    icon={isConfirmed ? 'iconamoon:check-circle-1-duotone' : 'ic:twotone-pending'}
                    className={`w-[24px] h-[24px] mt-2 mr-2 absolute top-0 right-0 ${
                      isConfirmed ? 'text-primary' : 'text-fuchsia-600/70'
                    }`}
                  />
                  <p className="font-bold">Referido {index + 1}</p>
                  <p className="text-sm">Estado: {isConfirmed ? 'Completado' : 'Pendiente'}</p>
                  <p className="text-xs">
                    {getTimeAgo(isConfirmed ? ref.date_acepted : ref.date_referred)}
                  </p>
                </div>
              )
            })
          ) : (
            <div className="col-span-2 px-4 py-3 rounded-3xl bg-primary/10 text-primary">
              <p className="font-semibold text-center">Aún no posees referidos</p>
            </div>
          )}
        </div>
      ),
    },
    {
      id: 'rewards',
      label: 'Recompensas',
      content: (
        <div className="flex flex-col space-y-3">
          <p>
            Invita a tus contactos y gana recompensas por cada uno que se registre y complete su
            perfil
          </p>
          <p className="font-semibold text-primary">Referidos completados:</p>
          {rewards.map((item, index) => {
            const isClaimable = item.value >= item.maxValue
            return (
              <div
                key={index}
                className={`flex items-center p-3 space-x-2 bg-primary/10 rounded-3xl ${
                  isClaimable ? '' : 'opacity-80'
                }`}
              >
                <Progress
                  value={confirmedReferrals}
                  maxValue={item.maxValue}
                  isStriped={!isClaimable}
                  size="lg"
                />
                <p className={`w-20 text-center ${isClaimable ? 'font-bold' : ''}`}>
                  {item.value}/{item.maxValue}
                </p>
                <Button
                  color={isClaimable ? 'primary' : ''}
                  className={`px-6 rounded-3xl ${isClaimable ? 'font-bold' : ''}`}
                  isDisabled={!isClaimable}
                  size="sm"
                >
                  Reclamar
                </Button>
              </div>
            )
          })}
        </div>
      ),
    },
  ]

  return (
    <div className="flex flex-col w-full max-w-2xl px-3 mx-auto text-secondary dark:text-foreground">
      <p className="mb-3">
        Aquí puedes ver el <b>estado</b> de tus referidos. Podrás consultar quiénes se han
        registrado a través de tu enlace y cuánto progreso han hecho <b>cada uno</b> de ellos.
      </p>
      {loading ? (
        <Spinner size="lg" color="primary" className="mx-auto !my-9" />
      ) : (
        <Fragment>
          <div className="flex flex-col w-full">
            <Tabs
              selectedKey={selectedTab}
              onSelectionChange={setSelectedTab}
              color="primary"
              radius="lg"
              variant="solid"
              items={tabs}
              aria-label="types"
              classNames={{
                // base: 'justify-center',
                panel: `overflow-y-auto p-0 pt-3 ${
                  selectedTab === 'referrals' ? 'max-h-[600px]' : ''
                }`,
                tabList: 'rounded-3xl bg-primary/10',
                cursor: 'rounded-3xl',
                tab: 'rounded-3xl',
              }}
            >
              {(item) => (
                <Tab key={item.id} title={item.label}>
                  {item.content}
                </Tab>
              )}
            </Tabs>
            <div className="py-3 my-3">
              <Divider />
            </div>
            <div className="flex justify-center space-x-3">
              <Button
                className="rounded-3xl w-[160px]"
                color="primary"
                variant="bordered"
                onClick={() => {
                  window.scrollTo(0, 0)
                  setEditMode(false)
                  setEditSection('account')
                }}
              >
                Volver atrás
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}

Referrals.propTypes = {
  user: PropTypes.object,
  setEditSection: PropTypes.func,
  setEditMode: PropTypes.func,
}

export default Referrals
