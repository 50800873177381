export const styles = {
  MAIN_CONTAINER: 'flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto',
  BUTTON_HEADER: 'animate-fade-right animate-duration-500 rounded-3xl',
  NO_RESULTS: 'flex items-center p-6 py-12 space-x-3 rounded-lg bg-primary/10',
  RESULT_ICON: 'w-9 h-9 text-primary',
  CARDS_CONTAINER:
    'grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5',
  CARD: 'border-none shadow-propital dark:shadow-none !rounded-2xl bg-white dark:bg-background',
  IMAGE: 'w-full object-contain h-[160px] border-none shadow-none',
  TEXT_CONTAINER: 'text-xl font-bold text-secondary dark:text-primary',
  NAME: 'text-sm font-semibold text-projectName dark:text-foreground',
  ACTIONS: 'flex justify-center space-x-3',
  PAGINATE: 'flex justify-center my-3',
  CHIP: 'shadow-propital',
}

export const detailStyles = {
  CONTENT: 'flex flex-col px-1 space-y-3 text-secondary',
  HEADER: 'flex items-center space-x-3 rounded-lg',
  IMAGE: 'object-contain rounded-full w-20 h-20 bg-primary/10',
  SECTION: 'flex flex-col',
  TITLE: 'font-semibold text-primary dark:text-foreground',
  TEXT: 'text-sm text-neutral-500',
}

export const editStyles = {
  CONTENT: 'flex flex-col px-1 space-y-3 dark:text-foreground',
  SECTION: 'flex flex-col',
  TEXT: 'text-sm text-neutral-500',
}
