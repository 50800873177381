/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiMeta } from 'api/index'
import { App } from 'helpers/index'
import CryptoJS from 'crypto-js'

const useConversion = () => {
  const [userIp, setUserIp] = useState('')
  const userCustomer = useSelector((state) => state.user?.customer?.authenticated?.attributes)

  const sendEvent = async (obj) => {
    // Evento por defecto
    const eventData = [
      {
        event_name: obj?.event_name ?? 'ViewContent',
        event_time: Math.round(new Date().getTime() / 1000),
        action_source: obj?.action_source ?? 'website',
        event_source_url: window.location.href,
        ...(obj?.user_data
          ? { user_data: obj.user_data }
          : {
              user_data: {
                fn: CryptoJS.SHA256(
                  userCustomer?.name ? userCustomer.name.trim().toLowerCase() : 'Invitado',
                ).toString(),
                ln: CryptoJS.SHA256(
                  userCustomer?.family_name ? userCustomer.family_name.trim().toLowerCase() : '',
                ).toString(),
                em: CryptoJS.SHA256(
                  userCustomer?.email ? userCustomer.email.trim().toLowerCase() : '',
                ).toString(),
                ph: CryptoJS.SHA256(
                  userCustomer?.phone_number ? userCustomer.phone_number?.split('+')[1] : '',
                ).toString(),
                client_ip_address: userIp,
              },
            }),
        ...(obj?.custom_data ? { custom_data: obj.custom_data } : {}),
      },
    ]
    // Solo funcionará en producción
    if (!App.isProd()) {
      return // console.log(eventData)
    }
    try {
      apiMeta.sendEvent(eventData)
      return eventData
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // La IP solo se obtendrá en producción
    if (App.isProd()) {
      App.getIp(userIp, setUserIp)
    }
  }, [])

  return { sendEvent }
}

export default useConversion
