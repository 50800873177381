import { Utils } from 'helpers/index'
import { priceFields } from './constants'

// Función para formatear el valor de los campos de precio
export const handlePriceChange = (inputName, e, setValue) => {
  if (priceFields.includes(inputName)) {
    let inputValue = e.target.value
    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''))
    if (!isNaN(numericValue)) {
      let formattedValue = numericValue.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      if (Number.isInteger(numericValue)) {
        formattedValue = numericValue.toLocaleString('es-AR')
      }
      setValue(inputName, numericValue)
      e.target.value = formattedValue
    }
  } else {
    setValue(inputName, e.target.value)
  }
}

// Función para manejar el evento onKeyDown y permitir solo valores numéricos
export const handleKeyPress = (inputName, e) => {
  const allowedCharacters = /[0-9.]/
  const isPriceField = priceFields.includes(inputName)
  if (isPriceField && !allowedCharacters.test(e.key) && e.key !== 'Backspace') {
    e.preventDefault()
  }
}

// Manejo de payload hacia la API según el tipo de activo, cada uno tiene campos específicos
export const handlePayload = (watch, assetType, assetSubType) => {
  const {
    age,
    assetPrice,
    currencyAsset,
    rentalPrice,
    annualVacancy,
    annualPropTax,
    description,
    nameProject,
    bricksNumber,
    brickPrice,
    sharesNumber,
    sharePrice,
    bank,
    depositType,
    startDate,
    finishDate,
    typeMutualFund,
    address,
    longitude,
    latitude,
    avatar,
    bathrooms,
    bedrooms,
  } = watch()

  const formatTypologies = () => {
    const typologies = [bathrooms && `${bathrooms}B`, bedrooms && `${bedrooms}D`]
    // Usar | solo cuando haya más de un elemento
    return typologies.filter((item) => item).join('|')
  }

  const defaultAsset = {
    assetType,
    currencyAsset,
    avatar,
  }
  const assetWithProject = {
    ...defaultAsset,
    description,
    nameProject,
  }
  if (assetType === 'Real Estate') {
    const asset = {
      ...assetWithProject,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
      assetSubType,
      address,
      longitude,
      latitude,
    }
    const assetWithRental = {
      ...asset,
      rentalPrice: Utils.sanitizeNumericValue(rentalPrice),
      annualVacancy: annualVacancy ? parseInt(annualVacancy) : null,
      annualPropTax: annualPropTax ? Utils.sanitizeNumericValue(annualPropTax) : null,
      typology: formatTypologies(),
    }
    if (assetSubType !== 'Terreno' && assetSubType !== 'Parcela') {
      return assetWithRental
    }
    return asset
  }
  const payloads = {
    Fraccionario: {
      ...assetWithProject,
      bricksNumber: parseInt(bricksNumber),
      brickPrice: Utils.sanitizeNumericValue(brickPrice),
    },
    Acciones: {
      ...assetWithProject,
      sharesNumber: parseInt(sharesNumber),
      sharePrice: Utils.sanitizeNumericValue(sharePrice),
    },
    'Fondos mutuos': {
      ...assetWithProject,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
      typeMutualFund,
    },
    'Depósito a plazo': {
      ...defaultAsset,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
      bank,
      depositType,
      finishDate, // (YYYY-MM-DD)
      startDate, // (YYYY-MM-DD)
    },
    Vehiculo: {
      ...assetWithProject,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
      age,
    },
    Otros: {
      ...assetWithProject,
      assetPrice: Utils.sanitizeNumericValue(assetPrice),
    },
  }
  return payloads[assetType]
}

// Validación de campos según el tipo de activo
export const handleValidations = (watch, assetTypeKey) => {
  const {
    age,
    assetType,
    assetPrice,
    currencyAsset,
    nameProject,
    bricksNumber,
    brickPrice,
    sharesNumber,
    sharePrice,
    bank,
    depositType,
    startDate,
    finishDate,
    typeMutualFund,
    longitude,
    latitude,
  } = watch()

  const validations = {
    'Real Estate': Boolean(
      assetPrice && assetType && currencyAsset && nameProject && latitude && longitude,
    ),
    Fraccionario: Boolean(assetType && currencyAsset && nameProject && bricksNumber && brickPrice),
    Acciones: Boolean(assetType && currencyAsset && nameProject && sharesNumber && sharePrice),
    'Fondos mutuos': Boolean(
      assetPrice && assetType && currencyAsset && nameProject && typeMutualFund,
    ),
    'Depósito a plazo': Boolean(
      assetPrice && assetType && currencyAsset && bank && depositType && startDate && finishDate,
    ),
    Vehiculo: Boolean(age && assetPrice && assetType && currencyAsset && nameProject),
    Otros: Boolean(assetPrice && assetType && currencyAsset && nameProject),
  }
  return validations[assetTypeKey]
}
