import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    passiveType: yup.string().required('El tipo de pasivo es obligatorio'),
    passivePrice: yup.string().required('El precio del pasivo es obligatorio'),
    currencyPassive: yup.string().required('La divisa del monto es obligatoria'),
    quotaValue: yup.string().required('La cuota del pasivo es obligatoria'),
    quotaPeriod: yup
      .number()
      .min(1, 'El período de la cuota debe ser positivo')
      .typeError('El período de la cuota debe ser un número')
      .required('El período de la cuota es obligatorio'),
    pendingQuota: yup
      .number()
      .min(1, 'El plazo del crédito pendiente debe ser positivo')
      .typeError('El plazo del crédito pendiente debe ser un número')
      .required('El plazo del crédito pendiente es obligatorio'),
    annualInterestRate: yup
      .number()
      .min(0, 'No puede ser negativo')
      .max(100, 'No puede ser mayor a 100')
      .typeError('La tasa de interés anual debe ser un número')
      .required('La tasa de interés anual es obligatoria'),
  })
  .required('Todos los campos son obligatorios')
