import { assetOptions } from 'constants/assets'

export const defaultValues = {
  assetType: '', // Tipo de activo
  assetSubType: '', // Subtipo de activo
  assetPrice: '', // Precio del activo
  currencyAsset: '', // Divisa del activo
  rentalPrice: '', // Canon de Arriendo
  currencyRentalPrice: '', // Divisa del arriendo
  rentalPriceFrequency: '', // Frecuencia del canon
  annualVacancy: '', // Vacancia anual estimada (Opc.)
  annualPropTax: '', // Impuesto territorial anual (Opc.)
  description: '', // Descripción
  nameProject: '', // Nombre del activo
  bricksNumber: '', // Cantidad de bricks
  brickPrice: '', // Precio del brick
  sharesNumber: '', // Cantidad de acciones
  sharePrice: '', // Precio de la acción
  bank: '', // Banco en el cual tiene ese deposito
  depositType: '', // Depósito fijo o renovable
  startDate: '', // (YYYY-MM-DD)
  finishDate: '', // (YYYY-MM-DD)
  typeMutualFund: '', // Cuál es el fondo al que pertenece
  address: '',
  longitude: '',
  latitude: '',
  avatar: '',
  age: '', // Modelo del vehículo
  bathrooms: '', // Cantidad de baños
  bedrooms: '', // Cantidad de dormitorios
}

// Campos que requieren formato de precio
export const priceFields = [
  'assetPrice',
  'rentalPrice',
  'brickPrice',
  'sharePrice',
  'annualVacancy',
]

export const steps = [
  {
    step: 1,
    title: 'Información del activo',
    key: 'data',
    icon: 'line-md:pencil',
    percent: 0,
  },
]

export const inputs = {
  data: [
    {
      type: 'text',
      name: 'assetType',
      label: 'Tipo de activo',
      placeholder: 'Elige una opción',
      isRequired: true,
      isSelect: true,
      options: assetOptions.types,
      className: 'col-span-2',
    },
    {
      type: 'text',
      name: 'assetSubType',
      label: 'Subtipo de activo',
      placeholder: 'Elige una opción',
      isRequired: true,
      isSelect: true,
      options: assetOptions.subTypes,
      className: 'col-span-2 animate-fade',
    },
    {
      type: 'text',
      name: 'assetPrice',
      label: 'Precio del activo',
      placeholder: 'Ingresa el monto',
      isRequired: true,
      className: 'col-span-1',
      startContent: '$',
    },
    {
      type: 'text',
      name: 'brickPrice',
      label: 'Precio del brick',
      placeholder: 'Ingresa un monto',
      isRequired: true,
      className: 'col-span-1',
      startContent: '$',
    },
    {
      type: 'text',
      name: 'sharePrice',
      label: 'Precio de la acción',
      placeholder: 'Ingresa un monto',
      isRequired: true,
      className: 'col-span-1',
      startContent: '$',
    },
    {
      type: 'text',
      name: 'currencyAsset',
      label: 'Divisa',
      placeholder: 'Elige una opción',
      isRequired: true,
      isSelect: true,
      options: assetOptions.currencies,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'nameProject',
      label: 'Nombre del activo',
      placeholder: 'Escribe un nombre',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'description',
      label: 'Descripción',
      placeholder: 'Describe brevemente',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'bathrooms',
      label: 'Baños',
      placeholder: 'Elige una opción',
      isRequired: false,
      isSelect: true,
      options: assetOptions.bathrooms,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'bedrooms',
      label: 'Dormitorios',
      placeholder: 'Elige una opción',
      isRequired: false,
      isSelect: true,
      options: assetOptions.bedrooms,
      className: 'col-span-1',
    },
    {
      type: 'number',
      name: 'bricksNumber',
      label: 'Cantidad de bricks',
      placeholder: 'Ingresa un número',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'number',
      name: 'sharesNumber',
      label: 'Cantidad de acciones',
      placeholder: 'Ingresa un número',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'rentalPrice',
      label: 'Canon de Arriendo',
      placeholder: 'Ingresa el monto',
      isRequired: true,
      className: 'col-span-2',
      startContent: '$',
    },
    {
      type: 'text',
      name: 'bank',
      label: 'Nombre del banco',
      placeholder: 'Escribe un nombre',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'depositType',
      label: 'Tipo de depósito',
      placeholder: 'Elige una opción',
      isRequired: true,
      isSelect: true,
      options: assetOptions.depositType,
      className: 'col-span-1',
    },
    {
      type: 'date',
      name: 'startDate',
      label: 'Fecha de inicio',
      placeholder: 'Elige una fecha',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'date',
      name: 'finishDate',
      label: 'Fecha de finalización',
      placeholder: 'Elige una fecha',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'typeMutualFund',
      label: 'Tipo de fondo al que pertenece',
      placeholder: 'Escribe un nombre',
      isRequired: true,
      className: 'col-span-2',
    },
    {
      type: 'text',
      name: 'annualVacancy',
      label: 'Vacancia anual estimada',
      placeholder: '(Opcional)',
      className: 'col-span-1',
      startContent: '$',
    },
    {
      type: 'number',
      name: 'annualPropTax',
      label: 'Impuesto territorial anual',
      placeholder: '(Opcional)',
      className: 'col-span-1',
      startContent: '%',
    },
    {
      type: 'number',
      name: 'age',
      label: 'Modelo de la unidad',
      placeholder: 'Ingrese el año de fabricación de la unidad',
      isRequired: false,
      className: 'col-span-2',
    },
  ],
  location: [],
  documents: [],
  images: [],
  avatar: [],
}
