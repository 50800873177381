import React, { useState } from 'react'
import { Button, Input } from '@nextui-org/react'
import authImage from 'assets/svg/auth-footer.svg'
import useAuth from 'hooks/useAuth'
import { styles } from './styles'
import { Icon } from '@iconify/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import toast from 'react-hot-toast'

const defaultValues = {
  email: '',
  password: '',
}

const Login = () => {
  const { signIn, loading } = useAuth()

  const [showPassword, setShowPassword] = useState(false)

  const {
    watch,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const { email, password } = watch()

  const handleLogin = (e) => {
    if (!isValid) return toast.error('Completa los campos correctamente')
    signIn(email, password)
    e.preventDefault()
  }

  return (
    <div className={`${styles.MAIN_CONTAINER} ${loading && 'cursor-progress'}`}>
      <form className={styles.FORM}>
        <p className={styles.TITLE}>Iniciar sesión</p>
        <Input
          color={errors.email ? 'danger' : 'primary'}
          variant="bordered"
          type="email"
          label="Email"
          placeholder="Ingresa tu correo electrónico"
          autoComplete="on"
          {...register('email')}
          isInvalid={errors.email}
          errorMessage={errors.email?.message}
        />
        <div>
          <Input
            color={errors.password ? 'danger' : 'primary'}
            variant="bordered"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            placeholder="Escribe tu contraseña"
            autoComplete="on"
            {...register('password')}
            isInvalid={errors.password}
            errorMessage={errors.password?.message}
            endContent={
              <Icon
                className={styles.ICON}
                onClick={() => setShowPassword(!showPassword)}
                icon={showPassword ? 'line-md:watch-off' : 'line-md:watch'}
              />
            }
          />
          <p className={styles.FORGET}>
            <a href="/#/recovery">¿Olvidaste tu contraseña?</a>
          </p>
        </div>
        <Button
          color="primary"
          type="submit"
          isDisabled={Object.keys(errors).length > 0 || !email || !password}
          isLoading={loading}
          onClick={(e) => handleLogin(e)}
        >
          Ingresar
        </Button>
        <div className={styles.BOTTOM_CONTENT}>
          <p>¿Todavía no tienes una cuenta?</p>
          <a href="/#/register" className={styles.REGISTER}>
            Regístrate aquí
          </a>
          <p className="mt-3 text-xs text-inactive">¿Ya tienes un código de verificación?</p>
          <a href="/#/confirm" className={`text-xs ${styles.REGISTER}`}>
            Ingresar código
          </a>
        </div>
      </form>
      <img src={authImage} alt="auth-footer" className={styles.FOOTER} />
    </div>
  )
}

export default Login
