import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('El formato del correo electrónico no es válido')
      .required('El correo electrónico es obligatorio'),
  })
  .required('Todos los campos son obligatorios')
