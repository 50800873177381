import React from 'react'
import { assetOptions } from 'constants/assets'
import { Card, CardBody } from '@nextui-org/react'
import { cardStyles } from './styles'
import PropTypes from 'prop-types'

const Preview = ({ setValue, setPreview }) => {
  return (
    <div className={cardStyles.CARDS_CONTAINER}>
      {assetOptions.types.map((asset, index) => (
        <Card
          key={index}
          className={cardStyles.CARD}
          isPressable
          onPress={() => {
            setValue('assetType', asset.value)
            setPreview(false)
          }}
        >
          <CardBody className="p-3">
            <img className={cardStyles.IMAGE} src={asset.avatar} alt={asset.label} />
            <div className="text-center">
              <p className={cardStyles.NAME}>{asset.label}</p>
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  )
}

export default Preview

Preview.propTypes = {
  setValue: PropTypes.func,
  setPreview: PropTypes.func,
}
