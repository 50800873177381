export const errors = {
  UserNotConfirmedException: 'El usuario no ha sido confirmado',
  UserNotFoundException: 'El usuario no existe',
  NotAuthorizedException: 'La contraseña es incorrecta',
  UsernameExistsException: 'El usuario ya existe',
  CodeMismatchException: 'El código es incorrecto',
  ExpiredCodeException: 'El código ha expirado',
  InvalidParameterException: 'El código es incorrecto',
  InvalidPasswordException: 'La contraseña es incorrecta',
  LimitExceededException: 'El usuario ha excedido el límite de intentos',
  TooManyRequestsException: 'El usuario ha excedido el límite de intentos',
  default: 'Ha ocurrido un error, por favor intenta de nuevo',
}
