import React, { Fragment } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionItem,
} from '@nextui-org/react'
import { ToS } from 'constants/tos'
import { coolTextShadowDark, coolTextShadowLight } from './style'
import PropTypes from 'prop-types'
import { useTheme } from 'next-themes'

const ModalToS = ({ visible, setVisible }) => {
  const { theme } = useTheme()
  const { sectionList } = ToS
  const paragraphs = (text) => text.split('\n\n')

  const itemClasses = {
    title: 'font-bold text-medium',
    trigger: 'shadow-none data-[hover=true]:bg-opacity-5',
  }

  return (
    <Modal
      isOpen={visible}
      onOpenChange={setVisible}
      className="bg-background px-3 py-6"
      size="5xl"
      placement="center"
      scrollBehavior="inside"
      backdrop="blur"
    >
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="flex flex-col text-center text-primary">
              <p className="text-2xl md:text-3xl">TÉRMINOS Y CONDICIONES GENERALES</p>
              <p className="text-xl md:text-2xl">SERVICIO PARA USUARIOS</p>
              <p className="text-lg md:text-xl">PROPITAL</p>
            </ModalHeader>
            <ModalBody className="flex flex-col py-0 pb-12 space-y-1">
              <Accordion
                selectionMode="multiple"
                defaultExpandedKeys={[0]}
                variant="splitted"
                itemClasses={itemClasses}
                className="bg-background"
              >
                {sectionList.map((section, index) => (
                  <AccordionItem
                    className="bg-background text-primary hover:bg-default-100"
                    key={index}
                    aria-label={`Sección ${index}`}
                    title={section.title}
                  >
                    {paragraphs(section.content).map((paragraph, index) => (
                      <p
                        key={'párrafo' + index}
                        style={theme === 'light' ? coolTextShadowLight : coolTextShadowDark}
                        className="text-pretty"
                      >
                        {paragraph}
                      </p>
                    ))}
                  </AccordionItem>
                ))}
              </Accordion>
            </ModalBody>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalToS.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}

export default ModalToS
