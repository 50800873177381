import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { NextUIProvider } from '@nextui-org/react'
import { ThemeProvider } from 'next-themes'
import { Toaster } from 'react-hot-toast'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { Utils, App as AppHelper } from './helpers'
import store from 'providers/Redux/store'
import awsConfig from './aws-exports'
import './index.css'

const renderApp = () => {
  Amplify.configure(awsConfig)
  const root = ReactDOM.createRoot(document.getElementById('root'))

  // Las páginas de error 500 solo se muestran en producción
  const Fallback = () => AppHelper.isProd() && Utils.redirect('/#/500')

  root.render(
    <React.StrictMode>
      <NextUIProvider>
        <ThemeProvider attribute="class" defaultTheme="light" themes={['light', 'dark']}>
          <Provider store={store}>
            <main className="text-foreground bg-background">
              <ErrorBoundary FallbackComponent={Fallback}>
                <App />
              </ErrorBoundary>
              <Toaster position="bottom-center" />
            </main>
          </Provider>
        </ThemeProvider>
      </NextUIProvider>
    </React.StrictMode>,
  )
}

renderApp()
