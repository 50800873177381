export const inputs = [
  {
    type: 'text',
    name: 'name',
    label: 'Nombre',
    placeholder: 'Ingresa tu nombre',
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Apellido',
    placeholder: 'Ingresa tu apellido',
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Teléfono',
    placeholder: 'Ingresa tu número de teléfono',
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    placeholder: 'Ingresa tu correo electrónico',
  },
  {
    type: 'password',
    name: 'password',
    label: 'Contraseña',
    placeholder: 'Crea una contraseña segura',
  },
  {
    type: 'password',
    name: 'confirmPassword',
    label: 'Confirmar',
    placeholder: 'Confirma tu contraseña',
  },
]
