import React, { useState, useEffect } from 'react'
import { App, UI } from 'components'
import { Avatar, Button, Pagination, Select, SelectItem } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { api } from 'api'
import { styles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { assetOptions } from 'constants/assets'
import { defaultValues } from 'constants/patrimony'
import { hints } from 'constants/hints'
import ModalDelete from './ModalDelete'
import ModalDetails from './ModalDetails'
import ModalEdit from './ModalEdit'
import CountUp from 'react-countup'
import useLayout from 'hooks/useLayout'
import AssetCard from './AssetCard'

const Assets = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { layout } = useLayout()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const cachedAssets = useSelector((state) => state.assets)
  const cachedPatrimony = useSelector((state) => state.patrimony)
  const currency = useSelector((state) => state.currency)

  const [modalDelete, setModalDelete] = useState(false)
  const [modalDetails, setModalDetails] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalPages = Math.ceil(items.length / itemsPerPage)

  // Filters
  const [selectedAssetType, setSelectedAssetType] = useState('all')

  const userCustomer = useSelector((state) => state.user.customer.authenticated.attributes)

  const fetchData = async () => {
    setLoading(true)
    try {
      const { data } = await api.getAllAssets(userCustomer.sub)
      setItems(data)
      dispatch({ type: 'set', assets: data })
      return setLoading(false)
    } catch (error) {
      setItems([])
      setLoading(false)
      return console.error(error)
    }
  }

  const fetchPatrimony = async () => {
    try {
      const { data } = await api.getPatrimony(userCustomer.sub)
      if (typeof data === 'string') {
        return dispatch({ type: 'set', patrimony: defaultValues })
      }
      return dispatch({ type: 'set', patrimony: data })
    } catch (error) {
      return console.error(error)
    }
  }

  useEffect(() => {
    if (cachedAssets.length > 0) {
      return setItems(cachedAssets)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (cachedPatrimony) return
    fetchPatrimony()
  }, [])

  const sumObjectValues = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return 0
    }
    return Object.values(obj).reduce((acc, value) => acc + (Number(value) || 0), 0)
  }

  const handleSelectionChange = (e) => {
    setSelectedAssetType(e.target.value)
  }

  useEffect(() => {
    if (selectedAssetType === 'all') {
      return setItems(cachedAssets)
    }
    const filteredItems = cachedAssets.filter(
      (item) => item.financialData.assetType === selectedAssetType,
    )
    setItems(filteredItems)
  }, [selectedAssetType])

  const renderMd = layout.width > 768

  return (
    <div className={styles.MAIN_CONTAINER}>
      <UI.Header
        renderTitle={
          <div className="flex items-center space-x-1">
            <p className="text-xl font-semibold text-primary">Activos</p>
            <UI.ModalHelper content={hints.assets} />
          </div>
        }
        containerClassName="flex items-center w-full py-4 rounded-lg"
        subtitle={
          <p>
            Total Activos: <span className="text-primary">{currency} </span>
            <CountUp
              className="text-primary"
              end={sumObjectValues(cachedPatrimony?.assetsTotalCurrencies[currency]) ?? 0}
              prefix="$"
              duration={2}
              separator="."
            />
          </p>
        }
        rightContent={
          <div className="w-full">
            <div className="flex items-center justify-end space-x-2">
              <Select
                color="primary"
                variant="underlined"
                label="Tipo de activo"
                className="w-32 md:w-52"
                onChange={handleSelectionChange}
                selectedKeys={[selectedAssetType]}
              >
                <SelectItem
                  key={'all'}
                  listboxProps={{ className: 'bg-background rounded-xl' }}
                  popoverProps={{ className: 'w-full' }}
                  classNames={{
                    value: 'text-primary font-bold ml-1 dark:text-foreground',
                    innerWrapper: 'pb-3.5',
                  }}
                  startContent={
                    renderMd && (
                      <Avatar
                        alt="all"
                        className="w-8 h-8 bg-primary/10"
                        src={assetOptions.types[6].avatar}
                      />
                    )
                  }
                >
                  Mostrar todos
                </SelectItem>
                {assetOptions.types.map((item, index) => (
                  <SelectItem
                    key={item.value}
                    listboxProps={{ className: 'bg-background rounded-xl' }}
                    popoverProps={{ className: 'w-full' }}
                    classNames={{
                      value: 'text-primary font-bold ml-1 dark:text-foreground',
                      innerWrapper: 'pb-3.5',
                    }}
                    startContent={
                      renderMd && (
                        <Avatar
                          alt={item.label}
                          className="w-8 h-8 bg-primary/10"
                          src={item.avatar}
                        />
                      )
                    }
                  >
                    {item.label}
                  </SelectItem>
                ))}
              </Select>
              <Button
                color="primary"
                variant="shadow"
                className={styles.BUTTON_HEADER}
                onClick={() => navigate('/create-assets')}
              >
                <Icon icon="line-md:plus-circle" className="min-w-[20px] min-h-[20px]" />
                {renderMd && 'Agregar'}
              </Button>
            </div>
          </div>
        }
      />
      {loading ? (
        <UI.Content className="py-12">
          <App.Loader />
        </UI.Content>
      ) : (
        <div className={styles.CARDS_CONTAINER}>
          {items.length > 0 ? (
            items
              .slice(startIndex, endIndex)
              .map((item, index) => (
                <AssetCard
                  key={index}
                  item={item}
                  setCurrentItem={setCurrentItem}
                  setModalDetails={setModalDetails}
                  setModalEdit={setModalEdit}
                  setModalDelete={setModalDelete}
                />
              ))
          ) : (
            <UI.Content className="col-span-12">
              <div className={styles.NO_RESULTS}>
                <Icon icon="line-md:alert-circle" className={styles.RESULT_ICON} />
                <p>Aún no posees activos registrados</p>
              </div>
            </UI.Content>
          )}
        </div>
      )}
      {!loading && items.length > itemsPerPage && (
        <div className={styles.PAGINATE}>
          <Pagination
            color="primary"
            total={totalPages}
            page={currentPage}
            onChange={setCurrentPage}
            showControls
            showShadow
          />
        </div>
      )}
      {modalDelete && currentItem && (
        <ModalDelete
          visible={modalDelete}
          setVisible={setModalDelete}
          currentItem={currentItem}
          fetchData={fetchData}
          userId={userCustomer.sub}
        />
      )}
      {modalDetails && currentItem && (
        <ModalDetails
          visible={modalDetails}
          setVisible={setModalDetails}
          currentItem={currentItem}
          fetchData={fetchData}
          userId={userCustomer.sub}
        />
      )}
      {modalEdit && currentItem && (
        <ModalEdit
          visible={modalEdit}
          setVisible={setModalEdit}
          currentItem={currentItem}
          setItems={setItems}
          userId={userCustomer.sub}
        />
      )}
    </div>
  )
}

export default Assets
