export const styles = {
  MAIN_CONTAINER: 'flex flex-col space-y-3 lg:max-w-7xl lg:mx-auto',
  CONTENT: 'flex flex-col py-3 space-y-3 md:max-w-xl md:mx-auto animate-fade',
  STEPPER: 'flex justify-center space-x-6',
  STEP_CONTAINER: 'flex flex-col space-y-3',
  STEP: 'flex items-center justify-center w-8 h-8 rounded-full',
  TITLE: 'py-1 text-lg font-semibold text-center',
  FORM_CONTAINER: 'grid grid-cols-2 gap-2 md:gap-4',
  ACTIONS: 'flex mx-auto space-x-3 transition-all md:w-fit pt-3',
  BUTTON: 'w-full rounded-3xl',
}

export const cardStyles = {
  CARDS_CONTAINER: 'flex flex-wrap gap-3 max-w-4xl justify-center mx-auto',
  CARD: 'border-none shadow-propital dark:shadow-none !rounded-2xl bg-white dark:bg-background',
  IMAGE: 'w-full object-contain h-[160px] border-none shadow-none',
  NAME: 'text-sm font-semibold text-secondary dark:text-foreground',
}
