/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "dd7989ae3db348aeaf64a35d955d8f6c",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "dd7989ae3db348aeaf64a35d955d8f6c",
            "region": "us-west-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "dd7989ae3db348aeaf64a35d955d8f6c",
                "region": "us-west-2"
            }
        }
    },
    "aws_cognito_identity_pool_id": "us-west-2:5df7c303-fbe3-4818-9df7-088a23abd3ed",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_rQI1C5LPz",
    "aws_user_pools_web_client_id": "2t4sip6s3jos72pq8com1m8tku",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL",
        "FAMILY_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
