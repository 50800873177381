export const imageUrls = {
  app: {
    android: 'https://prod-emails-assets.s3.us-west-2.amazonaws.com/public/app/android.png',
    awsStartups:
      'https://prod-emails-assets.s3.us-west-2.amazonaws.com/public/app/aws-startups.png',
    fullLogo: 'https://prod-emails-assets.s3.us-west-2.amazonaws.com/public/app/full-logo.png',
    ios: 'https://prod-emails-assets.s3.us-west-2.amazonaws.com/public/app/ios.png',
    logo: 'https://prod-emails-assets.s3.us-west-2.amazonaws.com/public/app/logo.png',
  },
}
