import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('El formato del correo electrónico no es válido')
      .required('El correo electrónico es obligatorio'),
    password: yup
      .string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .max(32, 'La contraseña no debe tener más de 32 caracteres')
      .required('La contraseña es obligatoria'),
  })
  .required('Todos los campos son obligatorios')
