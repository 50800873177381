import React from 'react'
import { styles } from './styles'
import { Card, CardBody } from '@nextui-org/react'
import { Utils } from 'helpers/index'
import PropTypes from 'prop-types'

const OpportunityCard = ({ item, setCurrentItem, setModalDetails }) => {
  const location = item.address?.split(',')[1]
  const detail = `${location} • ${item.typology?.replaceAll('|', '')} • ${item.surface}`
  return (
    <Card
      className={styles.CARD}
      isPressable
      onPress={() => {
        setCurrentItem(item)
        setModalDetails(true)
      }}
    >
      <CardBody className="relative flex flex-col p-3 text-center">
        {/* <div className="absolute top-0 right-0 p-2 mt-3 mr-3 rounded-full text-primary bg-primary/20">
          <Icon icon="ri:heart-add-line" className="w-4 h-4" />
        </div> */}
        <img className={styles.IMAGE} alt={item.nameProject} src={item.avatar} />
        <p className={styles.NAME}>{item.nameProject}</p>
        <p className={styles.TEXT_CONTAINER}>
          <span className="mr-1">{item.priceCurrency}</span>
          {item.currencyAsset === 'UF' ? 'UF ' : '$'}
          {Utils.formatRoundUF(item.assetPrice)}
        </p>
        <div className="mb-1">
          <p className={`${styles.DETAILS} ${item.assetType === 'Fraccionario' && 'invisible'}`}>
            {detail}
          </p>
        </div>
        <div
          className={`flex justify-evenly mt-auto rounded-3xl text-sm bg-[#E8EDF3] font-semibold py-1 px-2`}
        >
          <p className="text-primary">{item.capRate}</p>
          <p className="text-profit">${Utils.formatRoundPrice(item.rentalPrice)}</p>
          <p className="text-capital">{item.annualCapitalGain}</p>
        </div>
      </CardBody>
    </Card>
  )
}

OpportunityCard.propTypes = {
  item: PropTypes.object,
  setCurrentItem: PropTypes.func,
  setModalDetails: PropTypes.func,
}

export default OpportunityCard
