import React from 'react'
import { renderSection } from './controller'
import PropTypes from 'prop-types'

const Finance = ({ user }) => {
  const array = [
    {
      title: 'Lugar de trabajo',
      value: user.employment_place ?? 'Sin especificar',
      icon: 'carbon:building',
    },
    {
      title: 'Contrato',
      value: user.contract_type ?? 'Sin especificar',
      icon: 'clarity:contract-line',
    },
    {
      title: 'Antigüedad',
      value: user.seniority ?? 'Sin especificar',
      icon: 'icon-park-outline:time',
    },
    {
      title: 'Tipo de cuenta',
      value: user.account_type ?? 'Sin especificar',
      icon: 'carbon:user-certification',
    },
    {
      title: 'Banco',
      value: user.bank ?? 'Sin especificar',
      icon: 'ph:bank-light',
    },
    {
      title: 'Número de cuenta',
      value: user.bank_account_number ?? 'Sin especificar',
      icon: 'solar:card-2-outline',
    },
    {
      title: 'Ingresos líquidos',
      value: user.net_income ?? 'Sin especificar',
      icon: 'iconoir:graph-up',
    },
    {
      title: 'Ingresos adicionales',
      value: user.additional_income ?? 'Sin especificar',
      icon: 'ic:round-auto-graph',
    },
    {
      title: 'Capacidad de ahorro',
      value: user.savings_capacity ?? 'Sin especificar',
      icon: 'fluent:savings-16-regular',
    },
    {
      title: 'Ahorros',
      value: user.savings ?? 'Sin especificar',
      icon: 'la:coins',
    },
  ]
  return (
    <div className="flex flex-col space-y-2">
      {array.map((item) => renderSection(item.title, item.value, item.icon))}
    </div>
  )
}

Finance.propTypes = {
  user: PropTypes.object.isRequired,
}

export default Finance
