export const avatars = {
  'Real Estate': 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/departamento.png',
  Fraccionario: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/fraccionario.png',
  Acciones: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/acciones.png',
  'Fondos mutuos':
    'https://prod-propital-avatares.s3.us-west-2.amazonaws.com/activos/fondomutuo.png',
  'Depósito a plazo':
    'https://prod-propital-avatares.s3.us-west-2.amazonaws.com/activos/depositoaplazo.png',
  Otros: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/otros.png',
  // SubTypes
  Departamento: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/departamento.png',
  Estacionamiento:
    'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/estacionamiento.png',
  Bodega: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/bodega.png',
  Oficina: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/oficina.png',
  Casa: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/casa.png',
  Local: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/local.png',
  Terreno: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/parcela.png',
  Parcela: 'https://propital-avatares.s3.us-west-2.amazonaws.com/activos/parcela.png',
  Vehiculo: 'https://prod-propital-avatares.s3.us-west-2.amazonaws.com/activos/vehículo_icono.png',
}

export const assetOptions = {
  types: [
    { label: 'Real Estate', value: 'Real Estate', avatar: avatars['Real Estate'] },
    { label: 'Fraccionario', value: 'Fraccionario', avatar: avatars.Fraccionario },
    { label: 'Acciones', value: 'Acciones', avatar: avatars.Acciones },
    { label: 'Fondos mutuos', value: 'Fondos mutuos', avatar: avatars['Fondos mutuos'] },
    { label: 'Depósito a plazo', value: 'Depósito a plazo', avatar: avatars['Depósito a plazo'] },
    { label: 'Vehiculo', value: 'Vehiculo', avatar: avatars.Vehiculo },
    { label: 'Otros', value: 'Otros', avatar: avatars.Otros },
  ],
  subTypes: {
    'Real Estate': [
      { label: 'Departamento', value: 'Departamento', avatar: avatars.Departamento },
      { label: 'Estacionamiento', value: 'Estacionamiento', avatar: avatars.Estacionamiento },
      { label: 'Bodega', value: 'Bodega', avatar: avatars.Bodega },
      { label: 'Oficina', value: 'Oficina', avatar: avatars.Oficina },
      { label: 'Casa', value: 'Casa', avatar: avatars.Casa },
      { label: 'Local', value: 'Local', avatar: avatars.Local },
      { label: 'Otros', value: 'Otros', avatar: avatars.Otros },
      { label: 'Terreno', value: 'Terreno', avatar: avatars.Terreno },
      { label: 'Parcela', value: 'Parcela', avatar: avatars.Parcela },
    ],
    Fraccionario: [],
    Acciones: [],
    'Fondos mutuos': [],
    'Depósito a plazo': [],
    Otros: [],
  },
  currencies: [
    { label: 'UF', value: 'CLF' },
    { label: 'USD', value: 'USD' },
    { label: 'CLP', value: 'CLP' },
  ],
  frequency: [
    { label: 'Anual', value: 'Anual' },
    { label: 'Mensual', value: 'Mensual' },
    { label: 'Semanal', value: 'Semanal' },
    { label: 'Diario', value: 'Diario' },
  ],
  depositType: [
    { label: 'Fijo', value: 'Fijo' },
    { label: 'Renovable', value: 'Renovable' },
  ],
  bathrooms: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ],
  bedrooms: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ],
}

export const assetFields = {
  'Real Estate': ['assetType', 'assetSubType', 'assetPrice', 'currencyAsset'],
  Fraccionario: [
    'assetType',
    'currencyAsset',
    'bricksNumber',
    'brickPrice',
    'description',
    'nameProject',
  ],
  Acciones: [
    'assetType',
    'currencyAsset',
    'sharesNumber',
    'sharePrice',
    'description',
    'nameProject',
  ],
  'Fondos mutuos': [
    'assetType',
    'assetPrice',
    'currencyAsset',
    'description',
    'nameProject',
    'typeMutualFund',
  ],
  'Depósito a plazo': [
    'assetType',
    'assetPrice',
    'currencyAsset',
    'bank',
    'depositType',
    'finishDate',
    'startDate',
  ],
  Vehiculo: ['age', 'assetType', 'assetPrice', 'currencyAsset', 'description', 'nameProject'],
  Otros: ['assetType', 'assetPrice', 'currencyAsset', 'description', 'nameProject'],
  default: ['assetType', 'assetPrice', 'currencyAsset'],
  // SubTypes
  defaultSubType: [
    'assetType',
    'assetPrice',
    'currencyAsset',
    'assetSubType',
    'description',
    'nameProject',
  ],
  withRental: [
    'assetType',
    'assetPrice',
    'currencyAsset',
    'assetSubType',
    'description',
    'bathrooms',
    'bedrooms',
    'nameProject',
    'rentalPrice',
    'annualVacancy',
    'annualPropTax',
  ],
}

export const assetActions = {
  1: {
    edit: true,
    delete: true,
  },
  2: {
    edit: false,
    delete: false,
  },
}
