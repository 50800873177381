import axios from 'axios'

// Obtiene la IP del usuario, se debe enviar un useState
export const getIp = async (userIp, setUserIp) => {
  if (userIp) return
  try {
    const { data } = await axios.get('https://api.ipify.org/?format=json')
    return setUserIp(data.ip)
  } catch (error) {
    console.error(error)
  }
}

// Obtiene la IP del usuario
export const getAuxIp = async () => {
  const userIp = localStorage.getItem('user_ip')
  if (userIp) return userIp
  try {
    const { data } = await axios.get('https://api.ipify.org/?format=json')
    const { ip } = data
    localStorage.setItem('user_ip', ip)
    return ip
  } catch (error) {
    console.error(error)
  }
}

// Determina si el ambiente es de desarrollo o producción
export const isProd = () => {
  // Si es producción, retornará true
  return !(window.location.href.includes('localhost') || window.location.href.includes('amplify'))
}
