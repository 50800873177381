const useMetaPixel = () => {
  // Verificar si existe el objeto window y si existe la función fbq de MetaPixel
  const isAvailable = typeof window !== 'undefined' && typeof window.fbq === 'function'

  const pageView = () => {
    if (isAvailable) {
      window.fbq('track', 'PageView')
    }
  }

  const event = (name, options = {}) => {
    if (isAvailable) {
      window.fbq('track', name, options)
    }
  }

  return { pageView, event }
}

export default useMetaPixel
