import { Chip } from '@nextui-org/react'
import { Utils } from 'helpers/index'
import PropTypes from 'prop-types'
import React from 'react'

const CardPortfolio = ({ project }) => {
  const { id, image, name, amount, date, totalBricks, status } = project
  const statusColorMap = {
    Aprobada: 'success',
    Abonada: 'success',
    Pendiente: 'warning',
    Cancelada: 'danger',
    'En ejecución': 'primary',
    Finalizada: 'secondary',
    Pausado: 'warning',
  }

  return (
    <article
      key={id}
      className="flex flex-col justify-around w-full h-64 p-4 md:flex-row md:justify-normal md:space-x-6 rounded-3xl"
    >
      <img
        src={image}
        alt={name}
        className="object-cover w-full max-h-14 md:w-4/12 md:h-full md:max-h-full rounded-xl"
      />
      <div className="flex flex-col items-center md:items-start md:w-8/12">
        <span className="py-2 text-lg font-semibold text-primary md:py-0 dark:text-foreground">
          {name}
        </span>
        <div className="flex flex-col mb-3 space-y-2 md:mb-0 md:mt-3 md:space-y-3 text-secondary dark:text-primary">
          <p>
            Mi inversión:{' '}
            <b className="dark:text-foreground">${Utils.formatRoundPrice(amount)} CLP</b>
          </p>
          <p>
            Bricks solicitados: <b className="dark:text-foreground">{totalBricks}</b>
          </p>
          <p>
            Fecha: <b className="dark:text-foreground">{Utils.formatDatesWithHour(date)}</b>
          </p>
        </div>
        <div className="flex items-center content-center w-full space-x-3 justify-evenly md:content-normal md:justify-end md:mb-0 md:mt-auto md:ml-auto">
          <Chip className="min-h-[40px] capitalize" color={statusColorMap[status]} variant="flat">
            {status}
          </Chip>
          {/* {status === 'Aprobada' && (
            <Button
              color={
                status === 'Pendiente' || status === 'Cancelada' || status === 'Pausado'
                  ? 'background'
                  : 'primary'
              }
              variant="shadow"
              disabled={status === 'Pendiente' || status === 'Cancelada' || status === 'Pausado'}
              className="rounded-3xl"
              startContent={
                <Icon
                  className="min-h-[24px] min-w-[24px] animate-jump-in animate-duration-1000"
                  icon="ep:sell"
                />
              }
            >
              Vender
            </Button>
          )} */}
        </div>
      </div>
    </article>
  )
}

CardPortfolio.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    totalBricks: PropTypes.number.isRequired,
  }).isRequired,
}

export default CardPortfolio
