import React from 'react'
import { Button, Skeleton, Tab, Tabs } from '@nextui-org/react'
import { UI } from 'components/index'
import { styles } from './styles'
import { handleList } from './constants'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'
import Actions from './Actions'

const List = ({ loading, item, currency, navigate, selectedList, setSelectedList }) => {
  const haveAssets = Object.keys(item.assetsTotalCurrencies[currency]).length > 0
  const havePassives = Object.keys(item.passivesTotalCurrencies[currency]).length > 0

  const formatArray = (arrayObj) => {
    return Object.entries(arrayObj).map(([name, value]) => ({ name, value }))
  }

  let tabs = [
    {
      id: 'all',
      label: 'Mostrar todo',
      content: (
        <Skeleton className="rounded-lg" isLoaded={!loading}>
          {handleList(
            formatArray({
              ...item.assetsTotalCurrencies[currency],
              ...item.passivesTotalCurrencies[currency],
            }),
            'all',
            currency,
          )}
          {!haveAssets && !havePassives && (
            <Actions selected={selectedList} navigate={navigate} showAssets showPassives />
          )}
        </Skeleton>
      ),
    },
    {
      id: 'assets',
      label: 'Activos',
      content: (
        <Skeleton className="rounded-lg" isLoaded={!loading}>
          {handleList(formatArray(item.assetsTotalCurrencies[currency]), 'assets', currency)}
          {!haveAssets && <Actions selected={selectedList} navigate={navigate} showAssets />}
        </Skeleton>
      ),
    },
    {
      id: 'passives',
      label: 'Pasivos',
      content: (
        <Skeleton className="rounded-lg" isLoaded={!loading}>
          {handleList(formatArray(item.passivesTotalCurrencies[currency]), 'passives', currency)}
          {!havePassives && <Actions selected={selectedList} navigate={navigate} showPassives />}
        </Skeleton>
      ),
    },
  ]

  return (
    <UI.Content className={`${styles.LIST} step-5`}>
      <p className="mb-2 text-xl font-semibold text-primary">Resumen de tu cuenta</p>
      <div className={styles.TABS_CONTAINER}>
        <Tabs
          selectedKey={selectedList}
          onSelectionChange={setSelectedList}
          color="primary"
          radius="lg"
          variant="solid"
          items={tabs}
          aria-label="types"
          classNames={{
            panel: `overflow-y-auto h-[40vh] max-h-[400px]`,
            tabList: 'rounded-3xl bg-primary/10',
            cursor: 'rounded-3xl',
            tab: 'rounded-3xl',
          }}
        >
          {(item) => (
            <Tab key={item.id} title={item.label}>
              {item.content}
            </Tab>
          )}
        </Tabs>
        <div className={styles.TABS_ACTIONS}>
          <Button
            onClick={() =>
              selectedList === 'passives' ? navigate('/passives') : navigate('/assets')
            }
            isIconOnly
            color="primary"
            variant="light"
            size="sm"
            className="ml-auto rounded-full"
          >
            <Icon icon="line-md:list" className={styles.ICON} />
          </Button>
          <Button
            onClick={() =>
              selectedList === 'passives'
                ? navigate('/create-passives')
                : navigate('/create-assets')
            }
            isIconOnly
            color="primary"
            variant="light"
            size="sm"
            className="rounded-full"
          >
            <Icon icon="line-md:plus-circle" className={styles.ICON} />
          </Button>
        </div>
      </div>
    </UI.Content>
  )
}

List.propTypes = {
  loading: PropTypes.bool,
  item: PropTypes.object,
  currency: PropTypes.string,
  navigate: PropTypes.func,
  selectedList: PropTypes.string,
  setSelectedList: PropTypes.func,
}

export default List
