import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import authRoutes from 'routes/auth'
import contentRoutes from 'routes/content'
import publicRoutes from 'routes/public'

const ValidateRoutes = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isValidRoute = (rutaDinamica, rutaEspecifica) => {
    // Se validan rutas dinámicas
    const rutaRegex = new RegExp(rutaDinamica.replace(/:[^/]+/g, '([^/]+)') + '$')
    return rutaRegex.test(rutaEspecifica)
  }

  const excludeRoutes = ['/404', '/500', ...publicRoutes.map((ruta) => ruta.path)]

  useEffect(() => {
    if (excludeRoutes.includes(pathname)) return
    const routes = [...authRoutes, ...contentRoutes]
    // Si el pathname no es válido, entonces redirigir a 404
    if (!routes.some((ruta) => isValidRoute(ruta.path, pathname))) {
      return navigate('/404')
    }
  }, [pathname])

  return children
}

export default ValidateRoutes
