import { dashboard, profile } from './steps'

export const defaultTours = {
  dashboard: {
    completed: false,
  },
  profile: {
    completed: false,
  },
}

export const tours = {
  dashboard,
  profile,
}
