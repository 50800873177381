import { frac } from 'api/config'

const getAllProjects = () => {
  return frac.get(`/publication/read`)
}

const getProject = (id) => {
  return frac.get(`/publication/detallePublicacion/${id}`)
}

const createUser = (user) => {
  return frac.post(`/user/create`, user)
}

const userLogin = (email, password) => {
  return frac.post(`/auth/login`, { user: email, password })
}

const createInvestment = (investment) => {
  return frac.post(`/investment/createInvestment`, investment)
}

const updateUser = (user) => {
  return frac.post(`/user/update`, user)
}

const changePassword = (email, password) => {
  return frac.post(`/user/ForceUpdatePassword`, { user: email, password })
}

export {
  getAllProjects,
  getProject,
  createUser,
  userLogin,
  createInvestment,
  updateUser,
  changePassword,
}
