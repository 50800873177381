import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { handleMarker } from './constants'
import 'leaflet/dist/leaflet.css'
import './styles.css'

// El TileLayer es el mapa que se muestra en el fondo
const tileLayerUrl = 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token='

const JAWG_API_KEY = process.env.REACT_APP_JAWG_API_KEY

// TODO: Add a draggable marker functionality
const Map = ({
  center = { lat: -33.4489, lng: -70.6693 },
  markers = [{ lat: -33.4489, lng: -70.6693 }],
  height = '600px',
  popUpContent = <div>Propital</div>,
  scrollWheelZoom = true,
  currentPosition = null,
  zoom = 14,
}) => {
  const mapRef = useRef(null)

  const [centerPosition, setCenterPosition] = useState(center)

  const centerOnPosition = () => {
    if (currentPosition) {
      setCenterPosition(currentPosition)
      mapRef?.current?.setView(currentPosition)
    }
  }

  useEffect(() => {
    centerOnPosition()
  }, [currentPosition])

  const style = {
    height,
    width: '100%',
    borderRadius: '16px',
    position: 'relative',
  }

  return (
    <MapContainer
      ref={mapRef}
      center={centerPosition}
      scrollWheelZoom={scrollWheelZoom}
      style={style}
      zoom={zoom}
    >
      <TileLayer url={`${tileLayerUrl}${JAWG_API_KEY}`} />
      {markers &&
        markers.map((marker, index) => (
          <Marker icon={handleMarker()} key={index} position={[marker.lat, marker.lng]}>
            <Popup>{popUpContent}</Popup>
          </Marker>
        ))}
    </MapContainer>
  )
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ),
  height: PropTypes.string,
  popUpContent: PropTypes.node,
  scrollWheelZoom: PropTypes.bool,
  currentPosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
}

export default Map
