import { lazy } from 'react'

const Confirm = lazy(() => import('pages/Confirm'))
const Login = lazy(() => import('pages/Login'))
const Register = lazy(() => import('pages/Register'))
const Recovery = lazy(() => import('pages/Recovery'))
const RecoverySubmit = lazy(() => import('pages/RecoverySubmit'))
const NewPassword = lazy(() => import('pages/NewPassword'))

const authRoutes = [
  { path: '/login', name: 'Iniciar sesión', component: Login },
  { path: '/register', name: 'Registra tu cuenta', component: Register },
  { path: '/confirm', name: 'Confirma tu cuenta', component: Confirm },
  { path: '/recovery', name: 'Recuperar Contraseña', component: Recovery },
  { path: '/recoverySubmit', name: 'Cambiar Contraseña', component: RecoverySubmit },
  { path: '/newPassword', name: 'Nueva Contraseña', component: NewPassword },
]

export default authRoutes
