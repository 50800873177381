import React, { Fragment } from 'react'
import { Button } from '@nextui-org/react'
import { steps } from './constants'
import { styles } from './styles'
import PropTypes from 'prop-types'

const Actions = ({ step, setStep, handleGoBack, handleSubmit, isDisabled, loading }) => {
  return (
    <div className={styles.ACTIONS}>
      {step > 1 && (
        <Button
          className={styles.BUTTON}
          color="primary"
          variant="bordered"
          onClick={() => setStep(step - 1)}
        >
          Atrás
        </Button>
      )}
      {step < steps.length && (
        <Button
          className={styles.BUTTON}
          color="primary"
          variant="shadow"
          onClick={() => setStep(step + 1)}
        >
          Siguiente
        </Button>
      )}
      {step === steps.length && (
        <Fragment>
          <Button
            className={styles.BUTTON}
            color="primary"
            variant="bordered"
            onClick={handleGoBack}
            isLoading={loading}
          >
            Volver atrás
          </Button>
          <Button
            className={styles.BUTTON}
            color="primary"
            variant="shadow"
            isDisabled={isDisabled}
            onClick={handleSubmit}
            isLoading={loading}
          >
            Crear activo
          </Button>
        </Fragment>
      )}
    </div>
  )
}

Actions.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  handleGoBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export default Actions
