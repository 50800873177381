import React, { Fragment, useEffect, useState } from 'react'
import { App, UI } from 'components'
import { Chip, Pagination } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { api } from 'api'
import { styles } from './styles'
import { useSelector } from 'react-redux'
import ModalDetails from './ModalDetails'
import OpportunityCard from './OpportunityCard'

const Opportunities = () => {
  const [filter, setFilter] = useState('Todos')
  const [items, setItems] = useState([])
  const [cachedItems, setCachedItems] = useState([])
  const [loading, setLoading] = useState(false)

  const [modalDetails, setModalDetails] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalPages = Math.ceil(items.length / itemsPerPage)

  const userCustomer = useSelector((state) => state.user.customer.authenticated.attributes)

  const fetchData = async () => {
    setLoading(true)
    try {
      const { data } = await api.getOpportunities()
      setItems(data)
      setCachedItems(data)
      return setLoading(false)
    } catch (error) {
      setItems([])
      setCachedItems([])
      setLoading(false)
      return console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  const filters = ['Todos', 'Fraccionario', 'Real Estate-Seminuevo', 'Nuevos']

  const handleFilter = (filterName) => {
    setCurrentPage(1)
    setFilter(filterName)
    if (filterName === 'Todos') {
      return setItems(cachedItems)
    }
    if (filterName === 'Nuevos') {
      const filteredItems = cachedItems.filter(
        (item) => item.assetType !== 'Fraccionario' && item.assetType !== 'Seminuevo',
      )
      return setItems(filteredItems)
    }
    const filteredItems = cachedItems.filter((item) => item.assetType === filterName)
    return setItems(filteredItems)
  }

  const words = {
    'Real Estate-Seminuevo': 'Seminuevo',
    'Real Estate-Proyecto': 'Proyecto',
  }

  return (
    <div className={styles.MAIN_CONTAINER}>
      <p className="my-3 text-3xl font-semibold text-center text-primary">Oportunidades</p>
      {loading ? (
        <div className="py-12">
          <App.Loader />
        </div>
      ) : (
        <Fragment>
          {items.length > 0 && (
            <div className="flex flex-wrap justify-center pb-3 space-x-3">
              {filters.map((item, index) => (
                <Chip
                  key={index}
                  className={`${filter !== item && 'text-inactive'} cursor-pointer`}
                  variant={filter === item ? 'shadow' : 'light'}
                  onClick={() => {
                    handleFilter(item)
                  }}
                  color={filter === item ? 'primary' : 'default'}
                  size="lg"
                >
                  {words[item] ?? item}
                </Chip>
              ))}
            </div>
          )}
          <div className={styles.CARDS_CONTAINER}>
            {items.length > 0 ? (
              items
                .slice(startIndex, endIndex)
                .map((item, index) => (
                  <OpportunityCard
                    key={index}
                    item={item}
                    setCurrentItem={setCurrentItem}
                    setModalDetails={setModalDetails}
                  />
                ))
            ) : (
              <UI.Content className="col-span-12">
                <div className={styles.NO_RESULTS}>
                  <Icon icon="line-md:alert-circle" className={styles.RESULT_ICON} />
                  <p>No se han encontrado oportunidades.</p>
                </div>
              </UI.Content>
            )}
          </div>
        </Fragment>
      )}
      {!loading && items.length > itemsPerPage && (
        <div className={styles.PAGINATE}>
          <Pagination
            color="primary"
            total={totalPages}
            page={currentPage}
            onChange={setCurrentPage}
            showControls
            showShadow
          />
        </div>
      )}
      {modalDetails && currentItem && (
        <ModalDetails
          visible={modalDetails}
          setVisible={setModalDetails}
          currentItem={currentItem}
          userCustomer={userCustomer}
        />
      )}
    </div>
  )
}

export default Opportunities
