import React, { Fragment, useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { api } from 'api'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

const ModalDelete = ({ visible, setVisible, currentItem, fetchData, userId }) => {
  const [loading, setLoading] = useState(false)

  const passiveId = currentItem.uuid

  const handleDelete = async () => {
    setLoading(true)
    try {
      await api.deletePassive(passiveId)
      toast.success('Pasivo eliminado con éxito!')
      setLoading(false)
      fetchData()
      return setVisible(false)
    } catch (error) {
      setLoading(false)
      return console.error(error)
    }
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="text-primary">Eliminar Pasivo</ModalHeader>
            <ModalBody>
              <p>¿Estás seguro que deseas eliminar este pasivo?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancelar
              </Button>
              <Button color="primary" onClick={handleDelete} isLoading={loading}>
                Eliminar
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalDelete.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentItem: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export default ModalDelete
