import React from 'react'
import { styles } from './styles'
import { UI } from 'components/index'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'

const Header = ({ user, navigate }) => {
  return (
    <UI.Content className={`${styles.CONTENT} w-full step-1`}>
      <p className="text-xl font-semibold text-primary">¡Hola {user?.name?.trim()}!</p>
      <div className="flex flex-col py-3 space-y-2 text-secondary dark:text-foreground">
        <div className="flex flex-row items-center w-full space-x-2">
          <Icon
            className="min-h-[1.3rem] min-w-[1.3rem] animate-jump-in animate-duration-1000 text-primary"
            icon="ic:round-auto-graph"
          />{' '}
          <p>
            Completa tu{' '}
            <b className="cursor-pointer" onClick={() => navigate('/profile')}>
              perfil
            </b>{' '}
            para acceder a todos los{' '}
            <b className="cursor-pointer" onClick={() => navigate('/profile')}>
              beneficios
            </b>{' '}
            de Propital
          </p>
        </div>
        <div className="flex flex-row items-center w-full space-x-2">
          <Icon
            className="min-h-[1.3rem] min-w-[1.3rem] animate-jump-in animate-duration-1000 text-primary"
            icon="heroicons:rocket-launch"
          />{' '}
          <p>
            ¡Echa un vistazo a las{' '}
            <b className="cursor-pointer" onClick={() => navigate('/opportunities')}>
              oportunidades
            </b>{' '}
            de inversión que tenemos para ti!{' '}
          </p>
        </div>
        <div className="flex flex-row items-center w-full space-x-2">
          <Icon
            className="min-h-[1.3rem] min-w-[1.3rem] animate-jump-in animate-duration-1000 text-primary"
            icon="fluent:brain-circuit-20-regular"
          />{' '}
          <p>
            Aprende con{' '}
            <b className="cursor-pointer" onClick={() => navigate('/academy')}>
              Propital Academy
            </b>{' '}
            cómo administrar tus finanzas e inversiones
          </p>
        </div>
        <div className="flex flex-row items-center w-full space-x-2">
          <Icon
            className="min-h-[1.3rem] min-w-[1.3rem] animate-jump-in animate-duration-1000 text-primary"
            icon="streamline:subscription-cashflow"
          />{' '}
          <p>
            Conecta con <b>Propital Income</b> para tener una visión completa de tus <b>ingresos</b>
          </p>
        </div>
      </div>
    </UI.Content>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  navigate: PropTypes.func,
}

export default Header
