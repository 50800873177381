import React from 'react'
import { Icon } from '@iconify/react'
import { riskResults } from './constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const renderSection = (title, value, icon) => {
  return (
    <div key={`${title}${value}`} className="flex flex-col text-sm text-secondary">
      <div className="flex items-center space-x-2">
        <Icon icon={icon} className="w-5 h-5 text-primary" />
        <p className="dark:text-foreground">
          {title}: <span className="text-inactive">{value}</span>
        </p>
      </div>
    </div>
  )
}

export const setFormValues = (user, setValue) => {
  if (user) {
    dayjs.extend(utc)
    const fieldsToSet = [
      'first_name',
      'last_name',
      'age',
      'birth_date',
      'identity_document',
      'marital_status',
      'profession',
      'address',
      'city',
      'country',
      'phone',
      'email',
      // Finance
      'employment_place',
      'contract_type',
      'seniority',
      'account_type',
      'bank',
      'bank_account_number',
      'net_income',
      'additional_income',
      'savings_capacity',
      'savings',
      // Preferences
      'interest_area',
      'where_you_invest_today',
      'risk_profile',
    ]
    fieldsToSet.forEach((field) => {
      // Los input type date solo aceptan el formato YYYY-MM-DD en su defaultValue
      if (field === 'birth_date') {
        // Se agrega el .utc() para que no hayan fechas incorrectas según la zona horaria
        const formatDate = dayjs(user.birth_date).utc().format('YYYY-MM-DD')
        return setValue(field, formatDate || '')
      }
      setValue(field, user[field] || '')
    })
  }
}

export const calculateProgress = (user = {}) => {
  const excludedProperties = [
    'favorite_projects',
    'interest_area',
    'notifications',
    'onboarding',
    'settings',
  ]

  const totalProperties = Object.keys(user).filter(
    (key) => !excludedProperties.includes(key),
  ).length

  const completedProperties = Object.values(user).filter(
    (value) => value !== null && typeof value !== 'object',
  ).length

  const progress = Math.round((completedProperties / totalProperties) * 100)

  return {
    total: totalProperties,
    completed: completedProperties,
    progress,
  }
}

export const calculateRisk = (counter) => {
  const totalValue = Object.values(counter).reduce((acc, curr) => acc + curr, 0)
  let riskCategory

  if (totalValue <= 9) {
    riskCategory = 'Conservador'
  } else if (totalValue > 9 && totalValue <= 27) {
    riskCategory = 'Moderado'
  } else {
    riskCategory = 'Agresivo'
  }

  const profileInfo = riskResults.find((profile) => profile.perfil === riskCategory)

  return profileInfo
}
