import React, { Fragment, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
} from '@nextui-org/react'
import { apiSlackBot } from 'api/index'
import { Icon } from '@iconify/react'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'

const ModalContact = ({ visible, setVisible }) => {
  const user = useSelector((state) => state.user?.customer?.authenticated?.attributes ?? null)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSlack = async () => {
    setLoading(true)
    const payload = {
      title: `*Cliente - Servicio Contable*`,
      channel: 'github-logs',
      ref: 'App Propital - Web',
      content: [
        {
          text: `*Cliente*: ${user?.name} ${user?.family_name} (${user?.email})`,
        },
        {
          text: `*Mensaje*: ${message}`,
        },
        {
          text: `:info: El usuario se encuentra interesado en los servicios contables`,
        },
      ],
    }
    try {
      await apiSlackBot.sendMessage(payload)
      setLoading(false)
      toast.success('Hemos recibido tu solicitud!')
      return setVisible(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      return toast.error('Ocurrió un error al enviar tu solicitud, intenta nuevamente.')
    }
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="mx-auto text-xl text-primary">Servicios Contables</ModalHeader>
            <ModalBody className="flex flex-col py-0 space-y-1">
              <p className="text-secondary dark:text-foreground">
                ¿Sabías que en <b>Propital</b> también ofrecemos <b>servicios contables</b>? Si
                estás interesado en conocer más sobre ellos, déjanos tu información y nos pondremos
                en <b>contacto</b> contigo ✅
              </p>
              <Textarea
                label="Mensaje"
                color="primary"
                variant="bordered"
                placeholder="¿Podrías contarnos un poco más sobre tus necesidades?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <p className="text-secondary dark:text-foreground">
                Te enviaremos un correo con toda la información que necesitas para comenzar a
                trabajar con nosotros, <b>¡nos encantaría ayudarte!</b> 🚀
              </p>
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <Button
                color="primary"
                variant="bordered"
                className="rounded-3xl"
                isLoading={loading}
                onClick={() => setVisible(false)}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="shadow"
                className="rounded-3xl"
                endContent={<Icon icon="line-md:bell-loop" className="w-[20px] h-[20px]" />}
                isLoading={loading}
                onClick={() => handleSlack()}
              >
                <b>Solicitar</b>
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalContact.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}

export default ModalContact
