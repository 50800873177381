import React, { Fragment, useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'

const ModalHelper = ({ content, actions }) => {
  const [visible, setVisible] = useState(false)
  return (
    <Fragment>
      {visible && (
        <Modal isOpen={visible} onOpenChange={setVisible} className="max-w-sm bg-background">
          <ModalContent>
            {() => (
              <Fragment>
                <ModalHeader className="py-4 mx-auto text-xl font-semibold text-secondary dark:text-primary">
                  {content.title}
                </ModalHeader>
                <ModalBody className="flex flex-col pt-0 pb-4">
                  <div className="text-secondary dark:text-foreground">{content.description}</div>
                </ModalBody>
                {actions && <ModalFooter className="justify-center pt-0">{actions}</ModalFooter>}
              </Fragment>
            )}
          </ModalContent>
        </Modal>
      )}
      <Icon
        icon="eva:info-fill"
        onClick={() => setVisible(true)}
        className="w-4 h-4 transition-all cursor-pointer hover:scale-125 text-hint hover:text-primary/75"
      />
    </Fragment>
  )
}

ModalHelper.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
  }).isRequired,
  actions: PropTypes.node,
}

export default ModalHelper
