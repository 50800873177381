import React from 'react'
import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/react'
import PropTypes from 'prop-types'

const Actions = ({ selected, navigate, showAssets, showPassives }) => {
  return (
    <div className="flex flex-col justify-center py-6 mt-3 space-y-3">
      <div className="flex flex-row items-center justify-center w-full space-x-2">
        <Icon className="min-h-[1.5rem] min-w-[1.5rem] text-primary" icon="line-md:alert-circle" />
        <p className="text-secondary dark:text-foreground">Tus movimientos se verán aquí</p>
      </div>
      <div className="flex flex-row pt-3 mx-auto space-x-6 w-fit">
        {showAssets && (
          <div className="flex flex-col mx-auto space-y-3 text-center w-44">
            <p className="text-secondary dark:text-foreground">Agrega un activo</p>
            <Button
              onClick={() =>
                selected === 'passives' ? navigate('/passives') : navigate('/assets')
              }
              color="primary"
              size="sm"
              variant="shadow"
            >
              Agregar
            </Button>
          </div>
        )}
        {showPassives && (
          <div className="flex flex-col mx-auto space-y-3 text-center w-44">
            <p className="text-secondary dark:text-foreground">Agrega un pasivo</p>
            <Button
              onClick={() =>
                selected === 'passives' ? navigate('/passives') : navigate('/assets')
              }
              color="primary"
              size="sm"
              variant="shadow"
            >
              Agregar
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

Actions.propTypes = {
  selected: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  showAssets: PropTypes.bool,
  showPassives: PropTypes.bool,
}

export default Actions
