import { Auth } from 'aws-amplify'
import { errors } from './constants'
import { Utils } from 'helpers'
import { apiFrac } from 'api/index'
import { API_FRAC } from 'api/config'
import toast from 'react-hot-toast'
import axios from 'axios'

export const removeCredentials = (dispatch) => {
  localStorage.removeItem('session')
  localStorage.removeItem('profile')
  dispatch({ type: 'set', user: null })
  dispatch({ type: 'set', profile: {} })
  dispatch({ type: 'set', cognitoUser: null })
}

export const addSessionDuration = (user, minutes = 5) => {
  // Obtiene la fecha actual y le suma los minutos de duración de la sesión
  let expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + minutes * 60 * 1000)
  user.expirationDate = expirationDate
  // Al configurarlo, lo guarda en el localStorage
  localStorage.setItem('session', JSON.stringify(user))
}

export const signIn = async (email, password, setLoading, dispatch) => {
  setLoading(true)
  let token = null
  let userFrac = null
  try {
    // Hace signIn con el usuario de cognito
    const response = await Auth.signIn(email, password)
    dispatch({ type: 'set', cognitoUser: response })

    // Si el usuario requiere un cambio de contraseña, redirige y corta ejecución
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return Utils.redirect('/#/newPassword')
    }

    const authenticated = await Auth.currentAuthenticatedUser()
    const session = await Auth.currentSession()

    // Intenta hacer SignIn en fraccionario, si no existe el usuario entra en catch
    const fracResponse = await apiFrac.userLogin(email, password)
    token = fracResponse.data.data.token

    const userFracResponse = await axios.post(
      `${API_FRAC}/user/read`,
      { user: email, password },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    userFrac = userFracResponse.data.data[0]?.usuarios_plus_usuarios || null

    let user = {
      frac: userFrac ? { ...userFrac, token } : null,
      customer: { authenticated, session },
    }

    // Session Config
    addSessionDuration(user)

    // Redirect si existe el usuario en Fraccionario y en Cognito user pool
    return Utils.redirect('/')
  } catch (error) {
    try {
      // Hace signIn con el usuario de cognito
      await Auth.signIn(email, password)
      const authenticated = await Auth.currentAuthenticatedUser()
      const session = await Auth.currentSession()
      const userAttributes = authenticated.attributes
      // Cognito End

      // Crea el usuario en fraccionario con la data de cognito
      const userFrac = {
        nombre: userAttributes.name,
        user: email,
        password, // Use the password passed to the function
        apellido: userAttributes.lastName,
        with_google: 0,
      }
      const createFracUserResponse = await apiFrac.createUser(userFrac)
      userFrac.token = createFracUserResponse.data.data.token

      let user = {
        frac: userFrac ? { ...userFrac, token } : null,
        customer: { authenticated, session },
      }

      // Session Config
      addSessionDuration(user)

      // Redirect si la creacion del usuario en fraccionario es exitosa
      return Utils.redirect('/')
    } catch (error) {
      setLoading(false)
      return toast.error(errors[error.code] || errors.default)
    }
  }
}

export const signUp = async (userData, setLoading) => {
  setLoading(true)
  try {
    // Cognito Start
    const userCognito = {
      username: userData.email,
      password: userData.password,
      attributes: {
        email: userData.email,
        phone_number: `+${userData.phone}`,
        name: userData.name,
        family_name: userData.lastName,
      },
    }
    await Auth.signUp(userCognito)
    // Cognito End

    // Frac Start
    const userFrac = {
      nombre: userData.name,
      user: userData.email,
      password: userData.password,
      apellido: userData.lastName,
      with_google: 0,
    }
    await apiFrac.createUser(userFrac)
    // Frac End
    toast.success('Cuenta registrada exitosamente!')
    // Redirect
    return Utils.redirect('/#/confirm')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}

export const confirmSignUp = async (email, code, setLoading) => {
  setLoading(true)
  try {
    await Auth.confirmSignUp(email, code)
    toast.success('Cuenta confirmada exitosamente!')
    Utils.redirect('/#/login')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}

export const currentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    return null
  }
}

export const currentSession = async () => {
  try {
    const session = await Auth.currentSession()
    return session
  } catch (error) {
    return null
  }
}

export const signOut = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    await Auth.signOut()
    // Se agrega un pequeño delay para que no interfiera con addSessionDuration
    setTimeout(() => {
      removeCredentials(dispatch)
    }, 2000)
    setLoading(false)
    return Utils.redirect('/#/login')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}

export const changePassword = async (user, oldPassword, newPassword) => {
  try {
    const response = await Auth.changePassword(user, oldPassword, newPassword)
    return response
  } catch (error) {
    return toast.error(errors[error.code] || errors.default)
  }
}

export const forgotPassword = async (user, setLoading) => {
  setLoading(true)
  try {
    await Auth.forgotPassword(user)
    toast.success('Codigo enviado!')
    Utils.redirect('/#/recoverySubmit')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}

// Casos en los que se cambian las contraseñas de Fraccionario y Cognito al mismo tiempo
export const changeBothPasswords = async ({ email, code, password }, setLoading) => {
  setLoading(true)
  try {
    // Frac Start
    await apiFrac.changePassword(email, password)
    // Frac End
    await Auth.forgotPasswordSubmit(email, code, password)
    toast.success('Contraseña actualizada!')
    Utils.redirect('/#/login')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}

// Casos en los que el login pide un cambio de contraseña para los usuarios de BackOffice
export const completeNewPassword = async ({ email, password }, setLoading, cognitoUser) => {
  setLoading(true)
  try {
    await apiFrac.changePassword(email, password)
  } catch (error) {
    console.error(error)
  }
  try {
    await Auth.completeNewPassword(cognitoUser, password)
    toast.success('Contraseña actualizada!')
    Utils.redirect('/#/login')
  } catch (error) {
    setLoading(false)
    return toast.error(errors[error.code] || errors.default)
  }
}
