import React from 'react'

export const hints = {
  patrimony: {
    title: 'Patrimonio',
    description: (
      <div className="flex flex-col space-y-1">
        <p>
          Tu patrimonio es el monto resultante de sumar todos tus bienes o activos menos tus gastos
          o pasivos.
        </p>
        <p>
          Agrega todos tus activos en la sección <b>“Mis Activos”</b> y todas tus deudas y gastos en
          la sección <b>“Mis Pasivos”</b>, y podrás ir viendo mes a mes la evolución de tu
          patrimonio.
        </p>
      </div>
    ),
  },
  assets: {
    title: 'Activos',
    description: (
      <div className="flex flex-col space-y-1">
        <p>
          Un activo en el mundo de la inversión inmobiliaria es cualquier propiedad o bien que
          posees y tiene valor en el mercado. Este podría incluir propiedades inmobiliarias que
          generan ingresos, como alquileres, y que pueden ser vendidas para obtener dinero.
        </p>
        <p>
          En resumen, un activo pone dinero en tu bolsillo y contribuye al crecimiento de tu
          patrimonio.
        </p>
      </div>
    ),
  },
  passives: {
    title: 'Pasivos',
    description: (
      <div className="flex flex-col space-y-1">
        <p>
          En términos de inversión inmobiliaria, un pasivo se refiere a deudas u obligaciones
          financieras asociadas con una propiedad. Por ejemplo, pagos de hipoteca, mantenimiento,
          reparaciones, etc. Estos representan salidas de dinero de tu bolsillo. Es crucial
          distinguir entre activos que generan ingresos y pasivos que generan gastos al decidir qué
          adquirir para asegurar un aumento sostenible en tu patrimonio.
        </p>
      </div>
    ),
  },
}
