import { slackbot } from 'api/config'

const sendMessage = (payload) => {
  return slackbot.post(`/message`, payload)
}

export { sendMessage }

// Ejemplo de payload:

// {
//   "title": ":pogfish: Test de Lambda, mensaje custom :pogfish:",
//   "channel": "github-logs",
//   "ref": ":alert: Lambda",
//   "content": [
//       {
//           "text": "Lorem ipsum"
//       },
//       {
//           "text": "Lorem ipsum2"
//       },
//       {
//           "text": "Lorem ipsum3"
//       }
//   ]
// }
