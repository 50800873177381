import React from 'react'
import { styles } from './styles'
import { Button, Card, CardBody } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { Utils } from 'helpers/index'
import PropTypes from 'prop-types'

const PassiveCard = ({ item, setCurrentItem, setModalDetails, setModalEdit, setModalDelete }) => {
  return (
    <Card
      className={styles.CARD}
      isPressable
      onPress={() => {
        setCurrentItem(item)
        setModalDetails(true)
      }}
    >
      <CardBody className="relative p-3">
        <div className="flex space-x-1">
          <Button
            onClick={() => {
              setCurrentItem(item)
              setModalEdit(true)
            }}
            isIconOnly
            color="primary"
            variant="light"
            size="sm"
            className={styles.EDIT}
          >
            <Icon icon="akar-icons:edit" className="min-w-[24px] min-h-[24px]" />
          </Button>
          <Button
            onClick={() => {
              setCurrentItem(item)
              setModalDelete(true)
            }}
            isIconOnly
            color="primary"
            variant="light"
            size="sm"
            className={styles.EDIT}
          >
            <Icon icon="ic:outline-delete" className="min-w-[24px] min-h-[24px]" />
          </Button>
        </div>
        <img className={styles.IMAGE} alt={item.title} src={item.avatar} />
        <div className="text-center">
          <p className={styles.NAME}>{item.basicInfo.passiveType}</p>
          <p className={styles.TEXT_CONTAINER}>
            <span className="mr-1">{item.basicInfo.currencyPassive}</span>$
            {Utils.formatRoundUF(item.basicInfo.passivePrice)}
          </p>
        </div>
      </CardBody>
    </Card>
  )
}

PassiveCard.propTypes = {
  item: PropTypes.object,
  setCurrentItem: PropTypes.func,
  setModalDetails: PropTypes.func,
  setModalEdit: PropTypes.func,
  setModalDelete: PropTypes.func,
}

export default PassiveCard
