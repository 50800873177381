import React, { Fragment, useEffect, useState } from 'react'
import { Project } from 'helpers'
import { useSelector } from 'react-redux'
import { API_FRAC } from 'api/config'
import axios from 'axios'
import CardPortfolio from './CardPortfolio'
import TablePortfolio from './TablePortfolio'
import { UI } from 'components/index'

const PortfolioDetail = () => {
  const user = useSelector((state) => state.user)

  const [projects, setProjects] = useState(null)
  const userFrac = user?.frac ?? null

  const fetchData = async () => {
    try {
      const { data } = await (
        await axios.get(
          `${API_FRAC}/investment/getinvestmentByClient/${userFrac.sys_usuarios_id}`,
          { userId: userFrac.sys_usuarios_id },
          {
            headers: {
              authorization: `Bearer ${userFrac.token}`,
            },
          },
        )
      ).data
      const formattedProjects = Project.formatPortfolioMovements(data)
      //Se setean los proyectos en orden reverso, lo que resulta en que
      //las inversiones más recientes aparezcan en primer lugar
      setProjects(formattedProjects.reverse())
    } catch (error) {
      return console.error(error)
    }
  }

  useEffect(() => {
    if (userFrac) {
      fetchData()
    }
  }, [])

  const emptyMovements = (project) => project.movements.length === 0

  return (
    <Fragment>
      {projects && projects?.length > 0 && (
        <UI.Content className="flex flex-col p-4 space-y-3 animate-fade-up">
          <p className="text-xl font-semibold text-primary">Tus activos en Fraccionario</p>
          {projects?.map((project, index) => (
            <Fragment key={index}>
              {project.status === 'Aprobada' && (
                <div className="rounded-3xl shadow-propital dark:shadow-none dark:bg-neutral-800">
                  <CardPortfolio project={project} />
                  {!emptyMovements(project) && (
                    <TablePortfolio key={'table' + project.id} data={project.movements} />
                  )}
                </div>
              )}
            </Fragment>
          ))}
        </UI.Content>
      )}
    </Fragment>
  )
}

export default PortfolioDetail
