import React, { useEffect } from 'react'
import { Button, Image } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import congrats from 'assets/images/app/felicidades.png'
import confetti from 'canvas-confetti'

const Congrats = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      })
      const count = 200
      const defaults = {
        origin: { y: 0.7 },
      }
      const fireworks = [
        { particleRatio: 0.25, spread: 26, startVelocity: 55 },
        { particleRatio: 0.2, spread: 60 },
        { particleRatio: 0.35, spread: 100, decay: 0.91, scalar: 0.8 },
        { particleRatio: 0.1, spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 },
        { particleRatio: 0.1, spread: 120, startVelocity: 45 },
      ]
      fireworks.forEach(({ particleRatio, ...options }) =>
        confetti({
          ...defaults,
          ...options,
          particleCount: Math.floor(count * particleRatio),
        }),
      )
    }, 50)
  }, [])

  return (
    <div className="flex flex-col items-center min-h-[90vh] py-6 animate-fade">
      <section className="flex flex-col items-center space-y-2">
        <h1 className="text-3xl font-bold text-secondary dark:text-primary">¡Felicidades!</h1>
        <h4 className="text-lg text-neutral-500 dark:text-foreground">
          ¡Completaste tu inversión!
        </h4>
      </section>
      <Image width={400} alt="Felicidades Propital" src={congrats} />
      <section className="flex flex-col items-center text-lg text-center text-neutral-500 dark:text-foreground">
        <p>Te invitamos a seguir invirtiendo para aumentar tu patrimonio,</p>
        <p>multiplica tu patrimonio con el respaldo de Propital</p>
        <Button
          onClick={() => navigate('/opportunities')}
          endContent={
            <Icon
              className="min-h-[24px] min-w-[24px] animate-jump-in animate-duration-1000"
              icon="heroicons:rocket-launch"
            />
          }
          color="primary"
          size="lg"
          className="mt-6 rounded-3xl"
          variant="shadow"
        >
          ¡Seguir invirtiendo!
        </Button>
      </section>
    </div>
  )
}

export default Congrats
