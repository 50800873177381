import React, { Fragment, useEffect, useState } from 'react'
import { UI } from 'components'
import { Avatar, Button, Checkbox, Input, Select, SelectItem } from '@nextui-org/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import { inputs } from './constants'
import { avatars } from 'constants/passives'
import { useNavigate } from 'react-router-dom'
import { styles } from './styles'
import { api } from 'api'
import { useSelector } from 'react-redux'
import { Utils } from 'helpers/index'
import toast from 'react-hot-toast'
import useLayout from 'hooks/useLayout'
import Preview from './Preview'

const defaultValues = {
  passiveType: '', // Tipo de pasivo
  passivePrice: '', // Monto original
  currencyPassive: '', // Divisa del monto
  quotaValue: '', // Valor cuota
  quotaPeriod: '', // Período
  quotaDuration: '', // Meses
  includesQuota: { amortizacion: false, seguro: false, intereses: false }, // La cuota incluye (Check: Interéses, Amortización, Seguros)
  annualInterestRate: '', // Tasa de interés anual (%)
  avatar: '', // Avatar
}

const CreatePassive = () => {
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(true)

  const { layout } = useLayout()
  const userId = useSelector((state) => state.user.customer.authenticated.attributes.sub)
  const navigate = useNavigate()

  const {
    watch,
    register,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const {
    passiveType,
    passivePrice,
    currencyPassive,
    quotaValue,
    quotaPeriod,
    pendingQuota,
    includesQuota,
    annualInterestRate,
    avatar,
  } = watch()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const passive = {
        passiveType,
        passivePrice: Utils.sanitizeNumericValue(passivePrice),
        currencyPassive,
        quotaValue: Utils.sanitizeNumericValue(quotaValue),
        quotaPeriod: Number(quotaPeriod),
        pendingQuota: Number(pendingQuota),
        includesQuota,
        annualInterestRate: Number(annualInterestRate),
        avatar,
      }
      await api.createPassive(userId, passive)
      toast.success('¡Pasivo creado con éxito!')
      return navigate('/passives')
    } catch (error) {
      setLoading(false)
      return console.error(error)
    }
  }

  useEffect(() => {
    return setValue('avatar', avatars[passiveType])
  }, [passiveType])

  const handleCheckbox = (e, target) => {
    return setValue('includesQuota', { ...includesQuota, [target]: e })
  }

  const handlePriceChange = (fieldName, e) => {
    if (fieldName === 'passivePrice' || fieldName === 'quotaValue') {
      let inputValue = e.target.value
      const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''))
      if (!isNaN(numericValue)) {
        let formattedValue = numericValue.toLocaleString('es-AR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        if (Number.isInteger(numericValue)) {
          formattedValue = numericValue.toLocaleString('es-AR')
        }
        setValue(fieldName, numericValue)
        e.target.value = formattedValue
      }
    } else {
      setValue(fieldName, e.target.value)
    }
  }

  const handleGoBack = () => {
    reset(defaultValues)
    return setPreview(true)
  }

  // Al ingresar a la página, se debe hacer un scroll hacia arriba
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [preview])

  const renderInput = (input) => {
    const components = {
      select: (
        <Select
          items={input.options}
          label={input.label}
          placeholder={input.placeholder}
          className={input.className}
          {...register(input.name)}
          isInvalid={errors[input.name]}
          errorMessage={errors[input.name]?.message}
          defaultSelectedKeys={passiveType ? [passiveType] : null}
          color={errors[input.name] ? 'danger' : 'primary'}
          isRequired={input.isRequired}
          listboxProps={{ className: 'bg-background rounded-xl' }}
          popoverProps={{ className: 'w-full' }}
          classNames={
            input.name === 'passiveType' && passiveType
              ? {
                  label: 'hidden',
                  value: 'text-primary font-bold ml-1 dark:text-foreground',
                  innerWrapper: 'pb-3.5',
                }
              : {}
          }
          startContent={
            input.name === 'passiveType' &&
            passiveType && (
              <Avatar
                src={avatar}
                className="bg-primary/10 dark:bg-primary/20 min-w-[40px] min-h-[40px]"
              />
            )
          }
          disallowEmptySelection
          variant="bordered"
          aria-label="select"
          aria-labelledby="select"
        >
          {(option) => (
            <SelectItem
              key={option.value}
              startContent={
                input.name === 'passiveType' && (
                  <Avatar src={option.avatar} className="bg-primary/10 dark:bg-primary/20" />
                )
              }
            >
              {option.label}
            </SelectItem>
          )}
        </Select>
      ),
      checkboxGroup: (
        <div className={input.className}>
          <p className={styles.CHECK_GROUP_TITLE}>{input.label}</p>
          <div className={styles.CHECKBOX_CONTAINER}>
            {input.options?.map((option, index) => (
              <div key={index} className={styles.CHECKBOX_CONTENT}>
                <Checkbox
                  value={option.value}
                  onValueChange={(value) => handleCheckbox(value, option.value)}
                  aria-label="checkbox"
                  aria-labelledby="checkbox"
                >
                  <p className={styles.CHECKBOX_LABEL}>{option.label}</p>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      ),
      default: (
        <Input
          type={input.type}
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          className={input.className}
          {...register(input.name)}
          isInvalid={errors[input.name]}
          errorMessage={errors[input.name]?.message}
          color={errors[input.name] ? 'danger' : 'primary'}
          isRequired={input.isRequired}
          classNames={{
            label: `${layout.width < 600 && 'w-max'}`,
            input: 'pb-[1.5px]',
          }}
          variant="bordered"
          aria-label="input"
          aria-labelledby="input"
          onChange={(e) => handlePriceChange(input.name, e)}
          startContent={
            input.startContent && (
              <p className="text-primary/30 dark:text-foreground/30">{input.startContent}</p>
            )
          }
        />
      ),
    }
    return input ? components[input.component] : components.default
  }

  return (
    <div className={styles.MAIN_CONTAINER}>
      <UI.Header
        containerClassName="text-center mt-3"
        title={preview ? 'Seleccionar pasivo' : 'Agregar pasivos'}
        subtitle={preview ? '¿Qué tipo de pasivo deseas crear?' : 'Rellena todos los campos'}
      />
      <UI.Content className="!shadow-none !bg-transparent animate-fade-up">
        {preview ? (
          <Preview setValue={setValue} setPreview={setPreview} />
        ) : (
          <div className={styles.CONTENT}>
            <form className={styles.FORM_CONTAINER}>
              {inputs.map((input, index) => (
                <Fragment key={index}>{renderInput(input)}</Fragment>
              ))}
            </form>
            <div className={styles.ACTIONS}>
              <Button
                className={styles.BUTTON}
                color="primary"
                variant="bordered"
                onClick={handleGoBack}
                isLoading={loading}
              >
                Volver atrás
              </Button>
              <Button
                className={styles.BUTTON}
                color="primary"
                variant="shadow"
                isDisabled={!isValid}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Crear pasivo
              </Button>
            </div>
          </div>
        )}
      </UI.Content>
    </div>
  )
}

export default CreatePassive
