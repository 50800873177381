import React from 'react'
import { styles } from './styles'
import { Button } from '@nextui-org/react'
import PropTypes from 'prop-types'

const FormActions = ({ setEditMode, handleSubmit, submitLoading, isDirty }) => {
  return (
    <div className={styles.ACTIONS}>
      <Button
        className={styles.BUTTON}
        color="primary"
        variant="bordered"
        onClick={() => {
          window.scrollTo(0, 0)
          setEditMode(false)
        }}
      >
        Cancelar
      </Button>
      <Button
        className={styles.BUTTON}
        color="primary"
        variant="shadow"
        onClick={handleSubmit}
        isLoading={submitLoading}
        isDisabled={!isDirty}
      >
        Guardar
      </Button>
    </div>
  )
}

FormActions.propTypes = {
  setEditMode: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  isDirty: PropTypes.bool,
}

export default FormActions
