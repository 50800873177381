import React, { useEffect, useState } from 'react'
import { Button, Checkbox } from '@nextui-org/react'
import { Utils } from 'helpers/index'
import { defaultObjectives } from './constants'
import { api } from 'api/index'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

const Objectives = ({ user, userCustomerId }) => {
  const [disabled, setDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [objectives, setObjectives] = useState({})

  const handleObjectives = (key) => {
    setObjectives((prev) => ({ ...prev, [key]: !prev[key] }))
    setDisabled(false)
  }

  const handleValues = () => {
    if (user?.where_you_invest_today?.includes('{')) {
      return setObjectives(JSON.parse(user.where_you_invest_today))
    } else {
      return setObjectives(defaultObjectives)
    }
  }

  useEffect(() => {
    handleValues()
  }, [user])

  const handleSubmit = async () => {
    setSubmitLoading(true)
    const payload = { where_you_invest_today: JSON.stringify(objectives) }
    try {
      await api.updateUser(userCustomerId, payload)
      toast.success('¡Objetivos actualizados con éxito!')
      setSubmitLoading(false)
      return setDisabled(true)
    } catch (error) {
      setSubmitLoading(false)
      toast.error('Hubo un problema al actualizar los datos, intente de nuevo más tarde')
      return console.error(error)
    }
  }

  return (
    <div className="flex flex-col">
      {Object.keys(objectives).map((key, index) => (
        <Checkbox
          key={key}
          defaultSelected={objectives[key]}
          onChange={() => handleObjectives(key)}
          className={index !== 0 && 'mt-2'}
          classNames={{
            label: `text-sm ${
              objectives[key]
                ? 'text-secondary dark:text-foreground font-semibold'
                : 'text-inactive'
            }`,
          }}
        >
          {key === 'afp' ? key.toLocaleUpperCase() : Utils.capitalizeCombo(key)}
        </Checkbox>
      ))}
      <Button
        color="primary"
        className="!mt-6 w-fit rounded-3xl"
        isDisabled={disabled}
        isLoading={submitLoading}
        onClick={() => handleSubmit()}
      >
        Actualizar objetivos
      </Button>
    </div>
  )
}

Objectives.propTypes = {
  user: PropTypes.object.isRequired,
  userCustomerId: PropTypes.string.isRequired,
}

export default Objectives
