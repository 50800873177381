import React from 'react'
import { styles } from './styles'
import { UI } from 'components/index'
import { Icon } from '@iconify/react'
import { useSelector } from 'react-redux'
import { Spinner, Tooltip } from '@nextui-org/react'

const Datamart = () => {
  const user = useSelector((state) => state.user)
  const profile = useSelector((state) => state.profile)
  const RUT = profile?.identity_document ? `${profile?.identity_document?.replaceAll('.', '')}` : ''
  const name = `${profile.first_name} ${profile.last_name}`
  return (
    <UI.Content className={`${styles.CONTENT} col-span-5 step-2`}>
      <div className="flex items-center space-x-2">
        <Tooltip color="primary" content="Ver más información" closeDelay={100}>
          <a href="/#/income" rel="noreferrer">
            <p className="text-xl font-semibold text-primary">Propital Income</p>
          </a>
        </Tooltip>
        <Icon
          className="min-h-[1.3rem] min-w-[1.3rem] animate-jump-in animate-duration-1000 text-primary"
          icon="streamline:subscription-cashflow"
        />
      </div>
      <p className="mt-3 text-secondary dark:text-foreground">
        Nosotros te ayudaremos a tomar mejores decisiones financieras.
      </p>
      {user && Object.keys(profile).length > 0 ? (
        <div className="mx-auto mt-3 md:my-auto w-fit">
          <datamart-button
            connectid="e2fa73dd-bcd2-4126-a321-dc608b378d31"
            validatesubscription="true"
            subscriberid={RUT}
            authorizername={name}
            authorizeremail={profile.email}
            authorizermobile={profile.phone}
            uibuttontext="Sincronizar Income"
            uibuttonshowtagline="false"
            uibuttonshowexplanation="false"
            uibuttonbgbox="transparent"
            uibuttonshape="round"
            uibuttoncolor="#0084FE"
          ></datamart-button>
        </div>
      ) : (
        <div className="mx-auto mt-3 md:my-auto w-fit">
          <Spinner color="primary" />
        </div>
      )}
    </UI.Content>
  )
}

export default Datamart
