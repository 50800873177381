import React, { Fragment } from 'react'
import { inputs } from './constants'
import { Avatar, Input, Select, SelectItem } from '@nextui-org/react'
import { handleKeyPress, handlePriceChange } from './controller'
import { assetFields } from 'constants/assets'
import { styles } from './styles'
import useLayout from 'hooks/useLayout'
import PropTypes from 'prop-types'

const Form = ({ watch, register, setValue, errors, currentStep }) => {
  const { layout } = useLayout()
  const { assetType, assetSubType, avatar } = watch()
  return (
    <form className={styles.FORM_CONTAINER}>
      {inputs[currentStep.key].map((input, index) => {
        // Cambiarán los inputs según lo que seleccione el usuario
        if (!assetType) {
          if (!assetFields.default.includes(input.name)) return null
        }
        if (assetType === 'Real Estate' && assetSubType) {
          // Si es terreno o parcela, usar los inputs de assetFields.defaultSubType
          if (assetSubType === 'Terreno' || assetSubType === 'Parcela') {
            if (!assetFields.defaultSubType.includes(input.name)) return null
          } else {
            // Si no es terreno o parcela, usar los inputs de assetFields.withRental
            if (!assetFields.withRental.includes(input.name)) return null
          }
        }
        if (assetFields[assetType] && !assetSubType) {
          if (!assetFields[assetType].includes(input.name)) return null
        }
        // Se debe manejar las opciones que vea el usuario según el tipo de activo
        const options =
          assetType && input.name === 'assetSubType' ? input.options[assetType] : input.options
        if (input.name === 'assetSubType' && !assetType) return null
        if (input.isSelect && !options.length) return null
        return (
          <Fragment key={index}>
            {input.isSelect ? (
              <Select
                items={options}
                label={input.label}
                placeholder={input.placeholder}
                className={input.className}
                {...register(input.name)}
                isInvalid={errors[input.name]}
                errorMessage={errors[input.name]?.message}
                defaultSelectedKeys={assetType ? [assetType] : null}
                color={errors[input.name] ? 'danger' : 'primary'}
                isRequired={input.isRequired}
                listboxProps={{ className: 'bg-background rounded-xl' }}
                popoverProps={{ className: 'w-full' }}
                classNames={
                  input.name === 'assetType' && assetType
                    ? {
                        label: 'hidden',
                        value: 'text-primary font-bold ml-1 dark:text-foreground',
                        innerWrapper: 'pb-3.5',
                      }
                    : {}
                }
                startContent={
                  input.name === 'assetType' &&
                  assetType && (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="bg-primary/10 dark:bg-primary/20 w-[40px] h-[40px] rounded-full"
                    />
                  )
                }
                disallowEmptySelection
                variant="bordered"
                aria-label="select"
                aria-labelledby="select"
              >
                {(option) => (
                  <SelectItem
                    key={option.value}
                    startContent={
                      (input.name === 'assetType' || input.name === 'assetSubType') && (
                        <Avatar src={option.avatar} className="bg-primary/10 dark:bg-primary/20" />
                      )
                    }
                  >
                    {option.label}
                  </SelectItem>
                )}
              </Select>
            ) : (
              <Input
                type={input.type}
                name={input.name}
                label={input.label}
                placeholder={input.placeholder}
                className={input.className}
                {...register(input.name)}
                isInvalid={errors[input.name]}
                errorMessage={errors[input.name]?.message}
                color={errors[input.name] ? 'danger' : 'primary'}
                isRequired={input.isRequired}
                classNames={{
                  label: `${layout.width < 600 && 'w-max'}`,
                  input: 'pb-[1.5px]',
                }}
                variant="bordered"
                aria-label="input"
                aria-labelledby="input"
                onChange={(e) => handlePriceChange(input.name, e, setValue)}
                onKeyDown={(e) => handleKeyPress(input.name, e)}
                startContent={
                  input.startContent && (
                    <p className="text-primary/30 dark:text-foreground/30">{input.startContent}</p>
                  )
                }
              />
            )}
          </Fragment>
        )
      })}
    </form>
  )
}

Form.propTypes = {
  watch: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  currentStep: PropTypes.object,
}

export default Form
