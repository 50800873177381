import React, { Fragment } from 'react'
import { App } from 'components'
import useLayout from 'hooks/useLayout'

const Layout = () => {
  const { layout } = useLayout()
  const isMobile = layout.width < 768
  return (
    <Fragment>
      <div className="grid grid-cols-12 min-h-[90vh]">
        {isMobile ? (
          <div className="col-span-12">
            <App.Nav />
          </div>
        ) : (
          <div className="col-span-3 xl:col-span-2 animate-fade-right">
            <App.Nav />
          </div>
        )}
        <div
          className={`${
            isMobile ? 'col-span-12' : 'col-span-9 xl:col-span-10'
          } p-3 md:p-6 dark:bg-neutral-950 bg-background`}
        >
          <App.Content />
        </div>
      </div>
      <App.Footer />
    </Fragment>
  )
}

export default Layout
