import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('El formato del correo electrónico no es válido')
      .required('El correo electrónico es obligatorio'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Debe contener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial',
      )
      .max(32, 'La contraseña no debe tener más de 32 caracteres')
      .required('La contraseña es obligatoria'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('La confirmación de contraseña es obligatoria'),
  })
  .required('Todos los campos son obligatorios')
