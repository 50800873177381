export const styles = {
  MAIN_CONTAINER:
    'flex pt-9 bg-background md:pt-0 items-start md:items-center justify-center h-screen',
  FORM: 'flex flex-col space-y-3 w-[300px] p-3 py-6 border-2 border-primary rounded-3xl bg-background z-10 animate-fade-up shadow-propital',
  TITLE: 'mb-3 text-xl font-semibold text-center',
  FORGET: 'text-xs text-right cursor-pointer text-primary hover:underline mt-2',
  BOTTOM_CONTENT: 'text-sm text-center',
  REGISTER: 'font-semibold text-primary',
  ICON: 'text-neutral-400 h-[24px] w-[24px] cursor-pointer',
  FOOTER: 'absolute bottom-0 animate-fade animate-duration-2000 min-w-[100vw]',
}
