/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/react'
import useLayout from 'hooks/useLayout'
import Slider from 'react-slick'
import ModalContact from './ModalContact'
import Fraccionario from 'assets/images/app/banners/fraccionario.png'
import FraccionarioSm from 'assets/images/app/banners/fraccionario-sm.png'
import DescargaPropital from 'assets/images/app/banners/descarga-propital.png'
import DescargaPropitalSm from 'assets/images/app/banners/descarga-propital-sm.png'
import ServiciosContables from 'assets/images/app/banners/servicios-contables.png'
import ServiciosContablesSm from 'assets/images/app/banners/servicios-contables-sm.png'
import Simulador from 'assets/images/app/banners/simulador-credito-hipotecario.png'
import SimuladorSm from 'assets/images/app/banners/simulador-credito-hipotecario-sm.png'
import PropTypes from 'prop-types'

const Banner = ({ navigate }) => {
  const [modalContact, setModalContact] = useState(false)

  const { layout } = useLayout()

  const isMobile = layout.width < 768

  const settings = {
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: !isMobile,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const images = [
    {
      title: 'Sácale el mayor provecho a tu Propital App',
      src: isMobile ? DescargaPropitalSm : DescargaPropital,
      link: 'https://play.google.com/store/apps/details?id=com.propitalapp&pli=1',
      onClick: null,
      formButton: null,
    },
    {
      title: 'Simula tu crédito hipotecario',
      src: isMobile ? SimuladorSm : Simulador,
      link: 'https://simulador.propital.com',
      onClick: null,
      formButton: null,
    },
    {
      title: 'Fraccionario',
      src: isMobile ? FraccionarioSm : Fraccionario,
      link: 'https://fraccionario.propital.com',
      onClick: null,
      formButton: null,
    },
    {
      title: 'Estamos aquí para apoyarte en cada etapa del camino',
      src: isMobile ? ServiciosContablesSm : ServiciosContables,
      link: 'https://ww2.propital.com/legal-y-tributario',
      onClick: null,
      formButton: null,
    },
  ]

  return (
    <Fragment>
      {modalContact && <ModalContact visible={modalContact} setVisible={setModalContact} />}
      <div className="py-3 rounded-3xl slider-container bg-primary/10 dark:bg-background">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              className="relative cursor-pointer"
              onClick={() => {
                if (image.onClick) return image.onClick()
                if (image.link) return window.open(image.link, '_blank')
              }}
            >
              <img src={image.src} alt={image.title} className="h-[20vh] mx-auto object-contain" />
              {image.formButton && (
                <Button
                  color="primary"
                  className="absolute bottom-0 right-0 mr-6 rounded-3xl"
                  onClick={() => image.formButton.onClick()}
                >
                  <b>{image.formButton.text}</b>
                </Button>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </Fragment>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <Icon
      icon="line-md:chevron-left-circle-twotone"
      className={`${className} opacity-30 text-primary hover:opacity-100 transition-all hover:brightness-125 hover:text-primary h-[30px] w-[30px] ml-9 z-50`}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <Icon
      icon="line-md:chevron-right-circle-twotone"
      className={`${className} opacity-30 text-primary hover:opacity-100 transition-all hover:brightness-125 hover:text-primary h-[30px] w-[30px] mr-9 z-50`}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

export default Banner

Banner.propTypes = {
  navigate: PropTypes.func,
}
