import { profileOptions } from 'constants/profile'

export const steps = {
  finance: {
    title: 'Datos Financieros y Laborales',
    icon: 'iconamoon:profile-fill',
  },
  account: {
    title: 'Datos Personales',
    icon: 'iconamoon:profile-fill',
  },
  preferences: {
    title: 'Preferencias',
    icon: 'iconamoon:profile-fill',
  },
}

export const inputs = {
  account: [
    {
      type: 'text',
      name: 'first_name',
      label: 'Nombre',
      placeholder: 'Nombre completo',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'last_name',
      label: 'Apellido',
      placeholder: 'Apellido',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'number',
      name: 'age',
      label: 'Edad',
      placeholder: 'Ingresa tu edad',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'date',
      name: 'birth_date',
      label: 'Fecha de nacimiento',
      placeholder: 'Ingresa tu fecha de nacimiento',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'identity_document',
      label: 'R.U.T',
      placeholder: 'Ingresa tu documento de identidad',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'marital_status',
      label: 'Estado civil',
      placeholder: 'Seleccione su estado civil',
      isSelect: true,
      options: profileOptions.marital_status,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profesión',
      placeholder: 'Ingrese su profesión',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'address',
      label: 'Dirección',
      placeholder: 'Ingrese su dirección',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'city',
      label: 'Ciudad',
      placeholder: 'Ingrese su ciudad',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'country',
      label: 'Pais',
      placeholder: 'Ingrese su pais',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefono',
      placeholder: 'Ingrese su pais',
      isRequired: true,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      placeholder: 'Ingrese su e-mail',
      isRequired: true,
      className: 'col-span-1',
    },
  ],
  finance: [
    {
      type: 'text',
      name: 'employment_place',
      label: 'Lugar de trabajo',
      placeholder: 'Ingrese su lugar de trabajo',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'contract_type',
      label: 'Contrato',
      placeholder: 'Ingrese su tipo de contrato',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'seniority',
      label: 'Antigüedad',
      placeholder: 'Antigüedad en meses',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'account_type',
      label: 'Tipo de cuenta',
      placeholder: 'Ingrese su tipo de cuenta',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'bank',
      label: 'Nombre del banco',
      placeholder: 'Escriba el nombre del banco',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'bank_account_number',
      label: 'Número de cuenta',
      placeholder: 'Escribe tu número de cuenta',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'net_income',
      label: 'Ingresos líquidos',
      placeholder: 'Ingrese sus ingresos líquidos',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'additional_income',
      label: 'Ingresos adicionales',
      placeholder: 'Ingrese un monto',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'savings_capacity',
      label: 'Capacidad de ahorro',
      placeholder: 'Ingrese un monto',
      isRequired: false,
      className: 'col-span-1',
    },
    {
      type: 'text',
      name: 'savings',
      label: 'Ahorros',
      placeholder: 'Ingrese un monto',
      isRequired: false,
      className: 'col-span-1',
    },
  ],
  preferences: [
    {
      type: 'text',
      name: 'interest_area',
      label: 'Área de interés',
      placeholder: 'Escribe tu área de interés',
      isRequired: false,
      className: 'col-span-2',
    },
  ],
}

export const defaultObjectives = {
  'fondos mutuos': false,
  afp: false,
  cripto: false,
  fraccionario: false,
  otros: false,
}

export const defaultSettings = {
  currency: 'CLP',
  isVerified: false,
  isReferred: false,
}

export const defaultRiskProfile = {
  conocimiento: 0,
  identifica: 0,
  valor: 0,
  riesgo: 0,
  capacidad: 0,
  edad: 0,
  tiempo: 0,
  cuanto: 0,
  objetivo: 0,
  generar: 0,
}

export const riskResults = [
  {
    perfil: 'Conservador',
    descripcion:
      'Este inversionista se caracteriza por evitar el riesgo. Por lo tanto, escoge instrumentos de inversión que le den la garantía de que no perderá parte o todo el dinero que invertirá. No le importa que las ganancias obtenidas sean bajas.',
  },
  {
    perfil: 'Moderado',
    descripcion:
      'Este inversionista es cauteloso con sus decisiones, pero está dispuesto a tolerar un riesgo moderado para aumentar sus ganancias. Procura mantener un balance entre rentabilidad y seguridad.',
  },
  {
    perfil: 'Agresivo',
    descripcion:
      'A este inversionista le atrae el riesgo. Busca los mayores rendimientos posibles, por lo que está dispuesto a asumir el riesgo que sea necesario.',
  },
]

export const questions = [
  {
    title: '¿Como describirías tu grado de conocimiento en inversiones?',
    key: 'conocimiento',
    options: [
      { value: '1', label: 'Bajo.' },
      { value: '2', label: 'Medio.' },
      { value: '3', label: 'Alto.' },
    ],
  },
  {
    title: '¿Qué frase te identifica mejor con respecto a las inversiones?',
    key: 'identifica',
    options: [
      {
        value: '1',
        label:
          'Invertiría todo mi dinero en instrumentos con poco riesgo, aunque su rentabilidad sea baja.',
      },
      {
        value: '2',
        label:
          'Invertiría una parte de mi dinero en instrumentos de bajo riesgo y otra, en aquellos que aunque son más riesgosos, tienen mayor rentabilidad.',
      },
      {
        value: '3',
        label:
          'Invertiría todo mi dinero en instrumentos de alto rendimiento para maximizar mi ganancia, aun cuando esto signifique asumir mayores riesgos.',
      },
    ],
  },
  {
    title: 'Si tu inversión comienza a perder valor, ¿Que harías?',
    key: 'valor',
    options: [
      { value: '1', label: 'Vendería inmediatamente.' },
      { value: '2', label: 'Consultaría a un experto.' },
      {
        value: '3',
        label:
          'Asumiría las pérdidas en el corto plazo y esperaría obtener ganancias en el largo plazo.',
      },
    ],
  },
  {
    title: 'La palabra riesgo para ti significa:',
    key: 'riesgo',
    options: [
      { value: '1', label: 'Alerta.' },
      { value: '2', label: 'Inseguridad.' },
      { value: '3', label: 'Oportunidad.' },
    ],
  },
  {
    title: '¿Como esperas que evolucione tu capacidad de ahorro en los proximos años?',
    key: 'capacidad',
    options: [
      { value: '1', label: 'Disminuiría.' },
      { value: '2', label: 'Mantendría.' },
      { value: '3', label: 'Aumentaría.' },
    ],
  },
  {
    title: '¿Cuál es tu edad?',
    key: 'edad',
    options: [
      { value: '1', label: 'Más de 56 años.' },
      { value: '2', label: 'Entre 46 y 55 años.' },
      { value: '3', label: 'Menos de 45 años.' },
    ],
  },
  {
    title: '¿Por cuánto tiempo planeas invertir tu dinero?',
    key: 'tiempo',
    options: [
      { value: '1', label: 'Menos de 1 año.' },
      { value: '2', label: 'De 1 a 5 años.' },
      { value: '3', label: 'Más de 5 años.' },
    ],
  },
  {
    title: '¿Cuánto de tu patrimonio invertirías?',
    key: 'cuanto',
    options: [
      { value: '1', label: 'Menos del 30%.' },
      { value: '2', label: 'Entre 30% y 60%.' },
      { value: '3', label: 'Más del 60%.' },
    ],
  },
  {
    title: '¿Cuál es tu objetivo principal al invertir?',
    key: 'objetivo',
    options: [
      { value: '1', label: 'Asegurar y mantener mi capital.' },
      { value: '2', label: 'Aumentar mi patrimonio, ojalá con retornos estables.' },
      { value: '3', label: 'Que mi dinero crezca sin importar riesgos.' },
    ],
  },
  {
    title: '¿Cómo generas tus ingresos?',
    key: 'generar',
    options: [
      { value: '1', label: 'Básicamente mediante mi trabajo.' },
      { value: '2', label: 'Mediante mi trabajo, más algunos ahorros.' },
      { value: '3', label: 'De otras rentas no relacionadas con mi trabajo.' },
    ],
  },
]
