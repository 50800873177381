import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .min(2, 'El nombre debe tener al menos 2 caracteres')
      .max(32, 'El nombre no debe tener más de 32 caracteres')
      .required('El nombre es obligatorio'),
    last_name: yup
      .string()
      .min(2, 'El apellido debe tener al menos 2 caracteres')
      .max(32, 'El apellido no debe tener más de 32 caracteres')
      .required('El apellido es obligatorio'),
    age: yup
      .number()
      .min(18, 'Debe ser mayor de 18 años')
      .typeError('La edad debe ser un número')
      .required('La edad es obligatoria'),
    birth_date: yup.string().required('Este campo es obligatorio'),
    identity_document: yup
      .string()
      .matches(/^\d{1,2}\.\d{3}\.\d{3}-[\dkK]{1}$/, 'ingrese un RUT válido')
      .required('Este campo es obligatorio'),
    profession: yup.string().required('Este campo es obligatorio'),
    city: yup.string().required('Este campo es obligatorio'),
    country: yup.string().required('Este campo es obligatorio'),
    address: yup.string().required('Este campo es obligatorio'),
    phone: yup
      .string()
      .min(9, 'El teléfono debe tener al menos 9 caracteres')
      .max(15, 'El teléfono no debe tener más de 15 caracteres')
      .required('El teléfono es obligatorio'),

    email: yup
      .string()
      .email('El formato del correo electrónico no es válido')
      .required('El correo electrónico es obligatorio'),
  })
  .required('Todos los campos son obligatorios')

export const sendEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email('El formato del correo electrónico no es válido')
    .required('El correo electrónico es obligatorio'),
})
