export const avatars = {
  Consumo: 'https://propital-avatares.s3.us-west-2.amazonaws.com/pasivos/consumo.png',
  'Crédito Hipotecario':
    'https://propital-avatares.s3.us-west-2.amazonaws.com/pasivos/creditoHipotecario.png',
  'Crédito Privado':
    'https://propital-avatares.s3.us-west-2.amazonaws.com/pasivos/creditoPrivado.png',
  Otros: 'https://propital-avatares.s3.us-west-2.amazonaws.com/pasivos/otros.png',
}

export const passiveOptions = {
  types: [
    { label: 'Consumo', value: 'Consumo', avatar: avatars.Consumo },
    {
      label: 'Crédito Hipotecario',
      value: 'Crédito Hipotecario',
      avatar: avatars['Crédito Hipotecario'],
    },
    { label: 'Crédito Privado', value: 'Crédito Privado', avatar: avatars['Crédito Privado'] },
    { label: 'Otros', value: 'Otros', avatar: avatars.Otros },
  ],
  currencies: [
    { label: 'UF', value: 'CLF' },
    { label: 'USD', value: 'USD' },
    { label: 'CLP', value: 'CLP' },
  ],
  intervals: [
    { label: 'Anual', value: 'Anual' },
    { label: 'Mensual', value: 'Mensual' },
    { label: 'Semanal', value: 'Semanal' },
    { label: 'Diario', value: 'Diario' },
  ],
  duration: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ],
  benefits: [
    { label: 'Amortización', value: 'amortizacion' },
    { label: 'Seguros', value: 'seguro' },
    { label: 'Intereses', value: 'intereses' },
  ],
}
