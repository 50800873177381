import React, { useEffect, useState } from 'react'
import { Link, NavbarContent, Switch } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { icons } from './constants'
import { useTheme } from 'next-themes'
import PropTypes from 'prop-types'

const Sidebar = ({ routes = [], pathname = '', signOut, authLoading, setModal }) => {
  const { theme, setTheme } = useTheme()
  const [isDark, setIsDark] = useState(true)

  useEffect(() => {
    theme === 'dark' ? setIsDark(true) : setIsDark(false)
  }, [theme])

  return (
    <NavbarContent className={styles.container} justify="center">
      {routes.map((item, index) => {
        const isCurrentPath = pathname === item.path
        if (!item.showNavigate) return null
        return (
          <Link
            key={index}
            href={item.linkTo}
            className={`${styles.navLink} ${
              isCurrentPath ? styles.currentPath : styles.inactivePath
            }`}
          >
            <div className={styles.content}>
              <Icon className="h-[24px] w-[24px]" icon={item.icon} />
              <span className="text-sm font-semibold">{item.name}</span>
            </div>
          </Link>
        )
      })}
      <Link
        className={`${styles.navLink} ${styles.inactivePath} cursor-pointer`}
        onClick={() => {
          window.open('https://fraccionario.propital.com')
        }}
      >
        <div className={styles.content}>
          <Icon className="h-[24px] w-[24px]" icon={icons.fraccionario} />
          <span className="text-sm font-semibold">Fraccionario</span>
        </div>
      </Link>
      <Link
        className={`${styles.navLink} ${styles.inactivePath} cursor-pointer`}
        onClick={() => {
          setModal(true)
        }}
      >
        <div className={styles.content}>
          <Icon className="h-[24px] w-[24px]" icon={icons.report} />
          <span className="text-sm font-semibold">Reportar</span>
        </div>
      </Link>
      <Link className={`${styles.navLink} ${styles.inactivePath} cursor-pointer`}>
        <div className={styles.content}>
          <Icon className="h-[24px] w-[24px]" icon={icons.paint} />
          <span className="text-sm font-semibold">Modo</span>
          <Switch
            size="sm"
            color="default"
            className="ml-3"
            isSelected={!isDark}
            onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            thumbIcon={({ isSelected, className }) =>
              isSelected ? (
                <Icon className={className} icon={icons.light} style={{ pointerEvents: 'none' }} />
              ) : (
                <Icon className={className} icon={icons.dark} style={{ pointerEvents: 'none' }} />
              )
            }
          ></Switch>
        </div>
      </Link>
      <Link className={`${styles.navLink} ${styles.logout}`} onClick={signOut}>
        <div className={styles.content}>
          <Icon
            className="min-h-[24px] min-w-[24px] h-[24px] w-[24px]"
            icon={authLoading ? icons.loading : icons.logout}
          />
          <span className="text-sm font-semibold">Cerrar sesión</span>
        </div>
      </Link>
    </NavbarContent>
  )
}

Sidebar.propTypes = {
  routes: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
  signOut: PropTypes.func.isRequired,
  authLoading: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
}

export default Sidebar

const styles = {
  container: 'hidden w-full h-full gap-2 sm:flex md:flex-col md:items-start',
  navLink: 'w-full rounded-3xl transition-colors py-2 px-3',
  content: 'flex items-center space-x-2',
  currentPath: 'text-white bg-primary shadow-propital',
  inactivePath: 'text-inactive hover:bg-primary/30',
  logout: 'mt-auto cursor-pointer !text-danger hover:!bg-danger/30',
}
