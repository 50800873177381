import React from 'react'
import CountUp from 'react-countup'

const defaultValues = {
  data: [{ value: 100, name: 'Información' }],
}

export const chartOptions = (darkMode = false, data) => {
  return {
    darkMode,
    tooltip: {
      trigger: 'item',
    },
    color: ['#0084FE', '#3cc5f2', '#D930CD', '#8768E0'],
    series: [
      {
        type: 'pie',
        radius: ['66%', '85%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: darkMode ? '#121212' : '#F4F7FF',
          borderWidth: 2,
        },
        data: data ?? defaultValues.data,
      },
    ],
  }
}

export const handleList = (array = [], selected, currency) => {
  return (
    <div className="flex flex-col space-y-3 animate-fade">
      {array.length > 0
        ? array.map((item, index) => (
            <div
              className={`flex flex-row items-center p-2 transition-colors cursor-default rounded-3xl ${
                selected === 'passives' ? 'hover:bg-alt/10' : 'hover:bg-primary/10'
              }`}
              key={index}
            >
              <div
                className={`w-3 h-3 mr-2 rounded-full ${
                  selected === 'passives' ? 'bg-alt' : 'bg-primary'
                }`}
              />
              <p className="font-semibold">{item.name}</p>
              <CountUp
                className="ml-auto text-secondary dark:text-white"
                end={item.value}
                prefix={currency ? `${currency} $` : '$'}
                duration={1}
                separator="."
              />
            </div>
          ))
        : null}
    </div>
  )
}
