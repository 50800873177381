import React, { Fragment, useState } from 'react'
import { Navbar } from '@nextui-org/react'
import { useLocation } from 'react-router-dom'
import contentRoutes from 'routes/content'
import Brand from './Brand'
import Sidebar from './Links'
import Account from './Account'
import MobileMenu from './MobileMenu'
import useAuth from 'hooks/useAuth'
import useLayout from 'hooks/useLayout'
import './styles.css'
import ModalReport from 'components/App/ModalReport'

const Nav = () => {
  const [modal, setModal] = useState(false)

  const { signOut, loading: authLoading } = useAuth()
  const { pathname } = useLocation()
  const { layout } = useLayout()

  const [open, setOpen] = useState(false)

  const isMobile = layout.width < 768

  return (
    <Fragment>
      {modal && <ModalReport visible={modal} setVisible={setModal} />}
      <Navbar
        isMenuOpen={open}
        onMenuOpenChange={(open) => setOpen(open)}
        className={styles.navbar}
        isBordered
        classNames={{
          wrapper: styles.wrapper,
        }}
      >
        <Brand isOpen={open} />
        <Account signOut={signOut} isMobile={isMobile} />
        <Sidebar
          routes={contentRoutes}
          pathname={pathname}
          signOut={signOut}
          authLoading={authLoading}
          setModal={setModal}
        />
        <MobileMenu setOpen={setOpen} routes={contentRoutes} pathname={pathname} />
      </Navbar>
    </Fragment>
  )
}

export default Nav

const styles = {
  navbar: 'animate-fade md:h-[100vh] bg-background min-h-[10vh] shadow-propital dark:shadow-none',
  wrapper: 'flex md:flex-col md:items-start md:h-full md:mb-auto md:pb-6',
}
