import React, { useState } from 'react'
import { Input, Listbox, ListboxItem } from '@nextui-org/react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Icon } from '@iconify/react'

const useGooglePlaces = () => {
  const [placeDetails, setPlaceDetails] = useState(null)
  const [coordinates, setCoordinates] = useState(null)
  const [showList, setShowList] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading: loading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const getLocation = (placeId) => {
    placesService?.getDetails(
      {
        placeId: placeId,
      },
      (placeDetails) => {
        const { lat, lng } = placeDetails.geometry.location
        const coordinates = {
          lat: lat(),
          lng: lng(),
        }
        setCoordinates(coordinates)
        setPlaceDetails(placeDetails)
        setInputValue(placeDetails.formatted_address)
        setShowList(false)
      },
    )
  }

  const AutoComplete = () => (
    <div className="relative">
      <Input
        type="text"
        name="google"
        label="Ubicar en Google Maps"
        placeholder="Buscar ubicación"
        color="primary"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          getPlacePredictions({ input: e.target.value })
          setShowList(true)
        }}
        loading={loading}
        variant="underlined"
        aria-label="google-autocomplete"
        aria-labelledby="google-autocomplete"
        style={{ zIndex: 2100 }}
      />
      {placePredictions?.length > 0 && showList && (
        <div
          className="absolute top-0 w-full border-2 mt-[3.75rem] rounded-xl border-neutral-200 dark:border-neutral-800 bg-background"
          style={{ zIndex: 2000 }}
        >
          <Listbox
            items={placePredictions}
            onAction={(place_id) => getLocation(place_id)}
            aria-label="places"
          >
            {(item) => (
              <ListboxItem
                key={item.place_id}
                startContent={<Icon className="text-primary" icon="mdi:location" />}
              >
                {item.description}
              </ListboxItem>
            )}
          </Listbox>
        </div>
      )}
    </div>
  )

  return {
    AutoComplete,
    placeDetails,
    coordinates,
    loading,
  }
}

export default useGooglePlaces
