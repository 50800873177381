import { api } from 'api/index'
import { defaultTours } from 'constants/Onboarding'
import toast from 'react-hot-toast'

const actions = {
  onlyNext: [
    {
      classes: 'propital-tour-button',
      text: 'Siguiente',
      type: 'next',
    },
  ],
  onlyComplete: [
    {
      classes: 'propital-tour-button',
      text: 'Finalizar',
      type: 'complete',
    },
  ],
  both: [
    {
      classes: 'propital-tour-button-secondary',
      text: 'Atrás',
      type: 'back',
    },
    {
      classes: 'propital-tour-button',
      text: 'Siguiente',
      type: 'next',
    },
  ],
  complete: [
    {
      classes: 'propital-tour-button-secondary',
      text: 'Atrás',
      type: 'back',
    },
    {
      classes: 'propital-tour-button',
      text: 'Finalizar',
      type: 'hide',
    },
  ],
}

const handleCompleteTour = async (tourName) => {
  const userCustomerId = JSON.parse(localStorage.getItem('session'))?.customer.authenticated
    .attributes.sub
  try {
    const { data } = await api.getUserInfo(userCustomerId)
    const user = data[0]
    const onboarding = user?.onboarding?.tours ? user.onboarding.tours : null

    // Si el usuario ya tiene un proceso de onboarding
    if (onboarding) {
      const payload = {
        onboarding: { tours: { ...onboarding, [tourName]: { completed: true } } },
      }
      toast.success('Tutorial completado!')
      return await api.updateUser(userCustomerId, payload)
    }

    // En el caso de que se necesiten setear los tours por primera vez
    const defaultPayload = {
      onboarding: { tours: { ...defaultTours, [tourName]: { completed: true } } },
    }
    toast.success('Tutorial completado!')
    await api.updateUser(userCustomerId, defaultPayload)
  } catch (error) {
    return console.error(error)
  }
}

const handleStep = async () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve()
    }, 300)
  })
}

export const profile = [
  {
    id: 1,
    title: 'Tu cuenta Propital',
    text: [
      `
      Bienvenido a <b class="text-primary">Propital</b>!👋🏻 Puedes empezar a <b>personalizar tu perfil</b> eligiendo un avatar proporcionado por nosotros
      `,
    ],
    attachTo: { element: '.step-1', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.onlyNext,
    beforeShowPromise: handleStep,
  },
  {
    id: 2,
    title: 'Tus datos',
    text: [
      `
      Tu <b>información</b> es muy importante para nosotros, ya que nos ayuda a ofrecerte una mejor experiencia 🚀
      `,
    ],
    attachTo: { element: '.step-2', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.both,
    beforeShowPromise: handleStep,
  },
  {
    id: 3,
    title: 'Completa tu información',
    text: [
      `
      Puedes <b>completar</b> y <b>editar</b> tu información personal, financiera y laboral en estas secciones presionando el ícono de <b>editar</b>.
      `,
    ],
    attachTo: { element: '.step-3', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.complete,
    beforeShowPromise: handleStep,
    when: {
      hide: () => handleCompleteTour('profile'),
    },
  },
]

export const dashboard = [
  {
    id: 1,
    title: 'Bienvenido a Propital! 🚀',
    text: [
      `
      Nos alegra tenerte aquí! En esta sección podrás ver un resumen el tu <b>patrimonio, activos y pasivos</b>.<br/>
      `,
    ],
    attachTo: { element: '.step-1', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.onlyNext,
    beforeShowPromise: handleStep,
  },
  {
    id: 2,
    title: 'Sincronizar con Propital Income 💰',
    text: [
      `
      Con <b class="text-primary">Propital Income</b> podrás sincronizar tu información bancaria y obtener un análisis detallado de tus <b>ingresos</b> y <b>gastos</b>.
      `,
    ],
    attachTo: { element: '.step-2', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.onlyNext,
    beforeShowPromise: handleStep,
  },
  {
    id: 3,
    title: 'Tu Patrimonio 📈',
    text: [
      `
      El <b class="text-primary">patrimonio</b> es el monto resultante de sumar todos tus bienes o activos menos tus gastos o pasivos.
      Este <b>gráfico</b> representa la distribución de tu patrimonio en <b class="text-primary">activos</b> y <b class="text-primary">pasivos</b>.
      `,
    ],
    attachTo: { element: '.step-3', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.both,
    beforeShowPromise: handleStep,
  },
  {
    id: 4,
    title: 'Elige tu moneda de preferencia 💵',
    text: [
      `
      Puedes elegir la <b class="text-primary">moneda</b> en la que deseas ver tu patrimonio. Si deseas cambiarla, puedes hacerlo en este selector.
      `,
    ],
    attachTo: { element: '.step-4', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.both,
    beforeShowPromise: handleStep,
  },
  {
    id: 5,
    title: 'Resumen de tu cuenta 📊',
    text: [
      `
      Aquí podrás ver tus activos y pasivos en forma de listado. Si aún no posees alguno, puedes agregarlo haciendo click en el botón <b class="text-primary">Agregar Activo</b> o <b class="text-primary">Agregar Pasivo</b>.
      O simplemente puedes dirigirte a la sección <b>Activos</b> o <b>Pasivos</b> en el menú de la aplicación.
      `,
    ],
    attachTo: { element: '.step-5', on: 'bottom' },
    canClickTarget: false,
    buttons: actions.both,
    beforeShowPromise: handleStep,
  },
  {
    id: 6,
    title: 'Has completado este tutorial! 🎉',
    text: [
      `
      No olvides <b>completar</b> tu perfil para disfrutar de todas las funcionalidades de <b class="text-primary">Propital</b>!🚀<br/><br/>
      <small class="text-inactive">Si deseas reiniciar los tutoriales, también puedes hacerlo desde tu perfil en la sección de <b>preferencias</b>.</small>
      `,
    ],
    canClickTarget: false,
    buttons: actions.complete,
    beforeShowPromise: handleStep,
    when: {
      hide: () => handleCompleteTour('dashboard'),
    },
  },
]
