export const inputs = [
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    placeholder: 'Ingresa tu correo electrónico',
  },
  {
    type: 'text',
    name: 'code',
    label: 'Código de verificación',
    placeholder: 'Ingresa tu código de verificación',
  },
  {
    type: 'password',
    name: 'password',
    label: 'Contraseña',
    placeholder: 'Escribe tu nueva contraseña',
  },
  {
    type: 'password',
    name: 'confirmPassword',
    label: 'Confirmar',
    placeholder: 'Confirma tu contraseña',
  },
]
