import React from 'react'
import Logo from 'assets/images/app/logo-desktop.png'
import useLayout from 'hooks/useLayout'
import { Icon } from '@iconify/react'

const Legal = () => {
  const { layout } = useLayout()
  const rotate = `rotate-[${layout.width < 1024 ? 300 : 275}deg]`
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <img src={Logo} alt="Logo" className="w-28 h-fit" />
        <p className={styles.title}>Legal y tributario</p>
        <div className={styles.content}>
          <p>
            Haz click en el botón para abrir el chat y sigue los pasos para que un asesor se ponga
            en contacto contigo! 🚀
          </p>
        </div>
        <Icon icon="line-md:rotate-90" className={`${styles.icon} ${rotate}`} />
      </div>
    </div>
  )
}

export default Legal

const styles = {
  mainContainer: 'flex items-center justify-center h-screen px-6 animate-fade',
  container: 'flex flex-col items-center content-center space-y-6',
  title: 'text-xl font-bold text-secondary dark:text-primary',
  content:
    'flex justify-center max-w-[360px] shadow-propital px-6 py-3 space-x-1 text-center border-2 border-dashed rounded-3xl text-secondary dark:text-white border-primary',
  icon: 'w-12 h-12 ml-auto transform text-primary -scale-x-100',
}
