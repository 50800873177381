import { createStore, compose } from 'redux'

const session = JSON.parse(localStorage.getItem('session'))
const profile = JSON.parse(localStorage.getItem('profile'))
const currency = localStorage.getItem('currency')

const initialState = {
  user: session || null,
  profile: profile || {},
  cognitoUser: null,
  currency: currency || 'CLP',
  patrimony: null,
  assets: [],
  passives: [],
  roles: [],
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// Validar para habilitar Redux DevTools solo en entorno local
let composeEnhancers = compose

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const store = createStore(changeState, composeEnhancers())

export default store
