import React, { Fragment, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
} from '@nextui-org/react'
import { apiSlackBot } from 'api/index'
import { Icon } from '@iconify/react'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const ModalReport = ({ visible, setVisible }) => {
  const user = useSelector((state) => state.user?.customer?.authenticated?.attributes ?? null)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSlack = async (type = 'Error') => {
    setLoading(true)
    const payload = {
      title: `${
        type === 'Error' ? ':scared-dog:' : ':sheepish-kevin-james:'
      } *Reporte de un cliente*`,
      channel: 'github-logs',
      ref: 'App Propital - Web',
      content: [
        {
          text: `*Cliente*: ${user?.name} ${user?.family_name} (${user?.email})`,
        },
        {
          text: `*${type}*: ${message}`,
        },
        {
          text: `${
            type === 'Error' ? ':debug:' : ':info:'
          } El usuario ha reportado desde la página de error.`,
        },
      ],
    }
    try {
      await apiSlackBot.sendMessage(payload)
      setLoading(false)
      toast.success('Hemos recibido tu reporte!')
      return setVisible(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      return toast.error('Ocurrió un error al enviar tu reporte, intenta nuevamente.')
    }
  }

  return (
    <Modal isOpen={visible} onOpenChange={setVisible} className="bg-background">
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader className="mx-auto text-xl text-primary">Reportar</ModalHeader>
            <ModalBody className="flex flex-col py-0 space-y-1">
              <p className="text-secondary">
                Puedes describir tu <b>problema</b> o <b>sugerencia</b> y la enviaremos a nuestro
                equipo para solucionarlo lo antes posible! 🚀
              </p>
              <Textarea
                label="Mensaje"
                color="primary"
                variant="bordered"
                placeholder="Describe tu problema o sugerencia"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <Button
                color="primary"
                variant="shadow"
                className="rounded-3xl"
                isLoading={loading}
                onClick={() => handleSlack('Sugerencia')}
              >
                Enviar sugerencia
              </Button>
              <Button
                color="danger"
                variant="shadow"
                className="rounded-3xl"
                endContent={<Icon icon="fluent-mdl2:bug-block" className="w-[20px] h-[20px]" />}
                isLoading={loading}
                onClick={() => handleSlack('Error')}
              >
                Reportar error
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

ModalReport.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}

export default ModalReport
