import { useEffect, useState } from 'react'
import { addSessionDuration } from 'helpers/Auth'
import { isProd } from 'helpers/App'
import useAuth from 'hooks/useAuth'
import debounce from 'lodash/debounce'

// Este listener se ejecuta cuando el usuario no tiene actividad en la página
const UserInactivity = ({ children }) => {
  const { user, signOut } = useAuth()
  const [inactive, setInactive] = useState(false)

  // Duración de la sesión que se va a agregar al usuario en minutos y milisegundos
  const maxMinutes = isProd() ? 5 : 60
  const maxMilliseconds = maxMinutes * 60 * 1000

  useEffect(() => {
    let inactivityTimer

    const resetTimer = () => {
      clearTimeout(inactivityTimer)
      inactivityTimer = setTimeout(() => setInactive(true), maxMilliseconds)
    }

    // Si el usuario tiene actividad, se reinicia el timer y se agregan 5 minutos a la sesión
    const clearTimer = debounce(() => {
      addSessionDuration(user, maxMinutes)
      clearTimeout(inactivityTimer)
      setInactive(false)
      resetTimer()
    }, 1000)

    // Eventos que se van a escuchar en la aplicación para reiniciar el timer
    const events = ['mousemove', 'mousedown', 'keydown']

    const handleEvents = () => {
      events.forEach((event) => {
        window.addEventListener(event, clearTimer)
      })
    }

    const removeEventListeners = () => {
      events.forEach((event) => {
        window.removeEventListener(event, clearTimer)
      })
    }

    handleEvents()
    resetTimer()

    return () => {
      removeEventListeners()
      clearTimeout(inactivityTimer)
    }
  }, [user])

  // Si el usuario está inactivo por 5 minutos, se cierra la sesión
  useEffect(() => {
    if (inactive) {
      signOut()
    }
  }, [inactive, signOut])

  return children
}

export default UserInactivity
