import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  NavbarContent,
  NavbarItem,
  Switch,
} from '@nextui-org/react'
import { useTheme } from 'next-themes'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { api, apiReferrals } from 'api/index'
import { defaultAvatar, icons } from './constants'
import PropTypes from 'prop-types'

// TODO: Cuando se cambie la lógica, aplicar también en Fraccionario
const Account = ({ signOut, isMobile }) => {
  const [user, setUser] = useState({})
  const [isDark, setIsDark] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { theme, setTheme } = useTheme()

  const userCustomer = useSelector((state) => state?.user?.customer?.authenticated?.attributes)
  const userProfile = useSelector((state) => state?.profile)

  useEffect(() => {
    theme === 'dark' ? setIsDark(true) : setIsDark(false)
  }, [theme])

  const fetchProfile = async () => {
    try {
      const { data } = await api.getUserInfo(userCustomer.sub)
      const user = data[0]
      setUser(user)
      localStorage.setItem('profile', JSON.stringify(user))
      return dispatch({ type: 'set', profile: user })
    } catch (error) {
      console.error('Error al obtener la información del usuario', error)
    }
  }

  const handleFetchProfile = () => {
    if (Object.keys(userProfile).length === 0) {
      return fetchProfile()
    }
    setUser(userProfile)
  }

  useEffect(() => {
    if (userCustomer) {
      handleFetchProfile()
    }
  }, [userProfile, userCustomer])

  const checkReferralCode = async () => {
    try {
      const referralCode = localStorage.getItem('referralCode') || null
      if (referralCode) {
        await apiReferrals.createReferral(
          { referral_type_id: 1, client_referred_id: userCustomer.sub },
          referralCode,
        )
        return localStorage.removeItem('referralCode')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    checkReferralCode()
  }, [])

  return (
    <NavbarContent justify="end">
      <NavbarItem className="flex items-center">
        {isMobile ? (
          <Dropdown className="bg-background">
            <DropdownTrigger>
              <div className="flex items-center space-x-3 md:ml-1">
                <Avatar
                  isBordered
                  color="primary"
                  className="transition-all cursor-pointer bg-background"
                  src={user?.avatar || defaultAvatar}
                />
              </div>
            </DropdownTrigger>
            <DropdownMenu className="text-xl" variant="faded" aria-label="dropdown-menu">
              <DropdownItem
                textValue={userCustomer?.name}
                color="primary"
                className="font-semibold text-primary"
                startContent={<Icon icon={icons.user} />}
                onClick={() => navigate('/profile')}
              >
                {userCustomer?.name}
              </DropdownItem>
              <DropdownItem
                textValue="Fraccionario"
                color="primary"
                className="text-primary"
                startContent={<Icon icon={icons.circle} />}
              >
                <a href="https://fraccionario.propital.com">Fraccionario</a>
              </DropdownItem>
              <DropdownItem
                textValue="Modo"
                color="primary"
                startContent={<Icon className="text-primary" icon={icons.themes} />}
              >
                <div className="flex items-center w-full text-primary">
                  <p>Modo</p>
                  <Switch
                    size="sm"
                    color="primary"
                    className="ml-3"
                    isSelected={!isDark}
                    onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    thumbIcon={({ isSelected, className }) =>
                      isSelected ? (
                        <Icon
                          className={className}
                          icon={icons.light}
                          style={{ pointerEvents: 'none' }}
                        />
                      ) : (
                        <Icon
                          className={className}
                          icon={icons.dark}
                          style={{ pointerEvents: 'none' }}
                        />
                      )
                    }
                  ></Switch>
                </div>
              </DropdownItem>
              <DropdownItem
                color="danger"
                className="text-danger"
                onClick={signOut}
                startContent={<Icon icon={icons.logout} />}
              >
                Cerrar sesión
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <div className="flex items-center space-x-3 md:ml-1">
            <Avatar
              isBordered
              color="primary"
              className="transition-all cursor-pointer bg-background"
              src={user?.avatar || defaultAvatar}
              onClick={() => navigate('/profile')}
            />
            <span className="font-semibold text-primary">{userCustomer?.name}</span>
          </div>
        )}
      </NavbarItem>
    </NavbarContent>
  )
}

Account.propTypes = {
  signOut: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default Account
